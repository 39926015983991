import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = ({ overwritestyles: { root } = {} }) => makeStyles({
  root: {
    textTransform: 'none',
    borderRadius: '24px',
    padding: '0.875rem 2rem',
    fontWeight: '700',
    fontSize: '1rem',
    lineHeight: '1rem',
    '&:active, &:hover': {
      backgroundColor: '#00c441',
      color: 'white',
    },
    ...root
  },

});

const StyledButton = props => {
  const { children } = props;
  const classes = useStyles(props)();

  return (
    <Button
      {...props}
      classes={{
        root: classes.root,
        disabled: classes.disabled
      }}
    >
      { children }
    </Button>
  );
};

StyledButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  overwritestyles: PropTypes.objectOf(PropTypes.any),
};

StyledButton.defaultProps = {
  overwritestyles: [],
};

export default StyledButton;
