import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/Loading';

const FbLoginRedirectLoadable = Loadable({
  loader: () => import('./FbLoginRedirect')
    .then(module => module.default)
    .catch(() => { if (typeof window !== 'undefined') window.location.reload(); }),
  loading: () => <div key="FbLoginRedirectLoadable"><Loading /></div>
});

export default FbLoginRedirectLoadable;
