import axios from 'axios';

function getDefaults() {
  return {
    loadPath: '/locales/{{lng}}/{{ns}}.json',
    addPath: 'locales/add/{{lng}}/{{ns}}',
    allowMultiLoading: false,
    reloadInterval: false
  };
}

class Backend {
  constructor(services, options = {}, allOptions = {}) {
    this.options = options;
    this.init(services, options, allOptions);

    this.type = 'backend';
  }

  init(services, options = {}, allOptions = {}) {
    this.services = services;
    this.options = { ...getDefaults(), ...this.options, ...options }; // initial
    this.allOptions = allOptions;

    if (this.options.reloadInterval) {
      setInterval(() => {
        this.reload();
      }, this.options.reloadInterval);
    }
  }

  readMulti(languages, namespaces, callback) {
    const url = this.services.interpolator.interpolate(this.options.loadPath, {
      lng: languages.join('+'),
      ns: namespaces.join('+')
    });

    this.loadUrl(url, callback);
  }

  read(language, namespace, callback) {
    const url = this.services.interpolator.interpolate(this.options.loadPath, {
      lng: language,
      ns: namespace
    });

    this.loadUrl(url, callback);
  }

  loadUrl(url, callback) {
    console.log('-------- before load --------', url);
    return axios({
      method: 'get',
      url,
      responseType: 'application/json'
    })
      .then(response => {
        if (response.status > 400) {
          callback(`failed loading ${url}`, false);
        }

        return callback(null, response.data);
      })
      .catch(e => {
        console.log(e);
        return callback(`failed loading ${url}`, false);
      });
  }

  create(languages, namespace, key, fallbackValue) {
    if (typeof languages === 'string') languages = [languages];

    const payload = {};
    payload[key] = fallbackValue || '';

    languages.forEach(lng => {
      const url = this.services.interpolator.interpolate(this.options.addPath, {
        lng,
        ns: namespace
      });

      console.log('in create hook, url: ', url);

      // ajax(
      //   url,
      //   (err, data, res) => {
      //     // const statusCode = xhr.status.toString();
      //     // TODO: if statusCode === 4xx do log
      //   },
      //   payload
      // );
    });
  }

  reload() {
    const { backendConnector, languageUtils } = this.services;
    const currentLanguage = backendConnector.language;
    if (currentLanguage && currentLanguage.toLowerCase() === 'cimode') return; // avoid loading resources for cimode

    const toLoad = [];

    const append = lng => {
      const lngs = languageUtils.toResolveHierarchy(lng);
      lngs.forEach(l => {
        if (toLoad.indexOf(l) < 0) toLoad.push(l);
      });
    };

    append(currentLanguage);

    if (this.allOptions.preload) {
      this.allOptions.preload.forEach(l => {
        append(l);
      });
    }

    toLoad.forEach(lng => {
      this.allOptions.ns.forEach(ns => {
        backendConnector.read(lng, ns, 'read', null, null, (err, data) => {
          if (err) {
            console.warn(
              `loading namespace ${ns} for language ${lng} failed`,
              err
            );
          }
          if (!err && data) {
            console.log(`loaded namespace ${ns} for language ${lng}`, data);
          }

          const langLoaded = !err && data ? data[lng][ns] : data;
          backendConnector.loaded(`${lng}|${ns}`, err, langLoaded);
        });
      });
    });
  }
}

Backend.type = 'backend';

export default Backend;
