import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/Loading';

const AttractionDetailDialogLoadable = Loadable({
  loader: () => import('./AttractionDetailDialog')
    .then(module => module.default)
    .catch(() => { if (window) window.location.reload(); }),
  loading: () => <div key="AttractionDetailDialogLoadable"><Loading /></div>
});

export default AttractionDetailDialogLoadable;
