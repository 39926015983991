import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/Loading';

const PlaceItemLoadable = Loadable({
  loader: () => import('./PlaceItem')
    .then(module => module.default)
    .catch(() => { if (typeof window !== 'undefined') window.location.reload(); }),
  loading: () => <div key="PlaceItemLoable"><Loading /></div>
});

export default PlaceItemLoadable;
