const TOGGLE_LOGIN_DIALOG = 'dialogs/LOGIN/TOGGLE';
const CHANGE_LOGIN_FORM = 'dialogs/CHANGE/FORM';
const TOGGLE_DETAILS_DIALOG = 'dialogs/TOGGLE/TOGGLE_DETAILS_DIALOG';
const TOGGLE_SEARCH_PLACE_DIALOG = 'dialogs/TOGGLE/TOGGLE_SEARCH_PLACE_DIALOG';

export const TOGGLE_LOGIN_VIA = {
  bookingConfirm: 'BOOKING_COMFIRM'
};

const initialState = () => ({
  isAttractionDetailDialogOpen: false,
  isSearchPlaceDialogOpen: false,
  isLoginDialogOpen: false,
  currentLoginForm: 'login',
  via: '',
});

export default function reducer(state = initialState(), action = {}) {
  switch (action.type) {
    case TOGGLE_LOGIN_DIALOG:
      return {
        ...state,
        isLoginDialogOpen: !state.isLoginDialogOpen,
        currentLoginForm: action.currentLoginForm,
        via: action.via,
      };
    case CHANGE_LOGIN_FORM:
      return {
        ...state,
        currentLoginForm: action.form,
      };
    case TOGGLE_DETAILS_DIALOG:
      if (state.isAttractionDetailDialogOpen === action.state) return state;

      return {
        ...state,
        isAttractionDetailDialogOpen: action.state,
      };
    case TOGGLE_SEARCH_PLACE_DIALOG:
      if (state.isSearchPlaceDialogOpen === action.state) return state;

      return {
        ...state,
        isSearchPlaceDialogOpen: action.state,
      };
    default:
      return state;
  }
}

export function toggleLoginDialog(currentLoginForm = 'login', via = '') {
  return {
    type: TOGGLE_LOGIN_DIALOG,
    currentLoginForm,
    via,
  };
}

export function changeLoginForm(form = 'login') {
  return {
    type: CHANGE_LOGIN_FORM,
    form,
  };
}

export function toggleDetailsForm(state) {
  return {
    type: TOGGLE_DETAILS_DIALOG,
    state,
  };
}

export function toggleSearchPlaceDialog(state) {
  return {
    type: TOGGLE_SEARCH_PLACE_DIALOG,
    state,
  };
}
