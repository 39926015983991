const initialState = {
  isMobile: false,
  isPhone: false,
  isTablet: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}
