const LOAD = 'magicLink/LOAD';
const LOAD_SUCCESS = 'magicLink/LOAD_SUCCESS';
const LOAD_FAIL = 'magicLink/LOAD_FAIL';

const STORE = 'magicLink/data/STORE';
const STORE_SUCCESS = 'magicLink/data/STORE_SUCCESS';
const STORE_FAIL = 'magicLink/data/FAIL';

const CLEAR_FORM_RESULT = 'magicLink/CLEAR_FORM_RESULT';

const initialState = () => ({
  loading: false,
  loaded: false,
  result: {},
  error: null,
  email: '',
});

export default function reducer(state = initialState(), action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        email: action.email,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        result: action.result
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
        email: '',
      };
    case CLEAR_FORM_RESULT:
      return {
        ...state,
        result: {},
        error: null,
      };
    default:
      return state;
  }
}

export function storeBookingStateInCloud({ id, data }) {
  return {
    types: [STORE, STORE_SUCCESS, STORE_FAIL],
    promise: async ({ client }) => {
      client.post('/booking/saveState', {
        data: {
          id,
          data,
          expires: Date.now() + 900000 // 15 mins expiration time
        }
      });
    },
  };
}

export function magicLink({
  email, firstName = '', lastName = '', domain = ''
}) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ client }) =>
      client.post('/me/postUser', {
        data: {
          email,
          firstName,
          lastName,
          domain
        }
      }),
    email,
  };
}

export function clearMagicLinkResult() {
  return {
    type: CLEAR_FORM_RESULT
  };
}
