import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/Loading';

const PolicyLoadable = Loadable({
  loader: () => import('./Policy')
    .then(module => module.default)
    .catch(() => { if (window) window.location.reload(); }),
  loading: () => <div key="PolicyLoadable"><Loading /></div>
});

export default PolicyLoadable;
