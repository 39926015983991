import {
  LOAD_CARTYPES_ENQUIRY_SUCCESS,
  LOAD_ENQUIRY_SUCCESS
} from './customizeBooking';
const LOAD = 'view/itinerary-pricing/LOAD';
const LOAD_SUCCESS = 'view/itinerary-pricing/LOAD_SUCCESS';
const LOAD_FAIL = 'view/itinerary-pricing/LOAD_FAIL';
const CLEAR_ITINERARY = 'view/itinerary-pricing/CLEAR_ITINERARY';
const SET_CARTYPES = 'view/itinerary-pricing/SET_CARTYPES';

const initialState = {
  loaded: false,
  data: {}
};

export default function recommended(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
        error: null
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false
        // error: action.error
      };
    case LOAD_ENQUIRY_SUCCESS:
    case LOAD_CARTYPES_ENQUIRY_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          car_types: action.result.car_types,
        }
      };
    case SET_CARTYPES:
      return {
        ...state,
        data: {
          ...state.data,
          car_types: action.carTypes,
        }
      };
    case CLEAR_ITINERARY:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: {}
      };
    default:
      return state;
  }
}

export function isLoaded(globalState, slug) {
  const _slug = globalState.itineraryPricing?.data?.slug;

  if (!_slug) return false;

  return _slug === slug;
}

export function load(id) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ client }) => client.get('/itinerary/pricing/', {
      params: {
        id
      }
    })
  };
}

export function setCartypes(carTypes) {
  return {
    type: SET_CARTYPES,
    carTypes,
  };
};

export function clearItineraryPricing() {
  return {
    type: CLEAR_ITINERARY
  };
}
