import cookie from 'js-cookie';
import isEmpty from 'lodash.isempty';
import { restoreCustomizeObject } from './customizeBooking';
import { restoreBookingObject } from './booking';

const LOAD = 'auth/auth/LOAD';
const LOAD_SUCCESS = 'auth/auth/LOAD_SUCCESS';
const LOAD_FAIL = 'auth/auth/LOAD_FAIL';
const LOGIN = 'auth/auth/LOGIN';
const LOGIN_SUCCESS = 'auth/auth/LOGIN_SUCCESS';
const LOGIN_FAIL = 'auth/auth/LOGIN_FAIL';
const REGISTER = 'auth/auth/REGISTER';
const REGISTER_SUCCESS = 'auth/auth/REGISTER_SUCCESS';
const REGISTER_FAIL = 'auth/auth/REGISTER_FAIL';
const LOGOUT = 'auth/auth/LOGOUT';
const LOGOUT_SUCCESS = 'auth/auth/LOGOUT_SUCCESS';
const LOGOUT_FAIL = 'auth/auth/LOGOUT_FAIL';
const CLEAR_VALIDATING_DATA = 'auth/auth/CLEAR_VALIDATING_DATA';
const VALIDATE_REDIRECT_CODE = 'auth/auth/VALIDATE_REDIRECT_CODE';
const VALIDATE_REDIRECT_CODE_SUCCESS =
  'auth/auth/VALIDATE_REDIRECT_CODE_SUCCESS';
const VALIDATE_REDIRECT_CODE_FAIL = 'auth/auth/VALIDATE_REDIRECT_CODE_FAIL';
// const LOAD_VALIDATION_TOKEN = 'auth/magicLink/validate/LOAD';
// const LOAD_VALIDATION_TOKEN_SUCCESS = 'auth/magicLink/validate/LOAD_SUCCESS';
// const LOAD_VALIDATION_TOKEN_FAIL = 'auth/magicLink/validate/LOAD_FAIL';
const LOGIN_WITH_MAGIC_LINK = 'LOGIN_WITH_MAGIC_LINK';
export const LOGIN_WITH_MAGIC_LINK_SUCCESS = 'LOGIN_WITH_MAGIC_LINK_SUCCESS';
const LOGIN_WITH_MAGIC_LINK_FAIL = 'LOGIN_WITH_MAGIC_LINK_FAIL';
const CLEAR_AUTH_ERROR = 'auth/CLEAR_AUTH_ERROR';

const LOAD_PRESERVED_STATE = 'auth/LOAD_PRESERVED_STATE';
export const LOAD_PRESERVED_STATE_SUCCESS = 'auth/LOAD_PRESERVED_STATE_SUCCESS';
const LOAD_PRESERVED_STATE_FAIL = 'auth/LOAD_PRESERVED_STATE_FAIL';

/**
 * Every time we toggle the dialog, we reset auth error
 */
const TOGGLE_LOGIN_DIALOG = 'dialogs/LOGIN/TOGGLE';
const CHANGE_LOGIN_FORM = 'dialogs/CHANGE/FORM';

const initialState = {
  loaded: false,
  validationLoading: false,
  validationLoaded: false,
  validationResult: {},
  validationError: null,
  errorCode: 0,
  error: null,
  errorMessage: '',
  user: null,
  magicLinkLogginIn: false,
  magicLinkLoaded: false,
  fbAccessToken: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        accessToken: action.result.accessToken,
        user: action.result.user
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case VALIDATE_REDIRECT_CODE:
      return {
        ...state,
        validationLoaded: false,
        validationLoading: true
      };
    case VALIDATE_REDIRECT_CODE_SUCCESS:
      return {
        ...state,
        fbAccessToken: action.result,
        validationLoaded: true,
        validationLoading: false
      };
    case VALIDATE_REDIRECT_CODE_FAIL:
      return {
        ...state,
        loginError: action.error,
        errorCode: 400,
        errorMessage: action.error.message,
        validationLoaded: true,
        validationLoading: false
      };
    case CLEAR_VALIDATING_DATA:
      return {
        ...state,
        fbAccessToken: null,
        magicLinkLogginIn: false,
        magicLinkLoaded: false,
        validationLoading: false,
        validationLoaded: false,
        validationResult: {},
        validationError: null
      };
    case LOGIN_WITH_MAGIC_LINK:
      return {
        ...state,
        magicLinkLogginIn: true,
        magicLinkLoaded: false
      };
    case LOGIN:
      return {
        ...state,
        magicLinkLogginIn: true
      };
    case LOGIN_WITH_MAGIC_LINK_SUCCESS:
    case LOGIN_SUCCESS:
      const { user } = action.result || {};

      if (isEmpty(user)) {
        return {
          ...state,
          magicLinkLogginIn: false,
          accessToken: action.result.accessToken || ''
        };
      }

      const { owt } = user || {};
      const _user = owt
        ? action.result.user
        : {
          ...user,
          owt: action.result.owt
        };

      return {
        ...state,
        magicLinkLogginIn: false,
        magicLinkLoaded: true,
        accessToken: action.result.accessToken,
        errorCode: 0,
        error: null,
        user: _user
      };
    case LOGIN_WITH_MAGIC_LINK_FAIL:
      return {
        ...state,
        magicLinkLogginIn: false,
        magicLinkLoaded: true,
        validationLoading: false,
        validationLoaded: false,
        errorCode: action.error.code,
        errorMessage: action.error.message
      };
    case LOGIN_FAIL:
      return {
        ...state,
        magicLinkLogginIn: false,
        loginError: action.error,
        errorCode: action.error.code,
        errorMessage:
          action.error.code === 406
            ? 'Please make sure your Facebook Account has email information.'
            : 'Authentication Failed'
      };
    case TOGGLE_LOGIN_DIALOG:
    case CHANGE_LOGIN_FORM:
    case CLEAR_AUTH_ERROR:
      return {
        ...state,
        error: null,
        errorCode: 0,
        errorMessage: ''
      };
    case REGISTER:
      return {
        ...state,
        registeringIn: true
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        registeringIn: false
      };
    case REGISTER_FAIL:
      return {
        ...state,
        registeringIn: false,
        registerError: action.error
      };
    case LOGOUT:
      return {
        ...state,
        loggingOut: true
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loggingOut: false,
        accessToken: null,
        user: null
      };
    case LOGOUT_FAIL:
      return {
        ...state,
        loggingOut: false,
        logoutError: action.error
      };
    default:
      return state;
  }
}

function loadBookingState({ client }) {
  return async code => {
    let response = {};
    const id =
      typeof code === 'string' && code.length === 64 ? code.slice(0, 32) : code;

    if (!id) return response;

    response = await client.get('/booking/loadState', {
      params: {
        id
      }
    });

    return response.data;
  };
}

function setCookie({ app }) {
  return async response => {
    const payload = await app.passport.verifyJWT(response.accessToken);

    // const _expires = response.expires || payload.exp;
    const options =
      payload && payload.userId && response.expires
        ? {
          expires: new Date(response.expires * 1000),
          domain: process.env.COOKIE_DOMAIN
        }
        : undefined;

    cookie.set('feathers-jwt', response.accessToken, options);

    return response;
  };
}

function setToken({ client, app }) {
  return response => {
    const { accessToken } = response;
    // set manually the JWT for both instances of feathers/client
    app.set('accessToken', accessToken);
    client.setJwtToken(accessToken);
  };
}

function setUser({ app }) {
  return response => {
    app.set('user', response.user);
  };
}

function logoutIntercom() {
  return response => {
    if (typeof window !== 'undefined') {
      window.Intercom('shutdown');
      window.Intercom('boot', {
        app_id: 'ft0nme10'
      });
    }
    return response;
  };
}

const catchValidation = error => {
  if (error.message) {
    if (error.message === 'Validation failed' && error.data) {
      return Promise.reject(error.data);
    }
    const err = {
      error: error.message
    };
    return Promise.reject(err);
  }
  return Promise.reject(error);
};

function rebootIntercomWithUser() {
  return response => {
    if (typeof window !== 'undefined') {
      const email = response && response.user && response.user.email;
      window.Intercom('update', {
        email
      });
    }
    return response;
  };
}

/*
* Actions
* * * * */

export function isLoaded(globalState) {
  return globalState.auth && globalState.auth.loaded;
}

export function load() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: async ({ app, client }) => {
      const response = await app.authenticate();
      await setCookie({ app })(response);
      setToken({ client, app })(response);
      setUser({ app })(response);
      return response;
    }
  };
}

export function login(strategy, data, validation = true) {
  return {
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
    promise: async ({ client, app }) => {
      try {
        const response = await app.authenticate({
          ...data,
          strategy
        });

        await setCookie({ app })(response);
        setToken({ client, app })(response);
        setUser({ app })(response);
        rebootIntercomWithUser();

        return response;
      } catch (error) {
        if (strategy === 'local') {
          return catchValidation(error);
        }

        if (!validation) throw error;
      }
    }
  };
}

export function logout() {
  return {
    types: [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL],
    promise: async ({ client, app }) => {
      await app.logout();

      setToken({ client, app })({ accessToken: null });
      setUser({ app })({ user: null });

      cookie.set('feathers-jwt', '', {
        domain: process.env.COOKIE_DOMAIN
      });

      logoutIntercom();
    }
  };
}

export function validateRedirectCode(code, redirectUri) {
  return {
    types: [
      VALIDATE_REDIRECT_CODE,
      VALIDATE_REDIRECT_CODE_SUCCESS,
      VALIDATE_REDIRECT_CODE_FAIL
    ],
    promise: ({ client }) =>
      client.post('/auth/validateFbCode', {
        data: {
          code,
          redirectUri
        }
      })
  };
}

export function loadPreservedState(id) {
  return {
    types: [
      LOAD_PRESERVED_STATE,
      LOAD_PRESERVED_STATE_SUCCESS,
      LOAD_PRESERVED_STATE_FAIL
    ],
    promise: async ({ client }) => {
      const preservedState = await loadBookingState({ client })(id);
      return { preservedState };
    }
  };
}

export function magicLinkAuthentication({ code }) {
  return {
    types: [
      LOGIN_WITH_MAGIC_LINK,
      LOGIN_WITH_MAGIC_LINK_SUCCESS,
      LOGIN_WITH_MAGIC_LINK_FAIL
    ],
    promise: async ({ client, app }) => {
      const response = await app.authenticate({
        validateCode: code,
        strategy: 'email'
      });
      await setCookie({ app })(response);

      setToken({ client, app })(response);
      setUser({ app })(response);
      rebootIntercomWithUser()(response);

      const preservedState = await loadBookingState({ client })(code);

      return {
        ...response,
        preservedState
      };
    }
  };
}

export function clearAuthError() {
  return {
    type: CLEAR_AUTH_ERROR,
    promise: async ({ client, app }) => {
      await app.logout();
      setToken({ client, app })({ accessToken: null });
    }
  };
}

export function clearValidatingData() {
  return {
    type: CLEAR_VALIDATING_DATA
  };
}

/**
 * Proceed the stripe payment first then process bookItineraryRequest
 *
 * @param {} obejct consist of two object: stripe and bookingDetail
 */
export function restoreCustomize(customize) {
  return dispatch => dispatch(restoreCustomizeObject(customize));
}

export function restoreBooking(booking) {
  return dispatch => dispatch(restoreBookingObject(booking));
}
