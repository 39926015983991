import styled from 'styled-components';
import { media } from 'theme/styled-theme';

export const CardWrap = styled.div`
  display: flex;
  position: relative;
  border: ${props => props.selected ? '2px solid #00b33b' : '1px solid #ccc'};
  border-radius: 4px;
  margin-bottom: 1rem;
  flex-direction: column;

  ${media.forTabletPortraitUp`
    flex-direction: row;
  `}
`;

export const MobilePrice = styled.span`
  display: none;
  color: #595959;
  font-family: $font-family-raleway;
  margin: 0;
  word-wrap: break-word;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.6px;

  ${media.forPhoneOnly`
    display: flex;
    align-items: center;
  `}
`;

export const SelectButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  ${media.forPhoneOnly`
    justify-content: space-between;
  `}
`;

export const DescriptionWrap = styled.div`
  padding: 1rem;
  width: 100%;
`;

export const CardDescription = styled.div`
  font-weight: 300;
  color: #595959;
  margin: 0.75rem 0 1rem;
  word-wrap: break-word;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.2px;
  padding-top: 0;
  padding-bottom: 0;
`;

export const Name = styled.span`
  text-transform: uppercase;
  color: #595959;
  margin: 0 0 0.75rem;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: -0.2px;
  line-height: 28px;
`;

export const Price = styled.span`
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-weight: 400;
  color: #595959;
  margin: 0;
  word-wrap: break-word;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.6px;

  ${media.forPhoneOnly`
    display: none;
  `}
`;

export const SeparationLine = styled.div`
  display: block;
  height: 1px;
  width: 100%;
  background-color: rgb(219, 219, 219);
  margin: 0.75rem 0;
`;
