import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/Loading';

const PlannerLoadable = Loadable({
  loader: () => import('./Planner')
    .then(module => module.default)
    .catch(e => { console.log(e); if (typeof window !== 'undefined') window.location.reload(); }),
  loading: () => <div key="PlannerLoadable"><Loading /></div>
});

export default PlannerLoadable;
