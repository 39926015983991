/**
 * THIS IS THE ENTRY POINT FOR THE CLIENT, JUST LIKE server.js IS THE ENTRY POINT FOR THE SERVER.
 */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'fix-date';
import amplitude from 'amplitude-js';
import { AppContainer as HotEnabler } from 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';
import { renderRoutes } from 'react-router-config';
import { trigger } from 'redial';
import { createBrowserHistory } from 'history';
import Loadable from 'react-loadable';
import { getStoredState } from 'redux-persist';
import localForage from 'localforage';
import { socket, createApp } from 'app';
import createStore from 'redux/create';
import { RouterTrigger, Provider } from 'components';
import NProgress from 'nprogress';
import apiClient from 'helpers/apiClient';
import routes from 'routes';
import isOnline from 'utils/isOnline';
import asyncMatchRoutes from 'utils/asyncMatchRoutes';
import ReactPixel from 'react-facebook-pixel';
import MobileDetect from 'mobile-detect';
import { createMuiTheme } from '@material-ui/core/styles';
import { StylesProvider, ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import * as Sentry from '@sentry/browser';
import { useSSR } from 'react-i18next';
import OptimizeContext from 'context/OptimizeContext';
import { muiTheme } from './theme/styled-theme';

import './i18n';

/**
 * Sentry Raven
 */
Sentry.init({
  dsn: 'https://cfa47160f7354311ac9fb0e476ab8f13@sentry.io/267477'
});

ReactPixel.init('516158255388931');
ReactPixel.pageView();

const persistConfig = {
  key: 'root',
  storage: localForage,
  stateReconciler(inboundState, originalState) {
    // Ignore state from cookies, only use preloadedState from window object
    return originalState;
  },
  whitelist: ['auth', 'info', 'config']
};

const dest = document.getElementById('content');
const app = createApp();
const client = apiClient();
const providers = { app, client };

function initSocket() {
  socket.on('news', data => {
    console.log(data);
  });

  return socket;
}

initSocket();

(async () => {
  const preloadedState = await getStoredState(persistConfig);
  const online = window.__data ? true : await isOnline();
  const experiments = window.__experiments || [];

  let data = {
    ...preloadedState,
    ...window.__data,
    online
  };

  if (!window.__data) {
    const md = new MobileDetect(window.navigator.userAgent);
    const mobileDetect = {
      isMobile: md.mobile() !== null,
      isPhone: md.phone() !== null,
      isTablet: md.tablet() !== null,
    };

    data = {
      ...data,
      mobileDetect,
    };
  }

  if (online) {
    socket.open();
    const auth = await app.authenticate().catch(() => null);

    data = {
      ...data,
      auth
    };
  }

  const history = createBrowserHistory();
  const store = createStore({
    history,
    data,
    helpers: providers,
    persistConfig
  });

  if (window.Cypress) {
    // Expose Store to Cypress
    window.__store__ = store;
    window.__tgHistory__ = history;
  }

  /**
   * Amplitude
   */
  // initialise
  const amplitudeInstance = amplitude.getInstance();
  amplitudeInstance.init(process.env.AMPLITUDE_API_KEY);

  // Set Experiment once, if there's any active experiment
  if (experiments && experiments.length) {
    const tests = experiments.map(({ id, variant }) => `test_${id}_${variant}`);

    amplitude.setUserProperties({
      'Split Tests': tests,
    });
  }

  // expose to windows global
  window.amplitude = amplitude;

  // Create a new class name generator.
  // const generateClassName = createGenerateClassName();
  const theme = createMuiTheme(muiTheme);

  const triggerHooks = async (_routes, pathname) => {
    NProgress.start();

    const { components, match, params } = await asyncMatchRoutes(_routes, pathname);
    const triggerLocals = {
      ...providers,
      store,
      match,
      params,
      history,
      location: history.location
    };

    await trigger('inject', components, triggerLocals);

    // Don't fetch data for initial route, server has already done the work:
    if (window.__PRELOADED__ && !__DEVELOPMENT__) {
      // Delete initial data so that subsequent data fetches can occur:
      delete window.__PRELOADED__;
    } else {
      // Fetch mandatory data dependencies for 2nd route change onwards:
      await trigger('fetch', components, triggerLocals);
    }

    await trigger('defer', components, triggerLocals);

    NProgress.done();
  };


  const hydrate = _routes => {
    useSSR(window.initialI18nStore, window.initialLanguage);

    const element = (
      <HotEnabler>
        <Provider store={store} {...providers}>
          <StylesProvider injectFirst>
            <MuiThemeProvider theme={theme}>
              <Router history={history}>
                <RouterTrigger
                  trigger={pathname => triggerHooks(_routes, pathname)}
                  experiments={experiments}
                >
                  <OptimizeContext.Provider value={experiments}>
                    {renderRoutes(_routes)}
                  </OptimizeContext.Provider>
                </RouterTrigger>
              </Router>
            </MuiThemeProvider>
          </StylesProvider>
        </Provider>
      </HotEnabler>
    );

    if (dest.hasChildNodes()) {
      ReactDOM.hydrate(element, dest);
    } else {
      ReactDOM.render(element, dest);
    }
  };

  await Loadable.preloadReady();

  hydrate(routes);

  // Hot reload
  if (module.hot) {
    module.hot.accept('./routes', () => {
      const nextRoutes = require('./routes');
      try {
        hydrate(nextRoutes.__esModule ? nextRoutes.default : nextRoutes);

        // Deliberatly trigger route for hard reloading, or the page wouldn't rerender.
        triggerHooks(
          nextRoutes.__esModule ? nextRoutes.default : nextRoutes,
          history.location.pathname
        );
      } catch (err) {
        console.error('Error on routes reload:', err);
      }
    });
  }

  // Server-side rendering check
  if (process.env.NODE_ENV !== 'production') {
    window.React = React; // enable debugger
  }

  // Dev tools
  // if (__DEVTOOLS__ && !window.__REDUX_DEVTOOLS_EXTENSION__) {
  //   const devToolsDest = document.createElement('div');
  //   // window.document.body.insertBefore(devToolsDest, null);
  //   const DevTools = require('./containers/DevTools/DevTools');

  //   ReactDOM.hydrate(
  //     <I18nextProvider i18n={i18n}>
  //       <Provider store={store}>
  //         <DevTools />
  //       </Provider>
  //     </I18nextProvider>,
  //     devToolsDest
  //   );
  // }

  // Service worker
  if (!__DEVELOPMENT__ && !__CI__ && 'serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(
        '/dist/sw.js',
        { scope: '/' }
      );
      registration.onupdatefound = () => {
        // The updatefound event implies that reg.installing is set; see
        // https://w3c.github.io/ServiceWorker/#service-worker-registration-updatefound-event
        const installingWorker = registration.installing;

        installingWorker.onstatechange = () => {
          switch (installingWorker.state) {
            case 'installed':
              if (navigator.serviceWorker.controller) {
                // At this point, the old content will have been purged and the fresh content will
                // have been added to the cache.
                // It's the perfect time to display a "New content is available; please refresh."
                // message in the page's interface.
                console.log('New or updated content is available.');
              } else {
                // At this point, everything has been precached.
                // It's the perfect time to display a "Content is cached for offline use." message.
                console.log('Content is now available offline!');
              }
              break;
            case 'redundant':
              console.error('The installing service worker became redundant.');
              break;
            default:
          }
        };
      };
    } catch (error) {
      console.log('Error registering service worker: ', error);
    }

    await navigator.serviceWorker.ready;
    console.log('Service Worker Ready');
  }
})();
