import React, {
  memo,
  useMemo,
  useState,
  useEffect,
  useCallback
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { provideHooks } from 'redial';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import Sticky from 'react-stickynode';
import useReactRouter from 'use-react-router';
import queryString from 'query-string';
import isEmpty from 'lodash.isempty';
import {
  Link as ScrollLink,
  Element as ScrollElement,
  scrollSpy,
  scroller as scroll
} from 'react-scroll';

/* ------------------------------- Material UI ------------------------------ */
// import NavigateNextIcon from '@material-ui/icons/NavigateNext';
// import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

/* ---------------------------- Custom Components --------------------------- */
import config from 'config';
import { media } from 'theme/styled-theme';
import DayPlannerItem from 'components/DayPlannerItem/DayPlannerItem';
import CarTypeOptions from 'components/Pricing/CarTypeOptions/Loadable';
import LanguageTypeOptions from 'components/Pricing/LanguageTypeOptions/Loadable';
import GuestsPicker from 'components/GuestsPicker/Loadable';
import Policies from 'components/Pricing/Policies/Loadable';
import StyledButtonRounded from 'components/MuiComponents/RoundedButton';
import * as CardStyled from 'components/Pricing/card.styled';
import MobileStickyBanner from 'components/Pricing/MobileStickyBanner/Loadable';
import PriceSummary from 'components/Pricing/PriceSummary/Loadable';
import SaveAndShareDialog from 'components/SaveAndShareDialog/Loadable';
import AttractionDetailDialog from 'components/AttractionDetailDialog/Loadable';
import SearchDialog from 'components/SearchDialog/Loadable';
import { InfoRoundedIcon } from 'components/StyledIcons/StyledIcons';
import SingleDatePickerWrapper from 'components/SingleDatePicker/SingleDatePickerLoadable';
import { sendEvent, sendGtagEvent } from 'utils/gtag';
import { getTodayYYMMDDByTimezone } from 'utils/getLastMinuteMinimumBookingDate';
import useBookingPrice from 'hooks/useBookingPrice';
import {
  convertOwnridesPlaceToStandard,
  convertGooglePlaceToStandard,
  getPlaceDisplayName,
} from 'utils/placeHelper';
import ScrollRevealItineraryHeader from 'components/ScrollRevealItineraryHeader/Loadable';
// import useWhyDidYouUpdate from 'utils/useWhyDidYouUpdate';
import saveIcon from '../../assets/save-cloud-icon.svg'; // eslint-disable-line import/order

/* ----------------------------- Import Reducer ----------------------------- */
import itineraryPricingReducer, {
  isLoaded as isItineraryPricingLoaded,
  load as itineraryPricing,
  setCartypes as _setCarTypes,
} from 'redux/modules/itineraryPricing';
import {
  initializeImportingWithoutEnquiry as _initItineraryToBeCustomizable,
  retrieveCarTypes as _retrieveCarTypes,
  persistEnquiry as _persistEnquiry,
  moveDestinationUp as _moveDestinationUp,
  moveDestinationDown as _moveDestinationDown,
  addDestinationPlace as _addDestinationPlace,
  addStartingPlace as _addStartingPlace,
  addFinishingPlace as _addFinishingPlace,
  deleteDayTrip as _deleteDayTrip,
  deleteDestination as _deleteDestination,
  changeDestinationPlaceDuration as _changeDestinationPlaceDuration,
  makeEnquiryRequest as _makeEnquiryRequest,
} from 'redux/modules/customizeBooking';

import {
  changeCarType as _changeCarType,
  changeLanguage as _changeLanguage,
  setBookingRequestVia as _setBookingRequestVia,
  clearLoginRequestVia as _clearLoginRequestVia,
  changeDate as _changeDate,
  changeGuestsComposition as _changeGuestsComposition,
  clearSkipLoginRedirect as _clearSkipLoginRedirect,
} from 'redux/modules/booking';

import {
  toggleAddToRoute as _toggleAddToRoute,
  clearSearchResult as _clearSearchResult,
  selectPlaceId as _selectPlaceId,
  setPresetDuration as _setPresetDuration,
  setPresetDiaplayName as _setPresetDiaplayName,
  getPlaceDetails as _getPlaceDetails,
} from 'redux/modules/places';

import {
  toggleDetailsForm as _toggleDetailsForm,
  toggleSearchPlaceDialog as _toggleSearchPlaceDialog,
  toggleLoginDialog as _toggleLoginDialog,
  TOGGLE_LOGIN_VIA,
} from 'redux/modules/dialogs';

import {
  clearStep,
} from 'redux/modules/joyride';

import {
  loadPriceAdjustments as _loadPriceAdjustments,
} from 'redux/modules/priceAdjustments';

import moment from 'moment';

const GutterBottom = styled.div`
  width: 100%;
  margin-bottom: ${props => props.multiply ? `${props.multiply * 1.5}rem` : '1.5rem'};
`;

const Root = styled.div`
`;

const Panel = styled.div`
  position: relative;

  ${media.forTabletLandscapeUp`
    display: flex;
  `}
`;

const LeftPanel = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  margin-right: 3rem;

  ${media.forPhoneOnly`
    margin-right: 0;
    width: 100%;
  `}

  ${media.forTabletPortraitOnly`
    margin-right: 0;
    width: 100%;
  `}
`;

const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 338px;

  & .toolbarStickyActive {
    width: 100%;
  }

  ${media.forPhoneOnly`
    display: none;
  `}

  ${media.forTabletPortraitOnly`
    display: none;
  `}
`;

const PricingSidePanel = styled.div`
  min-width: 338px;
`;

const DatePickerStyle = styled.div`
  input.DateInput_input {
    padding: 0.875rem 1rem;
    font-size: 1rem;
    line-height: 1rem;
    color: #595959;
    width: 100%;
    text-align: left;
    font-weight: 400;
    margin: 0;
    border: 0;
  }

  .DateInput, .SingleDatePickerInput, .SingleDatePicker {
    width: 100%;
  }
`;

const DateGuestPickerWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 4px;
  background: white;
  border: 1px solid #ccc;
`;

const Flex = styled.div`
  display: flex;
`;

const WarningWrap = styled.span`
  display: flex;
  align-items: center;
  margin: 1rem 0 0;
  color: #595959;
  border: 1px solid #c5262a;
  border-left: 4px solid #c5262a;
  border-radius: 4px;
  padding: 0.5rem;
`;

const GuestsWrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 0;
`;

const DateWrap = styled(GuestsWrap)`
  margin-right: 0.5rem;
`;

const Header = styled(Typography)`
  && {
    color: #343434;

    ${media.forPhoneOnly`
      font-size: 1.5rem;
      line-height: 36px;
    `}
  }
`;

const SectionHeader = styled(Typography)`
  && {
    font-weight: 700;
  }
`;

const SectionSubHeader = styled(Typography)`
  && {
    font-weight: 400!important;
    color: #595959;
    display: flex;
    align-items: center;
  }
`;

const StickyBottom = styled.div`
  position: fixed;
  z-index: 1000;
  bottom: 0;
  width: 100vw;
  display: none;

  ${media.forPhoneOnly`
    display: flex;
  `}

  ${media.forTabletPortraitOnly`
    display: flex;
  `}
`;

const BookingNextStep = styled(CardStyled.CardDescription)`
  text-align: center;
`;

const SaveImage = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
`;

const JumpSeparator = styled.span`
  margin: 0 0.75rem;
  color: #3b3b3b;

  ${media.forPhoneOnly`
    margin: 0 0.125rem;
  `}
`;

const QuickJumpWrap = styled.div`
  display: flex;
  width: 100vw;
  height: ${props => props.height ? `${props.height}px` : '48px'};
  background-color: white;
  align-items: center;
  font-size: 1rem;
  color: #00b33b;
  background: white none repeat scroll 0% 0%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px -2px 8px;

  a {
    padding: 0.875rem 0;
    cursor: pointer;
  }

  & .quickJumpLink.active {
    font-weight: 700;
    color: #3b3b3b;
  }

  .container {
    ${media.forPhoneOnly`
      margin: 0;
      padding: 0 1rem;
    `}
  }
`;

/* -------------------------------------------------------------------------- */
/*                              Child components                              */
/* -------------------------------------------------------------------------- */
const RenderHelmet = memo(({ itinerary, t }) => {
  const {
    name = '',
    description = '',
    slug = ''
  } = itinerary;

  const pathname = __SERVER__
    ? `${config.app.url}/itinerary/${slug}`
    : `${config.app.url}${window.location?.pathname}`;

  return (
    <>
      <Helmet
        title={t('meta:META.PRICING.TITLE', {
          itineraryName: name || 'Customize Trip'
        })}
      />
      <Helmet
        meta={[
          { name: 'robots', content: 'noindex' },
          { name: 'description', content: description },
          { id: 'english-canonical-url', content: '' },
          { property: 'og:url', content: pathname },
          { property: 'og:title', content: `${name || 'My Customized Trip'} - OWNRIDES` },
          { property: 'og:description', content: description },
        ]}
      />
    </>
  );
});

RenderHelmet.propTypes = {
  itinerary: PropTypes.objectOf(PropTypes.any),
  t: PropTypes.func,
};

RenderHelmet.defaultProps = {
  itinerary: {},
  t: () => {},
};

const CarTypes = memo(({
  /* eslint-disable-next-line react/prop-types */
  selectedCarType, changeCarType, totalGuests, profitMargin, currency, carOptions
}) => {
  const sortedCarOptions = useMemo(() => (
    carOptions && carOptions
      .slice()
      .sort((car1, car2) => car1.id > car2.id)
  ), [carOptions]);

  return (
    <CarTypeOptions
      selectedCarType={selectedCarType}
      changeCarType={changeCarType}
      carOptions={sortedCarOptions}
      profitMargin={profitMargin}
      currency={currency}
      totalGuests={totalGuests}
    />
  );
});

const DayPlanners = memo(({
  /* eslint-disable react/prop-types */
  dayPlan,
  countryCode,
  referenceId,
  setShowRecommendedCard,
  setAddDestinationCallback,
  moveDestinationUp,
  moveDestinationDown,
  addDestinationPlace,
  addStartingPlace,
  clearSearchResult,
  toggleSearchPlaceDialog,
  toggleDetailsForm,
  toggleAddToRoute,
  addFinishingPlace,
  deleteDayTrip,
  selectPlaceId,
  deleteDestination,
  changeDestinationPlaceDuration,
  makeEnquiryRequest,
  presetDisplayName,
  presetDuration,
  isFromImportedButton,
  joyrideStep,
  clearJoyrideStep,
  enquiryResult,
  /* eslint-enable react/prop-types */
}) => {
  const openSearch = useCallback(() => {
    toggleSearchPlaceDialog(true);
    clearSearchResult();
  });

  const shouldMakeEnquiry = () => !dayPlan.find(({ startingPlace, finishingPlace }) => {
    if (!startingPlace) return true;
    if (!finishingPlace) return true;

    return false;
  });

  const getDestinationsInfo = destinations => {
    const places = destinations.map(_ => _.place_id);
    const placesDuration = destinations.map(_ => (
      // eslint-disable-next-line camelcase
      _.ride?.place_duration || _.duration
    ));
    const names = destinations.map(getPlaceDisplayName);

    return [places, placesDuration, names];
  };

  const destinationsPlaceHandlerCallback = (place, day) => {
    const _place = {
      ...place,
      name: presetDisplayName.length ? presetDisplayName : place.name,
    };

    addDestinationPlace(_place, day);

    const {
      destinations,
      startingPlace,
      finishingPlace
    } = dayPlan?.[day] || {};

    const {
      place_id: placeIdToAdd,
      duration: durationToAdd,
    } = _place;

    const {
      place_id: startingPlaceId,
      description: startingDescription,
    } = startingPlace || {};

    const {
      place_id: finishingPlaceId,
      description: finishingDescription,
    } = finishingPlace || {};

    if (!shouldMakeEnquiry()) return;
    if (!startingPlaceId || !finishingPlaceId) return;

    const [places, placesDuration, names] = getDestinationsInfo(destinations);

    makeEnquiryRequest({
      enquiry: {
        places_in_route: [startingPlaceId, ...places, finishingPlaceId],
        places_duration: [0, ...placesDuration, 0],
        place_to_add: placeIdToAdd,
        place_to_add_duration: durationToAdd || presetDuration || 60,
        names_in_route: [startingDescription, ...names, finishingDescription]
      },
      day,
      dayPlan,
      referenceId,
      countryCode
    });
  };

  const startingPlaceHandlerCallback = (place, day) => {
    addStartingPlace(place, day);

    if (day !== 0) addFinishingPlace(place, day - 1);

    const {
      destinations,
      finishingPlace
    } = dayPlan?.[day] || {};

    const {
      place_id: placeIdToAdd,
      description: descriptionToAdd,
    } = place || {};

    const {
      place_id: finishingPlaceId,
      description: finishingDescription,
    } = finishingPlace || {};

    if (!shouldMakeEnquiry()) return;
    if (!placeIdToAdd || !finishingPlaceId) return;

    const [places, placesDuration, names] = getDestinationsInfo(destinations);

    setTimeout(() => {
      makeEnquiryRequest({
        enquiry: {
          places_in_route: [placeIdToAdd, ...places, finishingPlaceId],
          places_duration: [0, ...placesDuration, 0],
          names_in_route: [descriptionToAdd, ...names, finishingDescription]
        },
        day,
        dayPlan,
        referenceId,
        countryCode
      });
    }, 0);
  };

  const finishingPlaceHandlerCallback = (place, day) => {
    addFinishingPlace(place, day);
    if (day !== dayPlan.length - 1) addStartingPlace(place, day + 1);

    const {
      destinations,
      startingPlace
    } = dayPlan?.[day] || {};

    const {
      place_id: placeIdToAdd,
      description: descriptionToAdd,
    } = place || {};
    const {
      place_id: startingPlaceId,
      description: startingDescription,
    } = startingPlace || {};

    if (!shouldMakeEnquiry()) return;
    if (!placeIdToAdd || !startingPlaceId) return;

    const [places, placesDuration, names] = getDestinationsInfo(destinations);

    setTimeout(() => {
      makeEnquiryRequest({
        enquiry: {
          places_in_route: [startingPlaceId, ...places, placeIdToAdd],
          places_duration: [0, ...placesDuration, 0],
          names_in_route: [startingDescription, ...names, descriptionToAdd]
        },
        day,
        dayPlan,
        referenceId,
        countryCode
      });
    }, 0);
  };

  const setDestinationsPlaceHandler = day => {
    setShowRecommendedCard(true);
    setAddDestinationCallback({
      fn: place => {
        destinationsPlaceHandlerCallback(place, day);
      }
    });
  };

  const setStartingPlaceHandler = day => {
    setShowRecommendedCard(false);
    setAddDestinationCallback({
      fn: place => {
        startingPlaceHandlerCallback(place, day);
      }
    });
  };

  const setFinishingPlaceHandler = day => {
    setShowRecommendedCard(false);
    setAddDestinationCallback({
      fn: place => {
        finishingPlaceHandlerCallback(place, day);
      }
    });
  };

  const getData = (d, day) => ({
    moveDestinationUpHandler: index => moveDestinationUp(index, day),
    moveDestinationDownHandler: index => moveDestinationDown(index, day),
    startingPlaceHandler: () => setStartingPlaceHandler(day),
    finishingPlaceHandler: () => setFinishingPlaceHandler(day),
    destinationsPlaceHandler: () => setDestinationsPlaceHandler(day),
    addFinishingPlaceHandler: place => {
      addFinishingPlace(place, day);

      if (day !== dayPlan.length - 1) {
        addStartingPlace(place, day + 1);
      }
    },
    deleteDayTrip: () => deleteDayTrip(day),
    openDetails: placeId => {
      selectPlaceId(placeId);
      toggleDetailsForm(true);
    },
    openSearch,
    makeEnquiryRequest: enquiry => {
      makeEnquiryRequest({
        enquiry,
        day,
        dayPlan,
        referenceId,
        countryCode
      });
    },
    changeDestinationPlaceDuration: (placeId, duration) => {
      changeDestinationPlaceDuration(placeId, duration, day);
    },
    deleteDestination: destinationIndex => {
      deleteDestination(destinationIndex, day);
    },
    shouldMakeEnquiry: shouldMakeEnquiry(),
    dayPlan: d,
    index: day,
    hideDeleteTrip: dayPlan.length === 1,
    enquiryResult,
    defaultToggle: isFromImportedButton, // check import
    toggleAddToRoute,
    joyrideStep,
    clearJoyrideStep,
  });

  const renderDay = (d, day) => (
    <div key={`Day_Trip_${day}`}>
      <DayPlannerItem {...getData(d, day)} />
    </div>
  );

  return dayPlan.map(renderDay);
});

/* eslint-disable-next-line react/prop-types */
const QuickJump = memo(({ shouldDisplayItinerary }) => (
  <ScrollRevealItineraryHeader height={48} scrollOffset={48}>
    <QuickJumpWrap height={48}>
      <div className="container">
        {
          shouldDisplayItinerary ? (
            <ScrollLink
              activeClass="active"
              className="quickJumpLink"
              to="section-itinerary"
              spy
              smooth
              offset={-100}
              duration={300}
              data-cy="cy-tab-summary"
            >
              Itinerary
            </ScrollLink>
          ) : null
        }
        <JumpSeparator>・</JumpSeparator>
        <ScrollLink
          activeClass="active"
          className="quickJumpLink"
          to="date"
          spy
          smooth
          offset={-100}
          duration={300}
          data-cy="cy-tab-summary"
        >
          Date
        </ScrollLink>
        <JumpSeparator>・</JumpSeparator>
        <ScrollLink
          activeClass="active"
          className="quickJumpLink"
          to="cartype"
          spy
          smooth
          offset={-100}
          duration={300}
          data-cy="cy-tab-summary"
        >
          Car & Language
        </ScrollLink>
        <JumpSeparator>・</JumpSeparator>
        {/* <ScrollLink
          activeClass="active"
          className="quickJumpLink"
          to="languagetype"
          spy
          smooth
          offset={-100}
          duration={300}
          data-cy="cy-tab-summary"
        >
          Language
        </ScrollLink>
        <JumpSeparator>・</JumpSeparator> */}
        <ScrollLink
          activeClass="active"
          className="quickJumpLink"
          to="paymentdetails"
          spy
          smooth
          offset={-100}
          duration={300}
          data-cy="cy-tab-itinerary"
        >
          Price
        </ScrollLink>
        {/* <JumpSeparator>・</JumpSeparator>
        <ScrollLink
          className="quickJumpLink"
          activeClass="active"
          to="policies"
          spy
          smooth
          offset={-100}
          duration={300}
        >
          Policy
        </ScrollLink> */}
      </div>
    </QuickJumpWrap>
  </ScrollRevealItineraryHeader>
));

/* -------------------------------------------------------------------------- */
/*                               Main components                              */
/* -------------------------------------------------------------------------- */
const PricingFunction = props => {
  const {
    itinerary,
    booking,
    customize,
    mobileDetect,
    auth,
    t,
    initItineraryToBeCustomizable,
    changeCarType,
    changeLanguage,
    retrieveCarTypes,
    persistEnquiry,

    moveDestinationUp,
    moveDestinationDown,
    addDestinationPlace,
    addStartingPlace,
    addFinishingPlace,
    deleteDayTrip,
    selectPlaceId,
    deleteDestination,
    changeDestinationPlaceDuration,
    makeEnquiryRequest,
    toggleAddToRoute,
    toggleDetailsForm,
    toggleSearchPlaceDialog,
    getPlaceDetails,
    setPresetDuration,
    setPresetDiaplayName,
    clearSearchResult,
    dialogs,
    places,
    joyrideStep,
    clearJoyrideStep,
    toggleLoginDialog,
    setBookingRequestVia,
    clearLoginRequestVia,
    setCartypes,
    changeDate,
    changeGuestsComposition,
    loadPriceAdjustments,
    priceAdjustments,
    clearSkipLoginRedirect,
  } = props;

  const [datePickerFocus, setDatePickerFocus] = useState(false);
  const [maximumGuests, setMaximumGuests] = useState(8);
  const [showQuickJump, setShowQuickJump] = useState(false);
  const [showRecommendedCard, setShowRecommendedCard] = useState(false);
  const [addDestinationCallback, setAddDestinationCallback] = useState({
    fn: () => {}
  });
  const { history, location } = useReactRouter();
  const [saveAndShareOpen, setSaveAndShareOpen] = useState(false);
  const [needRedirectToBooking, setNeedRedirectToBooking] = useState(false);

  // get authentication info
  const errorCode = auth?.errorCode;
  const { access_token: accessToken } = auth?.user?.owt || {};

  const {
    isAttractionDetailDialogOpen,
    isSearchPlaceDialogOpen,
  } = dialogs || {};

  const {
    presetDuration,
    presetDisplayName,
    presetAllowingAdd,
    presetPhotos,
  } = places;

  const {
    destinations: itinearyDestinations,
    id: itineraryId,
    languages,
    country,
    profit_margin: profitMarginFromItinerary,
    city,
    slug: itinerarySlug,
    name: itineraryName,
  } = itinerary;

  const shouldDisplayItinerary = itinearyDestinations && itinearyDestinations.length;
  const { time_zone: timeZone, id: cityId } = city || {};
  const { code, currency } = country || {};
  const maximumSizeWarning = t(`new-version:COMMON.MAXIMUM_GUESTS_${code || 'tw'}`);

  const {
    carType: bookingCarType,
    language: bookingLanguage,
    via: loginRequestVia,
    date: bookingDate,
    adult,
    children,
    infant,
    totalGuests,
    skipLoginRedirect
  } = booking;

  const {
    dayPlan,
    persisted,
    enquiryResult,
    referenceId,
    persisting,
    descriptions: customizeDescriptions,
    loadingEnquiry
  } = customize;

  const {
    surchargeAmount,
    discountAmount,
  } = priceAdjustments;

  const tripLength = dayPlan.length || 1;

  // Use carTypes from enquiry when possible,
  // since itinerary pricing api will return newest carTypes pricing based on current route
  const {
    car_types: carTypes = [],
    profit_margin: profitMarginFromEnquiry
  } = isEmpty(enquiryResult) ? itinerary : enquiryResult;

  const profitMargin = profitMarginFromEnquiry || profitMarginFromItinerary;

  const {
    totalPrice,
    payableNowPrice,
    currentSelectedCarType,
    carPriceFrom,
    languagePriceFrom
  } = useBookingPrice({
    carTypes,
    languages,
    bookingLanguage,
    bookingCarType,
    profitMargin,
    surchargeAmount,
    discountAmount,
  });

  const { isMobile, isTablet } = mobileDetect || {};

  const isFromImportedButton = useMemo(() => {
    const { search = {} } = location || {};
    const { imported } = queryString.parse(search);

    // return true whenever the string content is 'true', 'yes', 'boo'
    return !!imported;
  }, [location]);

  const outlineButtonStyles = useMemo(() => ({
    root: {
      color: '#00B33B',
      border: '1px solid #00B33B',
      backgroundColor: '#ffffff',
      boxShadow: 'none',
      padding: '1rem 2rem',
      textTransform: 'uppercase',
      fontSize: '1.25rem',
      width: '100%',
    }
  }), []);

  const ctaButtonStyle = useMemo(() => ({
    root: {
      color: '#ffffff',
      backgroundColor: '#00B33B',
      boxShadow: 'default',
      border: 'none',
      padding: '1rem 2rem',
      textTransform: 'uppercase',
      fontSize: '1.25rem',
      width: '100%',
    }
  }), []);

  const saveButtonStyle = useMemo(() => ({
    root: {
      backgroundColor: '#ffffff',
      boxShadow: 'none',
      border: '1px solid #ccc',
      textTransform: 'uppercase',
      fontWeight: '400',
      width: '100%',
      borderBottom: persisted ? '2px solid #00B33B' : '2px solid #c5262a',
    }
  }), [persisted]);

  const warningInfoStyle = useMemo(() => ({
    root: {
      color: '#C5262A',
      marginRight: '1rem',
    }
  }), []);

  const withFullScreenPortal = useMemo(() => isMobile && !isTablet, [isMobile, isTablet]);
  const todayDate = useMemo(() => getTodayYYMMDDByTimezone(timeZone), [timeZone]);

  const reshapedLanguage = useMemo(() => {
    if (!languages) return [];

    const flattend = languages.reduce((left, result) => ({ ...result, ...left }), {});

    if (!flattend) return [];

    return Object
      .keys(flattend)
      .map(key => ({ name: key, price: flattend[key] }))
      .sort((l1, l2) => l1.price > l2.price);
  }, [languages]);

  /* eslint-disable react/no-danger */
  const renderCustomStyle = useMemo(() => (
    <style
      dangerouslySetInnerHTML={{
        __html: `
          #intercom-container iframe.intercom-launcher-frame, #intercom-container iframe.intercom-launcher-discovery-frame {
            ${isMobile && !isTablet ? 'bottom: 92px!important;' : ''}
          }
          #intercom-container .intercom-messenger-frame {
            ${isMobile && !isTablet ? 'bottom: 176px!important;' : ''}
          }`
      }}
    />
  ), []);
  /* eslint-enable react/no-danger */

  /**
   * Redirect User to booking when they perform login
   */
  const redirectToBooking = () => {
    if (!totalPrice || !currentSelectedCarType || !bookingLanguage) return;

    const isCustomizeTrip = !isEmpty(enquiryResult);
    const tripName = isCustomizeTrip ? 'Customize Trip' : itineraryName;
    const bookingPathName = isCustomizeTrip ? 'customize' : itinerarySlug;
    // const { name: carTypeName } = currentSelectedCarType;

    sendGtagEvent(
      'Request Book',
      isCustomizeTrip ? 'Booking Customize Trip' : 'Booking Itinerary',
      'Proceed to Payment'
    );

    history.push({
      pathname: `/booking/${bookingPathName}`,
      state: {
        bookingCarType: currentSelectedCarType,
        totalPrice,
        payableNowPrice,
        tripName,
        isCustomizeTrip,
        itineraryId,
        languageName: bookingLanguage,
        languagePrice: languagePriceFrom,
        hasTourGuideService: false,
        tourGuideServiceFee: 0,
        timeZone,
        currency,
        profitMargin,
        enquiryResult
      }
    });
  };

  const handleSaveAndShareClose = useCallback(e => {
    e.stopPropagation();
    setSaveAndShareOpen(false);
  }, []);

  const onScrollTo = useCallback((e, to) => {
    scroll.scrollTo(to, {
      duration: 300,
      delay: 100,
      smooth: true,
      offset: -100
    });
  });

  const resetPreset = useCallback(
    () => {
      setPresetDuration(60);
      setPresetDiaplayName('');
      toggleAddToRoute(true);
    },
    [],
  );

  const addDestinationCallbackForDetailDialog = useCallback(
    place => {
      const _place = convertGooglePlaceToStandard(place);
      /**
        *
        *  If user has view the details from recommendation list
        *  We set our predefined NAME, DURATION & PHOTO when adding it
        *
        */
      const placeToAdd = {
        ..._place,
        name: presetDisplayName || _place.name,
        duration: presetDuration,
        photos: presetPhotos && presetPhotos.length
          ? presetPhotos.map(_photo => ({ url: _photo.thumbnail_url || '' }))
          : _place.photos,
      };

      addDestinationCallback.fn(placeToAdd);
      toggleDetailsForm(false);
      toggleSearchPlaceDialog(false);
    },
    [presetDisplayName, presetDuration, presetPhotos, addDestinationCallback],
  );

  const handleSearchClose = useCallback(
    () => {
      toggleSearchPlaceDialog(false);
    },
    [],
  );

  const handleNextButtonClick = useCallback(
    e => {
      e.stopPropagation();
      const target = bookingDate ? 'paymentdetails' : 'date';

      if (!bookingDate) setDatePickerFocus(true);

      scroll.scrollTo(target, {
        duration: 500,
        delay: 100,
        offset: -100,
        smooth: true,
        isDynamic: true,
      });
    },
    [bookingDate]
  );

  const handelDetailDialogClose = useCallback(
    () => toggleDetailsForm(false),
    [],
  );

  const addDestinationCallbackForSearhDialog = useCallback(
    place => {
      console.log(addDestinationCallback);
      addDestinationCallback.fn(convertOwnridesPlaceToStandard(place));
      toggleDetailsForm(false);
      toggleSearchPlaceDialog(false);
    },
    [addDestinationCallback],
  );

  const onSaveButtonClick = useCallback(() => {
    persistEnquiry(enquiryResult, dayPlan, referenceId);
    sendEvent({
      action: 'Save Itinerary',
      category: 'engagement',
      label: referenceId || 'New'
    });
    setSaveAndShareOpen(true);
  }, [enquiryResult, dayPlan, referenceId]);

  const onDateChange = useCallback(date => {
    changeDate(date.format('YYYY-MM-DD'));
  }, []);

  const onGuestsSaveHandler = useCallback(guests => {
    changeGuestsComposition(guests);
  }, []);

  const onBookingButtonClick = useCallback(() => {
    // If user has not login yet
    if (!accessToken || errorCode) {
      setBookingRequestVia(TOGGLE_LOGIN_VIA.bookingConfirm);
      toggleLoginDialog('signup', TOGGLE_LOGIN_VIA.bookingConfirm);
      setNeedRedirectToBooking(true);

      return;
    }

    // User is authenticated
    redirectToBooking();
  }, [
    errorCode,
    // These params are related to `redirectToBooking()`
    accessToken,
    currentSelectedCarType,
    bookingLanguage,
    languagePriceFrom,
    payableNowPrice,
    totalPrice,
    timeZone,
    currency,
    profitMargin,
    enquiryResult,
    itineraryId,
    itinerarySlug,
    itineraryName,
  ]);

  useEffect(() => {
    // Wait until after client-side hydration to show quick jump
    setShowQuickJump(true);
    scrollSpy.update();
    /**
     * componentWillUnmount
     */
    return () => {
      // clearItineraryPricing();
      clearLoginRequestVia();
    };
  }, []);

  /**
   * Redirect User to booking after they perform login
   */
  useEffect(() => {
    if (needRedirectToBooking && accessToken) {
      redirectToBooking();
    }
  }, [
    needRedirectToBooking,
    // These params are related to `redirectToBooking()`
    accessToken,
    currentSelectedCarType,
    bookingLanguage,
    languagePriceFrom,
    payableNowPrice,
    totalPrice,
    timeZone,
    currency,
    profitMargin,
    enquiryResult,
    itineraryId,
    itinerarySlug,
    itineraryName,
  ]);

  /**
   * Check if we should redirect to booking page, after login redirect with different session.
   */
  useEffect(() => {
    if (
      loginRequestVia === TOGGLE_LOGIN_VIA.bookingConfirm
      && accessToken
      && currentSelectedCarType
    ) {
      setTimeout(() => { redirectToBooking(); }, 100);
    }
  }, [
    accessToken,
    loginRequestVia,
    currentSelectedCarType,
    // These params are related to `redirectToBooking()`
    bookingLanguage,
    languagePriceFrom,
    payableNowPrice,
    totalPrice,
    timeZone,
    currency,
    profitMargin,
    enquiryResult,
    itineraryId,
    itinerarySlug,
    itineraryName,
  ]);

  useEffect(() => {
    // only initialize when enquiryResult is empty, i.e, no priror customize action being done
    if (isEmpty(enquiryResult)) {
      initItineraryToBeCustomizable(
        itinearyDestinations,
        itineraryId
      );
    }
  }, [itineraryId]);

  useEffect(() => {
    if (itineraryId) {
      const found = reshapedLanguage.find(l => l.name === bookingLanguage);
      const defaultLanguage = reshapedLanguage.find(l => l.price === 0);
      const keyToSet = defaultLanguage ? defaultLanguage.name : reshapedLanguage?.[0]?.name;

      if (!found) changeLanguage(keyToSet);
    }
  }, [itineraryId, reshapedLanguage, bookingLanguage]);

  /**
   * Init cartypes and maximumGuests
   */
  useEffect(() => {
    if (carTypes && carTypes.length !== 0) {
      // Set Max Capacity
      let _maximumGuests = 0;
      carTypes.forEach(({ seating_capacity: seat }) => {
        _maximumGuests = seat >= _maximumGuests ? seat : _maximumGuests;
      });

      setMaximumGuests(_maximumGuests);

      // Find Suitable Cartypes based on size
      const filterCartypes = carTypes.filter(({ seating_capacity: seat }) => (
        totalGuests <= seat
      ));

      // Find the selected car types if exist
      const resultIndex = filterCartypes.findIndex(({ id: _id }) => (
        _id === bookingCarType
      ));

      if (resultIndex && filterCartypes[resultIndex]) {
        changeCarType(filterCartypes[resultIndex].id);
      } else {
        // reset to first one
        changeCarType(filterCartypes[0].id);
      }
    }
  }, [carTypes, totalGuests]);

  /**
   * retrieve cartype if not exist when component mount
   */
  useEffect(() => {
    if (!carTypes || carTypes.length === 0) {
      if (isEmpty(enquiryResult)) {
        retrieveCarTypes(itinearyDestinations, itineraryId);
      } else if (enquiryResult?.car_types) { // eslint-disable-line camelcase
        setCartypes(enquiryResult.car_types);
      }
    }
  }, []);

  /**
   * Get Price Adjustment by date, cartypes, language, city
   */
  useEffect(() => {
    const toDate = tripLength > 1
      ? moment(bookingDate).add(tripLength - 1, 'days').format('YYYY-MM-DD')
      : bookingDate;

    if (cityId && bookingDate && toDate && bookingCarType && bookingLanguage) {
      loadPriceAdjustments({
        cityId,
        fromDate: bookingDate,
        toDate,
        carTypeId: bookingCarType,
        language: bookingLanguage,
      });
    }
  }, [
    bookingCarType,
    bookingLanguage,
    cityId,
    bookingDate,
    tripLength,
  ]);

  useEffect(() => {
    if (skipLoginRedirect) {
      redirectToBooking();
      clearSkipLoginRedirect();
    }
  }, [skipLoginRedirect]);

  return (
    <Root>
      <RenderHelmet t={t} itinerary={itinerary} />

      { showQuickJump ? <QuickJump shouldDisplayItinerary={shouldDisplayItinerary} /> : null }

      { renderCustomStyle }

      <Panel className="container">
        <LeftPanel>
          <GutterBottom />
          {/* <BreadcrumbEl /> */}
          <GutterBottom multiply={0.5} />
          <Header variant="h2" component="h1">{t('PRICING.STEP_1')}</Header>
          {
            shouldDisplayItinerary ? (
              <>
                <GutterBottom />
                <ScrollElement name="section-itinerary">
                  <SectionHeader variant="h4" component="h2">Itinerary</SectionHeader>
                  <GutterBottom />
                  <DayPlanners
                    dayPlan={dayPlan}
                    countryCode={code}
                    referenceId={referenceId}
                    isFromImportedButton={isFromImportedButton}
                    setShowRecommendedCard={setShowRecommendedCard}
                    setAddDestinationCallback={setAddDestinationCallback}
                    moveDestinationUp={moveDestinationUp}
                    moveDestinationDown={moveDestinationDown}
                    addStartingPlace={addStartingPlace}
                    addFinishingPlace={addFinishingPlace}
                    deleteDayTrip={deleteDayTrip}
                    selectPlaceId={selectPlaceId}
                    deleteDestination={deleteDestination}
                    changeDestinationPlaceDuration={changeDestinationPlaceDuration}
                    makeEnquiryRequest={makeEnquiryRequest}
                    toggleAddToRoute={toggleAddToRoute}
                    toggleDetailsForm={toggleDetailsForm}
                    toggleSearchPlaceDialog={toggleSearchPlaceDialog}
                    clearSearchResult={clearSearchResult}
                    presetDisplayName={presetDisplayName}
                    addDestinationPlace={addDestinationPlace}
                    presetDuration={presetDuration}
                    joyrideStep={joyrideStep}
                    clearJoyrideStep={clearJoyrideStep}
                    enquiryResult={enquiryResult}
                  />
                </ScrollElement>
              </>
            ) : null
          }

          <GutterBottom multiply={2} />

          <ScrollElement name="date">
            <SectionHeader variant="h4" component="h2">{t('new-version:NEW_PRICING.DATE_PARTICIPANT')}</SectionHeader>
            <GutterBottom multiply={0.5} />

            <GutterBottom />

            <DateGuestPickerWrap>
              <Flex>
                <DateWrap>
                  <SectionSubHeader variant="h4" component="h3">Date *</SectionSubHeader>
                  <GutterBottom multiply={0.5} />
                  <DatePickerStyle>
                    <SingleDatePickerWrapper
                      date={bookingDate}
                      placeholder="Start Date"
                      onTripDateChange={onDateChange}
                      withFullScreenPortal={withFullScreenPortal}
                      controledFocus={datePickerFocus}
                      controledSetFocus={setDatePickerFocus}
                    />
                  </DatePickerStyle>
                </DateWrap>
                <GuestsWrap>
                  <SectionSubHeader variant="h4" component="h3">Guests</SectionSubHeader>
                  <GutterBottom multiply={0.5} />
                  <GuestsPicker
                    onSave={onGuestsSaveHandler}
                    adultNumber={adult}
                    childrenNumber={children}
                    infantNumber={infant}
                    maximumSizeWarning={maximumSizeWarning}
                    maximumGuests={maximumGuests}
                  />
                </GuestsWrap>
              </Flex>
              {
                bookingDate === todayDate
                  ? (
                    <WarningWrap>
                      <InfoRoundedIcon overwritestyles={warningInfoStyle} />
                      <span>{ t('new-version:COMMON.LAST_MINUTE_WARNING') }</span>
                    </WarningWrap>
                  )
                  : null
              }
            </DateGuestPickerWrap>
            <GutterBottom multiply={2} />
          </ScrollElement>

          <ScrollElement name="cartype">
            <SectionHeader variant="h4" component="h2">Car Type</SectionHeader>
            <GutterBottom multiply={0.5} />
            <SectionSubHeader variant="h4" component="h3">{t('new-version:NEW_PRICING.CARTYPES_DESC')}</SectionSubHeader>
            <GutterBottom />

            <CarTypes
              itinerary={itinerary}
              selectedCarType={bookingCarType}
              changeCarType={changeCarType}
              totalGuests={totalGuests}
              profitMargin={profitMargin}
              currency={currency}
              carOptions={carTypes}
            />

            <GutterBottom multiply={2} />
          </ScrollElement>

          <ScrollElement name="languagetype">
            <SectionHeader variant="h4" component="h2">Driver's language</SectionHeader>
            <GutterBottom multiply={0.5} />
            <SectionSubHeader variant="h4" component="h3">{t('new-version:NEW_PRICING.LANGUAGE_TYPE_DESC')}</SectionSubHeader>
            <GutterBottom />

            <LanguageTypeOptions
              selectedLanguage={bookingLanguage}
              changeLanguage={changeLanguage}
              languageOptions={reshapedLanguage}
              countryCode={code}
              currency={currency}
            />

            <GutterBottom multiply={2} />
          </ScrollElement>

          <ScrollElement name="paymentdetails">
            <SectionHeader variant="h4" component="h2">{t('PRICING.PRICE_SUMMARY')}</SectionHeader>
            <GutterBottom />

            <PriceSummary
              currency={currency}
              totalPrice={totalPrice}
              payableNowPrice={payableNowPrice}
              currentSelectedCarType={currentSelectedCarType}
              bookingLanguage={bookingLanguage}
              carPriceFrom={carPriceFrom}
              languagePriceFrom={languagePriceFrom}
              onScrollTo={onScrollTo}
              loadingEnquiry={loadingEnquiry}
              priceAdjustments={priceAdjustments}
            />

            <StyledButtonRounded
              variant="contained"
              overwritestyles={ctaButtonStyle}
              onClick={onBookingButtonClick}
              data-cy="cy-confirm-and-book"
            >
              {t('PRICING.CONFIRM_AND_PROCEES')}
            </StyledButtonRounded>

            <BookingNextStep>
              {`${t('PRICING.NEXT')}: Payment and contact info. ${t('new-version:NEW_ITINERARY.NOT_CHARGED')}`}
            </BookingNextStep>

            <GutterBottom multiply={2} />
          </ScrollElement>

          <ScrollElement name="policies">
            <Policies countryCode={code} />
            <GutterBottom multiply={2} />
          </ScrollElement>

        </LeftPanel>

        <RightPanel>
          <GutterBottom />
          <Sticky
            enabled
            top={64}
            bottomBoundary="#bottom-elem-for-sticky"
            innerZ={99}
          >
            <PricingSidePanel>
              <PriceSummary
                t={t}
                currency={currency}
                totalPrice={totalPrice}
                payableNowPrice={payableNowPrice}
                currentSelectedCarType={currentSelectedCarType}
                bookingLanguage={bookingLanguage}
                carPriceFrom={carPriceFrom}
                languagePriceFrom={languagePriceFrom}
                onScrollTo={onScrollTo}
                loadingEnquiry={loadingEnquiry}
                priceAdjustments={priceAdjustments}
                showAdjustments={false}
              />
            </PricingSidePanel>

            <StyledButtonRounded
              variant="contained"
              overwritestyles={outlineButtonStyles}
              onClick={handleNextButtonClick}
            >
              Next
            </StyledButtonRounded>

            <GutterBottom />

            <StyledButtonRounded
              data-cy="cy-save-and-share-button"
              variant="contained"
              overwritestyles={saveButtonStyle}
              onClick={onSaveButtonClick}
            >
              <picture>
                <source srcSet={saveIcon} />
                <SaveImage src={saveIcon} alt="Share with Email" />
              </picture>
              <span>
                {persisted ? t('SAVE_AND_SHARE.SAVED') : 'Save the link'}
              </span>
            </StyledButtonRounded>
          </Sticky>
        </RightPanel>
      </Panel>

      <span id="bottom-elem-for-sticky" />

      <StickyBottom>
        <MobileStickyBanner
          price={totalPrice}
          currency={currency}
          onButtonClick={handleNextButtonClick}
          loadingEnquiry={loadingEnquiry}
        />
      </StickyBottom>

      <SearchDialog
        open={isSearchPlaceDialogOpen && !isAttractionDetailDialogOpen}
        toggleDetailsForm={toggleDetailsForm}
        handleClose={handleSearchClose}
        countryCode={code}
        addDestinationCallback={addDestinationCallbackForSearhDialog}
        showRecommendedCard={showRecommendedCard}
      />

      <AttractionDetailDialog
        open={isAttractionDetailDialogOpen}
        handleClose={handelDetailDialogClose}
        countryCode={code}
        places={places}
        mobileDetect={mobileDetect}
        presetAllowingAdd={presetAllowingAdd}
        getPlaceDetails={getPlaceDetails}
        addedDestinations={customizeDescriptions}
        selectPlaceIdCallback={selectPlaceId}
        presetDisplayName={presetDisplayName}
        resetPreset={resetPreset}
        addDestinationCallback={addDestinationCallbackForDetailDialog}
      />

      <SaveAndShareDialog
        persisting={persisting}
        isSaveAndShareOpen={saveAndShareOpen}
        handleClose={handleSaveAndShareClose}
        referenceId={referenceId}
        countryCode={code}
      />
    </Root>
  );
};

PricingFunction.propTypes = {
  itinerary: PropTypes.objectOf(PropTypes.any),
  customize: PropTypes.objectOf(PropTypes.any),
  booking: PropTypes.objectOf(PropTypes.any),
  dialogs: PropTypes.objectOf(PropTypes.any),
  places: PropTypes.objectOf(PropTypes.any),
  mobileDetect: PropTypes.objectOf(PropTypes.any),
  priceAdjustments: PropTypes.objectOf(PropTypes.any),
  auth: PropTypes.objectOf(PropTypes.any).isRequired,
  t: PropTypes.func,
  initItineraryToBeCustomizable: PropTypes.func,

  changeCarType: PropTypes.func.isRequired,
  changeLanguage: PropTypes.func.isRequired,
  retrieveCarTypes: PropTypes.func.isRequired,

  persistEnquiry: PropTypes.func.isRequired,
  moveDestinationUp: PropTypes.func.isRequired,
  moveDestinationDown: PropTypes.func.isRequired,
  addStartingPlace: PropTypes.func.isRequired,
  addFinishingPlace: PropTypes.func.isRequired,
  deleteDayTrip: PropTypes.func.isRequired,
  selectPlaceId: PropTypes.func.isRequired,
  deleteDestination: PropTypes.func.isRequired,
  changeDestinationPlaceDuration: PropTypes.func.isRequired,
  makeEnquiryRequest: PropTypes.func.isRequired,
  toggleAddToRoute: PropTypes.func.isRequired,
  toggleDetailsForm: PropTypes.func.isRequired,
  toggleSearchPlaceDialog: PropTypes.func.isRequired,
  clearSearchResult: PropTypes.func.isRequired,
  setPresetDuration: PropTypes.func.isRequired,
  setPresetDiaplayName: PropTypes.func.isRequired,
  getPlaceDetails: PropTypes.func.isRequired,
  addDestinationPlace: PropTypes.func.isRequired,
  joyrideStep: PropTypes.objectOf(PropTypes.any),
  clearJoyrideStep: PropTypes.func.isRequired,
  toggleLoginDialog: PropTypes.func.isRequired,
  setBookingRequestVia: PropTypes.func.isRequired,
  clearLoginRequestVia: PropTypes.func.isRequired,
  setCartypes: PropTypes.func.isRequired,
  changeDate: PropTypes.func.isRequired,
  changeGuestsComposition: PropTypes.func.isRequired,
  loadPriceAdjustments: PropTypes.func.isRequired,
  clearSkipLoginRedirect: PropTypes.func.isRequired,
};

PricingFunction.defaultProps = {
  itinerary: {},
  customize: {},
  booking: {},
  dialogs: {},
  places: {},
  mobileDetect: {},
  joyrideStep: {},
  priceAdjustments: {},

  initItineraryToBeCustomizable: () => {},
  t: () => {},
};

export default compose(
  provideHooks({
    inject: ({ store }) => store.inject({
      itineraryPricing: itineraryPricingReducer
    }),
    fetch: async ({ store: { dispatch, getState }, params }) => {
      const currentState = getState();
      const { id } = params;

      if (!isItineraryPricingLoaded(currentState, id) && id) {
        await dispatch(itineraryPricing(id)).catch(() => null);
      }
    }
  }),
  connect(state => ({
    itinerary: state.itineraryPricing?.data,
    mobileDetect: state.mobileDetect,
    customize: state.customize,
    booking: state.booking,
    dialogs: state.dialogs,
    places: state.places,
    joyrideStep: state.joyride,
    auth: state.auth,
    priceAdjustments: state.priceAdjustments,
  }), {
    initItineraryToBeCustomizable: _initItineraryToBeCustomizable,
    changeCarType: _changeCarType,
    changeLanguage: _changeLanguage,
    retrieveCarTypes: _retrieveCarTypes,
    persistEnquiry: _persistEnquiry,
    moveDestinationUp: _moveDestinationUp,
    moveDestinationDown: _moveDestinationDown,
    addStartingPlace: _addStartingPlace,
    addFinishingPlace: _addFinishingPlace,
    deleteDayTrip: _deleteDayTrip,
    selectPlaceId: _selectPlaceId,
    deleteDestination: _deleteDestination,
    changeDestinationPlaceDuration: _changeDestinationPlaceDuration,
    makeEnquiryRequest: _makeEnquiryRequest,
    toggleAddToRoute: _toggleAddToRoute,
    toggleDetailsForm: _toggleDetailsForm,
    toggleSearchPlaceDialog: _toggleSearchPlaceDialog,
    clearSearchResult: _clearSearchResult,
    setPresetDuration: _setPresetDuration,
    setPresetDiaplayName: _setPresetDiaplayName,
    getPlaceDetails: _getPlaceDetails,
    addDestinationPlace: _addDestinationPlace,
    clearJoyrideStep: clearStep,
    toggleLoginDialog: _toggleLoginDialog,
    setBookingRequestVia: _setBookingRequestVia,
    clearLoginRequestVia: _clearLoginRequestVia,
    setCartypes: _setCarTypes,
    changeDate: _changeDate,
    changeGuestsComposition: _changeGuestsComposition,
    loadPriceAdjustments: _loadPriceAdjustments,
    clearSkipLoginRedirect: _clearSkipLoginRedirect,
  }),
  withTranslation(['common', 'meta', 'new-version'])
)(PricingFunction);
