const LOAD_PRICE_ADJUSTMENTS = 'PRICE_ADJUSTMENTS/LOAD_PRICE_ADJUSTMENTS';
const LOAD_PRICE_ADJUSTMENTS_SUCCESS = 'PRICE_ADJUSTMENTS/LOAD_PRICE_ADJUSTMENTS_SUCCESS';
const LOAD_PRICE_ADJUSTMENTS_FAIL = 'PRICE_ADJUSTMENTS/LOAD_PRICE_ADJUSTMENTS_FAIL';

const initialState = () => ({
  loading: false,
  loaded: false,
  surcharges: [],
  discounts: [],
  surchargeAmount: 0,
  discountAmount: 0,
});

const formatResult = (adjustments = []) => {
  const surcharges = [];
  const discounts = [];
  let surchargeAmount = 0;
  let discountAmount = 0;

  adjustments.forEach(({ date, amount: _amount, description }) => {
    const amount = parseFloat(_amount);

    if (_amount > 0) {
      surchargeAmount += amount;
      surcharges.push({ amount, date, description });
    } else {
      discountAmount += amount;
      discounts.push({ amount, date, description });
    }
  });

  return {
    surcharges,
    discounts,
    surchargeAmount,
    discountAmount
  };
};

export default function reducer(state = initialState(), action = {}) {
  switch (action.type) {
    case LOAD_PRICE_ADJUSTMENTS:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case LOAD_PRICE_ADJUSTMENTS_SUCCESS:
      const {
        surcharges,
        discounts,
        surchargeAmount,
        discountAmount
      } = formatResult(action.result);

      return {
        ...state,
        loading: false,
        loaded: true,
        surcharges,
        discounts,
        surchargeAmount,
        discountAmount,
      };
    case LOAD_PRICE_ADJUSTMENTS_FAIL:
      return {
        ...state,
        ...initialState(),
        error: action.error,
      };
    default:
      return state;
  }
}

export function loadPriceAdjustments({
  cityId,
  fromDate,
  toDate,
  carTypeId,
  language,
}) {
  return {
    types: [LOAD_PRICE_ADJUSTMENTS, LOAD_PRICE_ADJUSTMENTS_SUCCESS, LOAD_PRICE_ADJUSTMENTS_FAIL],
    promise: ({ client }) => client.get('/priceadjustments/search', {
      params: {
        cityId,
        fromDate,
        toDate,
        carTypeId,
        language,
      }
    }),
  };
}
