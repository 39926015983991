const actionType = {
  SET_STEP: 'joyride/SET_STEP',
  CLEAR_STEP: 'joyride/CLEAR_STEP',
};

const initialState = {};

export default function joyride(state = initialState, action = {}) {
  switch (action.type) {
    case actionType.SET_STEP:
      return action.step;
    case actionType.CLEAR_STEP:
      return {};
    default:
      return state;
  }
}

export function setStep(step) {
  return {
    type: actionType.SET_STEP,
    step,
  };
}

export function clearStep() {
  return {
    type: actionType.CLEAR_STEP,
    step: {},
  };
}
