// import isEmpty from 'lodash.isempty';

import { sendGtagEvent } from '../../utils/gtag';

const LOAD_AIRPORT_DESTINATION_AUTOCOMPLETE =
  'searchBar/airport/LOAD_AUTOCOMPLETE';
const LOAD_AIRPORT_DESTINATION_AUTOCOMPLETE_SUCCESS =
  'searchBar/airport/LOAD_AUTOCOMPLETE_SUCCESS';
const LOAD_AIRPORT_DESTINATION_AUTOCOMPLETE_FAIL =
  'searchBar/airport/LOAD_AUTOCOMPLETE_FAIL';

const LOAD_AIRPORT_DESTINATION_SERCH_KEYWORD =
  'searchBar/airport/LOAD_SERCH_KEYWORD';
const LOAD_AIRPORT_DESTINATION_SERCH_KEYWORD_SUCCESS =
  'searchBar/airport/LOAD_SERCH_KEYWORD_SUCCESS';
const LOAD_AIRPORT_DESTINATION_SERCH_KEYWORD_FAIL =
  'searchBar/airport/LOAD_SERCH_KEYWORD_FAIL';

const LOAD_ONEWAY_FROM_AUTOCOMPLETE = 'searchBar/oneway/from/LOAD_AUTOCOMPLETE';
const LOAD_ONEWAY_FROM_AUTOCOMPLETE_SUCCESS =
  'searchBar/oneway/from/LOAD_AUTOCOMPLETE_SUCCESS';
const LOAD_ONEWAY_FROM_AUTOCOMPLETE_FAIL =
  'searchBar/oneway/from/LOAD_AUTOCOMPLETE_FAIL';

const LOAD_ONEWAY_FROM_SERCH_KEYWORD =
  'searchBar/oneway/from/LOAD_SERCH_KEYWORD';
const LOAD_ONEWAY_FROM_SERCH_KEYWORD_SUCCESS =
  'searchBar/oneway/from/LOAD_SERCH_KEYWORD_SUCCESS';
const LOAD_ONEWAY_FROM_SERCH_KEYWORD_FAIL =
  'searchBar/oneway/from/LOAD_SERCH_KEYWORD_FAIL';

const LOAD_ONEWAY_TO_AUTOCOMPLETE = 'searchBar/oneway/to/LOAD_AUTOCOMPLETE';
const LOAD_ONEWAY_TO_AUTOCOMPLETE_SUCCESS =
  'searchBar/oneway/to/LOAD_AUTOCOMPLETE_SUCCESS';
const LOAD_ONEWAY_TO_AUTOCOMPLETE_FAIL =
  'searchBar/oneway/to/LOAD_AUTOCOMPLETE_FAIL';

const LOAD_ONEWAY_TO_SERCH_KEYWORD = 'searchBar/oneway/to/LOAD_SERCH_KEYWORD';
const LOAD_ONEWAY_TO_SERCH_KEYWORD_SUCCESS =
  'searchBar/oneway/to/LOAD_SERCH_KEYWORD_SUCCESS';
const LOAD_ONEWAY_TO_SERCH_KEYWORD_FAIL =
  'searchBar/oneway/to/LOAD_SERCH_KEYWORD_FAIL';

const SET_AIRPORT_AIRPORT = 'searchBar/airport/airport/SET_SELECTED_PLCAE';
const SET_AIRPORT_DESTINATION =
  'searchBar/airport/destination/SET_SELECTED_PLCAE';
const CLEAR_AIRPORT_DESTINATION =
  'searchBar/airport/destination/CLEAR_SELECTED_PLCAE';
const CLEAR_AIRPORT_SEARCH_RESULT =
  'searchBar/airport/destination/CLEAR_AIRPORT_SEARCH_RESULT';

const SET_ONEWAY_FROM_DESTINATION = 'searchBar/oneway/from/SET_SELECTED_PLCAE';
const CLEAR_ONEWAY_FROM_DESTINATION =
  'searchBar/oneway/from/CLEAR_SELECTED_PLCAE';
const CLEAR_ONEWAY_FROM_SEARCH_RESULT =
  'searchBar/oneway/from/CLEAR_ONEWAY_FROM_SEARCH_RESULT';

const SET_ONEWAY_TO_DESTINATION = 'searchBar/oneway/to/SET_SELECTED_PLCAE';
const CLEAR_ONEWAY_TO_DESTINATION = 'searchBar/oneway/to/CLEAR_SELECTED_PLCAE';
const CLEAR_ONEWAY_TO_SEARCH_RESULT =
  'searchBar/oneway/to/CLEAR_ONEWAY_TO_SEARCH_RESULT';

// const LOAD_SEARCH_PLACE = 'search/places/LOAD_SEARCH_PLACE';
// const LOAD_SEARCH_PLACE_SUCCESS = 'search/places/LOAD_SEARCH_PLACE_SUCCESS';
// const LOAD_SEARCH_PLACE_FAIL = 'search/places/LOAD_SEARCH_PLACE_FAIL';
// const SET_SELECTED_PLACE = 'search/places/SET_SELECTED_PLCAE';

const CLEAR_SEARCH_RESULT = 'search/places/CLEAR';

const initialState = () => ({
  loadingAirportDestination: false,
  loadedAirportDestination: false,
  suggestionAirportDestination: [],
  searchedAirport: false,
  placeSearchResultAirportDestination: [],
  errorAirportDestination: null,

  airportDestination: {},
  airportAirport: {},
  searchKeyAirport: '',

  loadingOnewayFrom: false,
  loadedOnewayFrom: false,
  suggestionOnewayFrom: [],
  searchedOnewayFrom: false,
  placeSearchResultOnewayFrom: [],
  errorOnewayFrom: null,

  onewayFromDestination: {},
  searchKeyOnewayFrom: '',

  loadingOnewayTo: false,
  loadedOnewayTo: false,
  suggestionOnewayTo: [],
  searchedOnewayTo: false,
  placeSearchResultOnewayTo: [],
  errorOnewayTo: null,

  onewayToDestination: {},
  searchKeyOnewayTo: ''
});

export default function reducer(state = initialState(), action = {}) {
  switch (action.type) {
    case LOAD_AIRPORT_DESTINATION_SERCH_KEYWORD:
      return {
        ...state,
        loadingAirportDestination: true,
        searchedAirport: false,
        placeSearchResultAirportDestination: [],
        suggestionAirportDestination: []
      };
    case LOAD_AIRPORT_DESTINATION_AUTOCOMPLETE:
      return {
        ...state,
        loadingAirportDestination: true
      };
    case LOAD_AIRPORT_DESTINATION_AUTOCOMPLETE_SUCCESS:
      if (action.result && action.result.length === 0) {
        sendGtagEvent('Not Foound', 'Place Autocomplete', action.searchKey);
      }

      return {
        ...state,
        loadingAirportDestination: false,
        loadedAirportDestination: true,
        suggestionAirportDestination: action.result,
        errorAirportDestination: null
      };
    case LOAD_AIRPORT_DESTINATION_AUTOCOMPLETE_FAIL:
      return {
        ...state,
        loadingAirportDestination: false,
        loadedAirportDestination: false,
        suggestionAirportDestination: null,
        errorAirportDestination:
          typeof action.error === 'string' ? action.error : 'Error'
      };
    case LOAD_AIRPORT_DESTINATION_SERCH_KEYWORD_SUCCESS:
      return {
        ...state,
        loadingAirportDestination: false,
        searchedAirport: true,
        placeSearchResultAirportDestination: action.result
      };
    case LOAD_AIRPORT_DESTINATION_SERCH_KEYWORD_FAIL:
      return {
        ...state,
        loadingAirportDestination: false,
        searchedAirport: true,
        placeSearchResultAirportDestination: []
      };

    case LOAD_ONEWAY_FROM_SERCH_KEYWORD:
      return {
        ...state,
        loadingOnewayFrom: true,
        searchedOnewayFrom: false,
        placeSearchResultOnewayFrom: [],
        suggestionOnewayFrom: []
      };
    case LOAD_ONEWAY_FROM_AUTOCOMPLETE:
      return {
        ...state,
        loadingOnewayFrom: true
      };
    case LOAD_ONEWAY_FROM_AUTOCOMPLETE_SUCCESS:
      if (action.result && action.result.length === 0) {
        sendGtagEvent('Not Foound', 'Place Autocomplete', action.searchKey);
      }

      return {
        ...state,
        loadingOnewayFrom: false,
        loadedOnewayFrom: true,
        suggestionOnewayFrom: action.result,
        errorOnewayFrom: null
      };
    case LOAD_ONEWAY_FROM_AUTOCOMPLETE_FAIL:
      return {
        ...state,
        loadingOnewayFrom: false,
        loadedOnewayFrom: false,
        suggestionOnewayFrom: null,
        errorOnewayFrom:
          typeof action.error === 'string' ? action.error : 'Error'
      };
    case LOAD_ONEWAY_FROM_SERCH_KEYWORD_SUCCESS:
      return {
        ...state,
        loadingOnewayFrom: false,
        searchedOnewayFrom: true,
        placeSearchResultOnewayFrom: action.result
      };
    case LOAD_ONEWAY_FROM_SERCH_KEYWORD_FAIL:
      return {
        ...state,
        loadingOnewayFrom: false,
        searchedOnewayFrom: true,
        placeSearchResultOnewayFrom: []
      };

    case LOAD_ONEWAY_TO_SERCH_KEYWORD:
      return {
        ...state,
        loadingOnewayTo: true,
        searchedOnewayTo: false,
        placeSearchResultOnewayTo: [],
        suggestionOnewayTo: []
      };
    case LOAD_ONEWAY_TO_AUTOCOMPLETE:
      return {
        ...state,
        loadingOnewayTo: true
      };
    case LOAD_ONEWAY_TO_AUTOCOMPLETE_SUCCESS:
      if (action.result && action.result.length === 0) {
        sendGtagEvent('Not Foound', 'Place Autocomplete', action.searchKey);
      }

      return {
        ...state,
        loadingOnewayTo: false,
        loadedOnewayTo: true,
        suggestionOnewayTo: action.result,
        errorOnewayTo: null
      };
    case LOAD_ONEWAY_TO_AUTOCOMPLETE_FAIL:
      return {
        ...state,
        loadingOnewayTo: false,
        loadedOnewayTo: false,
        suggestionOnewayTo: null,
        errorOnewayTo: typeof action.error === 'string' ? action.error : 'Error'
      };
    case LOAD_ONEWAY_TO_SERCH_KEYWORD_SUCCESS:
      return {
        ...state,
        loadingOnewayTo: false,
        searchedOnewayTo: true,
        placeSearchResultOnewayTo: action.result
      };
    case LOAD_ONEWAY_TO_SERCH_KEYWORD_FAIL:
      return {
        ...state,
        loadingOnewayTo: false,
        searchedOnewayTo: true,
        placeSearchResultOnewayTo: []
      };

    case SET_AIRPORT_DESTINATION:
      return {
        ...state,
        airportDestination: action.place
      };
    case CLEAR_AIRPORT_DESTINATION:
      return {
        ...state,
        airportDestination: {},
        searchKeyAirport: ''
      };
    case CLEAR_AIRPORT_SEARCH_RESULT:
      return {
        ...state,
        searchedAirport: false,
        placeSearchResultAirportDestination: [],
        suggestionAirportDestination: []
      };

    case CLEAR_ONEWAY_FROM_SEARCH_RESULT:
      return {
        ...state,
        searchedOnewayFrom: false,
        placeSearchResultOnewayFrom: [],
        suggestionOnewayFrom: []
      };

    case CLEAR_ONEWAY_TO_SEARCH_RESULT:
      return {
        ...state,
        searchedOnewayTo: false,
        placeSearchResultOnewayTo: [],
        suggestionOnewayTo: []
      };

    case SET_AIRPORT_AIRPORT:
      return {
        ...state,
        airportAirport: action.place
      };
    case SET_ONEWAY_FROM_DESTINATION:
      return {
        ...state,
        onewayFromDestination: action.place
      };
    case SET_ONEWAY_TO_DESTINATION:
      return {
        ...state,
        onewayToDestination: action.place
      };
    case CLEAR_ONEWAY_FROM_DESTINATION:
      return {
        ...state,
        onewayFromDestination: {},
        searchKeyOnewayFrom: ''
      };
    case CLEAR_ONEWAY_TO_DESTINATION:
      return {
        ...state,
        onewayToDestination: {},
        searchKeyOnewayTo: ''
      };

    // case LOAD_SEARCH_PLACE_SUCCESS:
    //   let guessResult = [];

    //   if (action.result && action.result.length) {
    //     // guessResult = action.result.filter(place =>
    //     //   (place && place.formatted_address.indexOf('Taiwan') >= 0) ||
    //     //   (place && place.formatted_address.indexOf('Thailand') >= 0));

    //     guessResult = action.result;
    //   }

    //   return {
    //     ...state,
    //     loading: false,
    //     loaded: true,
    //     selected: guessResult,
    //     detailsError: null,
    //     error: null
    //   };

    case CLEAR_SEARCH_RESULT:
      return {
        ...state,
        loading: false,
        loaded: false,
        suggestion: [],
        searchKey: '',
        error: null,
        selected: null,
        details: null,
        presetDuration: 60,
        presetDisplayName: '',
        searchedAirport: false,
        placeSearchResultAirportDestination: [],
        searchedOnewayFrom: false,
        placeSearchResultOnewayFrom: [],
        searchedOnewayTo: false,
        placeSearchResultOnewayTo: []
      };
    default:
      return state;
  }
}

export function searchAutocompleteAirportDestination(searchKey, country) {
  return {
    types: [
      LOAD_AIRPORT_DESTINATION_AUTOCOMPLETE,
      LOAD_AIRPORT_DESTINATION_AUTOCOMPLETE_SUCCESS,
      LOAD_AIRPORT_DESTINATION_AUTOCOMPLETE_FAIL
    ],
    promise: ({ client }) =>
      client.get('/places/autocomplete', {
        params: {
          searchKey,
          country
        }
      }),
    searchKey
  };
}

export function selectAirportDestinationPlaceId(place) {
  return {
    type: SET_AIRPORT_DESTINATION,
    place
  };
}

export function clearAirportDestination() {
  return {
    type: CLEAR_AIRPORT_DESTINATION
  };
}

export function selectAirportAirport(place) {
  return {
    type: SET_AIRPORT_AIRPORT,
    place
  };
}

export function searchAutocompleteOnewayFrom(searchKey, country) {
  return {
    types: [
      LOAD_ONEWAY_FROM_AUTOCOMPLETE,
      LOAD_ONEWAY_FROM_AUTOCOMPLETE_SUCCESS,
      LOAD_ONEWAY_FROM_AUTOCOMPLETE_FAIL
    ],
    promise: ({ client }) =>
      client.get('/places/autocomplete', {
        params: {
          searchKey,
          country
        }
      }),
    searchKey
  };
}

export function selectOnewayFromPlaceId(place) {
  return {
    type: SET_ONEWAY_FROM_DESTINATION,
    place
  };
}

export function clearOnewayFrom() {
  return {
    type: CLEAR_ONEWAY_FROM_DESTINATION
  };
}

export function searchAutocompleteOnewayTo(searchKey, country) {
  return {
    types: [
      LOAD_ONEWAY_TO_AUTOCOMPLETE,
      LOAD_ONEWAY_TO_AUTOCOMPLETE_SUCCESS,
      LOAD_ONEWAY_TO_AUTOCOMPLETE_FAIL
    ],
    promise: ({ client }) =>
      client.get('/places/autocomplete', {
        params: {
          searchKey,
          country
        }
      }),
    searchKey
  };
}

export function selectOnewayToPlaceId(place) {
  return {
    type: SET_ONEWAY_TO_DESTINATION,
    place
  };
}

export function clearOnewayTo() {
  return {
    type: CLEAR_ONEWAY_TO_DESTINATION
  };
}

export function searchKeyWordPlaceAirportDestination(searchKey, country) {
  return {
    types: [
      LOAD_AIRPORT_DESTINATION_SERCH_KEYWORD,
      LOAD_AIRPORT_DESTINATION_SERCH_KEYWORD_SUCCESS,
      LOAD_AIRPORT_DESTINATION_SERCH_KEYWORD_FAIL
    ],
    promise: ({ client }) =>
      client.get('/places/search', {
        params: {
          searchKey,
          country
        }
      })
  };
}

export function searchKeyWordPlaceOnewayTo(searchKey, country = 'tw') {
  return {
    types: [
      LOAD_ONEWAY_TO_SERCH_KEYWORD,
      LOAD_ONEWAY_TO_SERCH_KEYWORD_SUCCESS,
      LOAD_ONEWAY_TO_SERCH_KEYWORD_FAIL
    ],
    promise: ({ client }) =>
      client.get('/places/search', {
        params: {
          searchKey,
          country
        }
      })
  };
}

export function searchKeyWordPlaceOnewayFrom(searchKey, country = 'tw') {
  return {
    types: [
      LOAD_ONEWAY_FROM_SERCH_KEYWORD,
      LOAD_ONEWAY_FROM_SERCH_KEYWORD_SUCCESS,
      LOAD_ONEWAY_FROM_SERCH_KEYWORD_FAIL
    ],
    promise: ({ client }) =>
      client.get('/places/search', {
        params: {
          searchKey,
          country
        }
      })
  };
}

export function clearAirportSearchResult() {
  return {
    type: CLEAR_AIRPORT_SEARCH_RESULT
  };
}

export function clearOnewayFromSearchResult() {
  return {
    type: CLEAR_ONEWAY_FROM_SEARCH_RESULT
  };
}

export function clearOnewayToSearchResult() {
  return {
    type: CLEAR_ONEWAY_TO_SEARCH_RESULT
  };
}
