const LOAD = 'categories/LOAD';
const LOAD_SUCCESS = 'categories/LOAD_SUCCESS';
const LOAD_FAIL = 'categories/LOAD_FAIL';

const initialState = () => ({
  loaded: false,
  categories: [],
});

export default function reducer(state = initialState(), action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        categories: action.result,
        error: null
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        categories: null,
        error: typeof action.error === 'string' ? action.error : 'Error'
      };
    default:
      return state;
  }
}

export function isLoaded(globalState) {
  return globalState.categories && globalState.categories.loaded;
}

export function load() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ client }) => client.get('/categories/load')
  };
}
