import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import MuiSadIcon from '@material-ui/icons/SentimentDissatisfiedRounded';
import theme from '../../theme/styled-theme';

const Root = styled.div`
  color: ${props => props.theme.vibrantRed};
  border-radius: 4px;
  border: 1px solid ${props => props.theme.vibrantRed};
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
`;

const ErrorMessage = styled.p`
  display: flex;
  align-items: center;
`;

const SadIcon = styled(MuiSadIcon)`
  margin-right: 0.5rem;
`;

const ErrorBoundaryFallbackComponent = ({ error }) => ( // eslint-disable-line react/prop-types
  <ThemeProvider theme={theme}>
    <Root>
      <ErrorMessage>
        <SadIcon /><strong>Oops! An error occured!</strong>
      </ErrorMessage>
      <ErrorMessage>{ error.toString() }</ErrorMessage>
    </Root>
  </ThemeProvider>
);

export default ErrorBoundaryFallbackComponent;
