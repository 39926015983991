import auth from './modules/auth';
import info from './modules/info';
import dialogs from './modules/dialogs';
import config from './modules/config';
import magiclink from './modules/magiclink';
import customize from './modules/customizeBooking';
import booking from './modules/booking';
import planner from './modules/planner';
import destinations from './modules/destinations';
import recommendedDestinations from './modules/recommendedDestinations';
import places from './modules/places';
import searchBar from './modules/searchBar';
import redirect from './modules/redirect';
import recommended from './modules/recommended';
import recommendedReviews from './modules/recommendedReviews';
import mobileDetect from './modules/mobileDetect';
import categories from './modules/categories';
import joyride from './modules/joyride';
import priceAdjustments from './modules/priceAdjustments';

export default function createReducers(asyncReducers) {
  return {
    online: (v = true) => v,
    booking,
    customize,
    planner,
    auth,
    info,
    categories,
    dialogs,
    config,
    recommendedReviews,
    recommended,
    destinations,
    recommendedDestinations,
    places,
    searchBar,
    magiclink,
    redirect,
    mobileDetect,
    joyride,
    priceAdjustments,
    ...asyncReducers
  };
}
