import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Line = styled.div`
  display: block;
  height: 1px;
  width: 100%;
  background-color: ${props => props.color};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
`;

const SeparationLine = memo(props => <Line {...props} />);

SeparationLine.propTypes = {
  padding: PropTypes.string,
  margin: PropTypes.string,
  color: PropTypes.string
};

SeparationLine.defaultProps = {
  padding: '0',
  margin: '0.75rem 0',
  color: '#DBDBDB'
};

export default SeparationLine;
