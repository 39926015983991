import config from '../config';

export function sendGtagEvent(action, category, label = '') {
  if (typeof window === 'undefined') return;

  const { gtag, amplitude } = window;

  if (typeof gtag === 'function') {
    gtag('event', action, {
      event_category: category,
      event_action: action,
      event_label: label
    });
  }

  if (amplitude) {
    amplitude.getInstance().logEvent(action, { type: 'Google Analytics', label, category });
  }
}

export function activateOptimize({ id, variant }) {
  if (typeof window !== 'undefined' && typeof gtag === 'function' && id) {
    // This is necessary for Google Optimize to track active session.
    window.gtag('config', config.ga, {
      send_page_view: false,
      experiments: [
        { id, variant }
      ]
    });

    setTimeout(() => {
      window.gtag(
        'event',
        'optimize.activate', {
          non_interaction: true,
          experiments: [
            { id, variant }
          ],
          event_label: id,
          value: variant,
        }
      );
    }, 0);

    window.dataLayer.push({
      event: 'optimize.activate'
    });
  }
}

export function sendEvent({
  action,
  category,
  label = '',
  value,
  nonInteraction = false,
}) {
  if (typeof window === 'undefined') return;

  const { gtag, amplitude } = window;

  if (typeof gtag === 'function') {
    gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
      non_interaction: nonInteraction,
    });
  }

  if (amplitude) {
    amplitude.getInstance().logEvent(action, { type: 'Google Analytics', label, category });
  }
}

export function sendGtagPageViewEvent(path = '/', experiments = []) {
  if (typeof window === 'undefined') return;

  const { gtag, amplitude } = window;

  if (typeof gtag === 'function') {
    gtag('config', config.ga, {
      page_title: document.title,
      page_location: window.location.href,
      page_path: path,
      experiments,
    });
  }

  if (amplitude) {
    amplitude.getInstance().logEvent('Page View', {
      type: 'Google Analytics',
      label: path,
      category: 'Page View',
      experiments
    });
  }
}

export function sendGtagPurchaseEvent(transactionId, transactionFee, currency = 'twd', items = []) {
  if (typeof window === 'undefined') return;

  const { gtag, amplitude } = window;

  if (typeof gtag === 'function') {
    gtag('event', 'purchase', {
      transaction_id: transactionId,
      affiliation: 'ownrides.com',
      value: transactionFee,
      currency,
      tax: 0,
      shipping: 0,
      items,
    });
  }

  if (amplitude && items.length) {
    const revenue = new amplitude
      .Revenue()
      .setProductId(items[0].id).setPrice(transactionFee).setEventProperties({
        items,
      });
    amplitude.logRevenueV2(revenue);
  }
}

export function sendGtagConversionEvent(booking, currency = 'twd', country, cb) {
  if (typeof window === 'undefined') return;
  if (!booking) return;

  const { gtag, amplitude } = window;
  const productId = booking?.itinerary?.id;
  const transactionId = booking?.id;
  const deposit = booking?.deposit;

  if (typeof gtag === 'function') {
    gtag('event', 'conversion', {
      send_to: 'AW-825690816/_xr0CKnq-HoQwJXciQM',
      value: deposit,
      currency,
      transaction_id: transactionId,
      event_callback: cb
    });
  }

  if (amplitude) {
    amplitude.getInstance().logEvent(
      'Conversion',
      {
        productId,
        country,
        currency,
        value: deposit
      }
    );
  }
}

export default sendGtagEvent;
