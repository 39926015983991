import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/Loading';

const AppLoadable = Loadable({
  loader: () => import('./App')
    .then(module => module.default)
    .catch(e => {
      console.error(e);
      if (typeof window !== 'undefined') window.location.reload();
    }),
  loading: () => <div key="AppLoadable"><Loading /></div>
});

export default AppLoadable;
