import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/Loading';

const AboutLoadable = Loadable({
  loader: () => import('./About').then(module => module.default),
  loading: () => <div key="AboutLoadable"><Loading /></div>
});

export default AboutLoadable;
