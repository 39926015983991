import { ErrorBoundary, withErrorBoundary } from 'react-error-boundary';
import { captureException } from '@sentry/browser';
import ErrorBoundaryFallbackComponent from './ErrorBoundaryFallbackComponent';

const onErrorHandler = (error, componentStack) => {
  // console.log('in onErrorHandler', error);
  captureException(error, { // send to crash reporting tool
    extra: {
      componentStack,
    }
  });
};

export { onErrorHandler, ErrorBoundaryFallbackComponent, withErrorBoundary };
export default ErrorBoundary;
