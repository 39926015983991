import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';

const Button = styled(MuiButton)`
  && {
    ${props => props.overwritestyles}
  }
`;

const StyledButton = props => {
  const { children } = props;

  return (
    <Button {...props}>
      { children }
    </Button>
  );
};

StyledButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  overwritestyles: PropTypes.arrayOf(PropTypes.any),
};

StyledButton.defaultProps = {
  overwritestyles: [],
};

export default StyledButton;
