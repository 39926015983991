import moment from 'moment-timezone';
import {
  CHANGE_TRIP_NAME,
  CHANGE_TRIP_DATE,
  CHANGE_START_TIME_OF_DAY,
  LOAD_SAVED_ENQUIRY_SUCCESS
} from './planner';
import {
  LOGIN_WITH_MAGIC_LINK_SUCCESS,
  LOAD_PRESERVED_STATE_SUCCESS
} from './auth';


const CHANGE_DATE = 'booking/form/CHANGE_DATE';
const CHANGE_CAR_TYPE = 'booking/form/CHANGE_CAR_TYPE';
const CHANGE_LANGUAGE = 'booking/form/CHANGE_LANGUAGE';
const CHANGE_TOUR_GUIDE_SERVICE = 'booking/form/CHANGE_TOUR_GUIDE_SERVICE';
const CLEAR_BOOKING_DATA = 'booking/form/CLEAR_BOOKING_DATA';
const CLEAR_BOOKING_ERROR = 'booking/form/CLEAR_BOOKING_ERROR';
const CHANGE_TOTAL_GUESTS = 'booking/form/CHANGE_TOTAL_GUESTS';
const CHANGE_GUESTS_COMPOSITION = 'booking/form/CHANGE_GUESTS_COMPOSITION';
const CHANGE_TOTAL_LUGGAGES = 'booking/form/CHANGE_TOTAL_LUGGAGES';
const CHANGE_FULL_NAME = 'booking/form/CHANGE_FULL_NAME';
const CHANGE_MOBILE = 'booking/form/CHANGE_MOBILE';
const CHANGE_MESSAGING_TYPE = 'booking/form/CHANGE_MESSAGING_TYPE';
const CHANGE_MESSAGING_ID = 'booking/form/CHANGE_MESSAGING_ID';
const CHANGE_MEETUP_TIME = 'booking/form/CHANGE_MEETUP_TIME';
const CHANGE_PICKUP_LOCATION = 'booking/form/CHANGE_PICKUP_LOCATION';
const CHANGE_DROPOFF_LOCATION = 'booking/form/CHANGE_DROPOFF_LOCATION';
const CHANGE_OTHER_INFO = 'booking/form/CHANGE_OTHER_INFO';

const BOOKING_REQUEST = 'booking/api/BOOKING_REQUEST';
export const BOOKING_SUCCESS = 'booking/api/BOOKING_SUCCESS';
const BOOKING_FAIL = 'booking/api/BOOKING_FAIL';

const BOOKING_CUSTOMIZE_REQUEST = 'booking/api/BOOKING_CUSTOMIZE_REQUEST';
export const BOOKING_CUSTOMIZE_SUCCESS = 'booking/api/BOOKING_CUSTOMIZE_SUCCESS';
const BOOKING_CUSTOMIZE_FAIL = 'booking/api/BOOKING_CUSTOMIZE_FAIL';

const CREATE_BOOKING_AND_CHARGE = 'booking/create/CREATE_BOOKING_AND_CHARGE';
const CREATE_BOOKING_AND_CHARGE_SUCCESS = 'booking/create/CREATE_BOOKING_AND_CHARGE_SUCCESS';
const CREATE_BOOKING_AND_CHARGE_FAIL = 'booking/create/CREATE_BOOKING_AND_CHARGE_FAIL';

const CLEAR_BOOKED_RESULT = 'booking/api/CLEAR_BOOKED_RESULT';
const RESTORE_BOOKING_OBJECT = 'restore/RESTORE_BOOKING_OBJECT';

const CLEAR_SKIP_LOGIN_REDIRECT = 'CLEAR_SKIP_LOGIN_REDIRECT';
const SET_SKIP_LOGIN_BOOKING = 'SET_SKIP_LOGIN_BOOKING';
const SET_BOOKING_REQUEST_VIA = 'SET_BOOKING_REQUEST_VIA';
const SET_IS_CUSTOMIZE_TRIP = 'SET_IS_CUSTOMIZE_TRIP';
const CLEAR_LOGIN_REQUEST_VIA = 'CLEAR_LOGIN_REQUEST_VIA';

const RESET_GUEST_CAR_LANGUAGE_TIME_AND_PLACE = 'booking/RESET_GUEST_CAR_LANGUAGE_TIME_AND_PLACE';

const CHANGE_FLIGHT_NO = 'CHANGE_FLIGHT_NO';

const initialState = () => ({
  name: '',
  date: null,
  tourGuideService: false,
  totalLuggages: 1,
  fullName: '',
  mobile: '',
  messagingType: '',
  messagingId: '',
  pickupLocation: '',
  dropoffLocation: '',
  otherInfo: '',
  flightNo: '',
  loading: false,
  bookedResult: null,
  isBooked: false,
  via: '',
  isCustomizeTrip: false,
  totalGuests: 1,
  adult: 1,
  children: 0,
  infant: 0,
  language: '',
  meetupTime: '09:00',
  carType: 1,
  skipLoginBooking: false,
  skipLoginRedirect: false,
  isBookingCreating: false,
  isBookingCreated: false,
  error: null
});

export default function reducer(state = initialState(), action = {}) {
  switch (action.type) {
    /**
     *
     * Planner.js Actions
     *
     */
    case LOAD_SAVED_ENQUIRY_SUCCESS:
      const { result: { enquiry: _enquiry } } = action;
      const { multiple_days: multipleDays } = _enquiry;
      const TAIWAN_TIME_ZONE = 'Asia/Taipei';

      return {
        ...state,
        date: multipleDays[0].pick_up_time
          ? moment(multipleDays[0].pick_up_time)
            .tz(TAIWAN_TIME_ZONE)
            .format('YYYY-MM-DD')
          : state.startDate,
        meetupTime: multipleDays[0].pick_up_time ? multipleDays[0].pick_up_time.slice(11, 16) : state.meetupTime, // Example: "2018-08-08 10:30 +08:00".slice(11, 16)
      };
    case CREATE_BOOKING_AND_CHARGE:
      return {
        ...state,
        isBookingCreating: true,
        isBookingCreated: false,
      };
    case CREATE_BOOKING_AND_CHARGE_SUCCESS:
      const { error } = action.result;

      if (error) {
        return {
          ...state,
          isBookingCreating: false,
          isBookingCreated: false,
          error: {
            message: error,
            createdAt: Date.now()
          }
        };
      }

      return {
        ...state,
        isBookingCreating: false,
        isBookingCreated: true,
        bookedResult: action.result,
      };
    case CREATE_BOOKING_AND_CHARGE_FAIL:
      return {
        ...state,
        isBookingCreating: false,
        isBookingCreated: false,
        error: action.error
      };
    case SET_SKIP_LOGIN_BOOKING:
      return {
        ...state,
        skipLoginBooking: action.isSkipLogin,
        skipLoginRedirect: action.isSkipLogin,
      };
    case CLEAR_SKIP_LOGIN_REDIRECT:
      if (state.skipLoginRedirect === false) return state;

      return {
        ...state,
        skipLoginRedirect: false,
      };
    case CHANGE_START_TIME_OF_DAY:
      let meetupTime = state.meetupTime; // eslint-disable-line

      if (action.day === 0) {
        meetupTime = action.startTime;
      }

      return {
        ...state,
        meetupTime,
      };
    case CHANGE_TRIP_NAME:
      if (state.name === action.name) return state;

      return {
        ...state,
        name: action.name,
      };
    case CHANGE_TRIP_DATE:
      return {
        ...state,
        date: action.startDate,
      };
      /* End of Planner.js Actions */

    /**
     * Auth.js Actions
     */
    case LOAD_PRESERVED_STATE_SUCCESS:
    case LOGIN_WITH_MAGIC_LINK_SUCCESS:
      const { preservedState } = action.result;
      return preservedState && preservedState.booking ? { ...preservedState.booking } : { ...state };
      /* End of Auth.js Actions */

    case CHANGE_DATE:
      return {
        ...state,
        date: action.date,
      };
    case CHANGE_CAR_TYPE:
      if (state.carType === action.carType) return state;

      return {
        ...state,
        carType: action.carType,
      };
    case CHANGE_LANGUAGE:
      if (state.language === action.language) return state;

      return {
        ...state,
        language: action.language,
      };
    case CHANGE_TOUR_GUIDE_SERVICE:
      if (state.tourGuideService === action.tourGuideService) return state;

      return {
        ...state,
        tourGuideService: action.tourGuideService,
      };
    case CHANGE_GUESTS_COMPOSITION:
      if (
        state.adult === action.adult
        && state.children === action.children
        && state.infant === action.infant
      ) {
        return state;
      }

      return {
        ...state,
        adult: action.adult,
        children: action.children,
        infant: action.infant,
        totalGuests: action.adult + action.children + action.infant,
      };
    case CHANGE_TOTAL_GUESTS:
      if (state.totalGuests === action.totalGuests) return state;

      return {
        ...state,
        totalGuests: action.totalGuests,
      };
    case CHANGE_TOTAL_LUGGAGES:
      if (state.totalLuggages === action.totalLuggages) return state;

      return {
        ...state,
        totalLuggages: action.totalLuggages,
      };
    case CHANGE_FULL_NAME:
      if (state.fullName === action.fullName) return state;

      return {
        ...state,
        fullName: action.fullName,
      };
    case CHANGE_MOBILE:
      if (state.mobile === action.mobile) return state;

      return {
        ...state,
        mobile: action.mobile,
      };
    case CHANGE_MESSAGING_ID:
      if (state.messagingId === action.messagingId) return state;

      return {
        ...state,
        messagingId: action.messagingId,
      };
    case CHANGE_MESSAGING_TYPE:
      if (state.messagingType === action.messagingType) return state;

      return {
        ...state,
        messagingType: action.messagingType,
      };
    case CHANGE_MEETUP_TIME:
      if (state.meetupTime === action.meetupTime) return state;

      return {
        ...state,
        meetupTime: action.meetupTime,
      };
    case CHANGE_PICKUP_LOCATION:
      if (state.pickupLocation === action.pickupLocation) return state;

      return {
        ...state,
        pickupLocation: action.pickupLocation,
      };
    case CHANGE_DROPOFF_LOCATION:
      if (state.dropoffLocation === action.dropoffLocation) return state;

      return {
        ...state,
        dropoffLocation: action.dropoffLocation,
      };
    case CHANGE_FLIGHT_NO:
      if (state.flightNo === action.flightNo) return state;

      return {
        ...state,
        flightNo: action.flightNo,
      };
    case CHANGE_OTHER_INFO:
      if (state.otherInfo === action.otherInfo) return state;

      return {
        ...state,
        otherInfo: action.otherInfo,
      };
    case BOOKING_CUSTOMIZE_REQUEST:
    case BOOKING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case BOOKING_CUSTOMIZE_SUCCESS:
    case BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        bookedResult: action.result,
        isBooked: action.result && action.result.id,
      };
    case BOOKING_CUSTOMIZE_FAIL:
    case BOOKING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case RESTORE_BOOKING_OBJECT:
      return {
        ...action.booking,
      };
    case CLEAR_BOOKING_ERROR:
      return {
        ...state,
        bookedResult: null,
        error: null,
      };
    case SET_BOOKING_REQUEST_VIA:
      if (state.via === action.via) return state;

      return {
        ...state,
        via: action.via,
      };
    case SET_IS_CUSTOMIZE_TRIP:
      return {
        ...state,
        isCustomizeTrip: action.isCustomizeTrip,
      };
    case CLEAR_LOGIN_REQUEST_VIA:
      if (state.via === '') return state;

      return {
        ...state,
        via: '',
      };
    case RESET_GUEST_CAR_LANGUAGE_TIME_AND_PLACE:
      return {
        ...state,
        totalGuests: 1,
        adult: 1,
        children: 0,
        infant: 0,
        language: '',
        meetupTime: '09:00',
        carType: 1,
        pickupLocation: '',
        dropoffLocation: '',
      };
    case CLEAR_BOOKED_RESULT:
      return initialState();
    case CLEAR_BOOKING_DATA:
      return initialState();
    default:
      return state;
  }
}

export function changeFlightNumber(flightNo) {
  return {
    type: CHANGE_FLIGHT_NO,
    flightNo,
  };
}

export function changeDate(date) {
  return {
    type: CHANGE_DATE,
    date,
  };
}

export function changeCarType(carType) {
  return {
    type: CHANGE_CAR_TYPE,
    carType,
  };
}

export function changeLanguage(language) {
  return {
    type: CHANGE_LANGUAGE,
    language,
  };
}

export function changeTourGuideService(tourGuideService) {
  return {
    type: CHANGE_TOUR_GUIDE_SERVICE,
    tourGuideService,
  };
}

export function clearBookingData() {
  return {
    type: CLEAR_BOOKING_DATA,
  };
}

export function changeTotalGuests(totalGuests) {
  return {
    type: CHANGE_TOTAL_GUESTS,
    totalGuests,
  };
}

export function changeGuestsComposition({ adult = 0, children = 0, infant = 0 }) {
  return {
    type: CHANGE_GUESTS_COMPOSITION,
    adult,
    children,
    infant,
  };
}

export function changeTotalLuggages(totalLuggages) {
  return {
    type: CHANGE_TOTAL_LUGGAGES,
    totalLuggages,
  };
}

export function changeFullName(fullName) {
  return {
    type: CHANGE_FULL_NAME,
    fullName,
  };
}

export function changeMobile(mobile) {
  return {
    type: CHANGE_MOBILE,
    mobile,
  };
}

export function changeMessagingId(messagingId) {
  return {
    type: CHANGE_MESSAGING_ID,
    messagingId,
  };
}

export function changeMessagingType(messagingType) {
  return {
    type: CHANGE_MESSAGING_TYPE,
    messagingType,
  };
}

export function changeMeetupTime(meetupTime) {
  return {
    type: CHANGE_MEETUP_TIME,
    meetupTime,
  };
}

export function changePickupLocation(pickupLocation) {
  return {
    type: CHANGE_PICKUP_LOCATION,
    pickupLocation,
  };
}

export function changeDropoffLocation(dropoffLocation) {
  return {
    type: CHANGE_DROPOFF_LOCATION,
    dropoffLocation,
  };
}

export function changeOtherInfo(otherInfo) {
  return {
    type: CHANGE_OTHER_INFO,
    otherInfo,
  };
}

export function createBookingAndCharge({
  bookingState,
  accessToken,
  itineraryId,
  multipleDays,
  stripeToken,
  country,
  user
}) {
  return {
    types: [CREATE_BOOKING_AND_CHARGE, CREATE_BOOKING_AND_CHARGE_SUCCESS, CREATE_BOOKING_AND_CHARGE_FAIL],
    promise: async ({ client }) => {
      const response = await client.post('/booking/create', {
        data: {
          bookingState,
          multipleDays,
          accessToken,
          itineraryId,
          stripeToken,
          country,
          user,
        }
      });

      return response;
    },
  };
}

export function bookItineraryRequest({ bookingState, owt, itineraryId }, chargeId) {
  return {
    types: [BOOKING_REQUEST, BOOKING_SUCCESS, BOOKING_FAIL],
    promise: async ({ client }) => {
      const response = await client.post('/booking/request', {
        data: {
          bookingState,
          owt,
          id: itineraryId,
          chargeId,
        }
      });

      return response;
    },
  };
}

export function bookCustomizeRequest({ bookingState, owt }, chargeId) {
  return {
    types: [BOOKING_CUSTOMIZE_REQUEST, BOOKING_CUSTOMIZE_SUCCESS, BOOKING_CUSTOMIZE_FAIL],
    promise: async ({ client }) => {
      const response = await client.post('/booking/customize', {
        data: {
          bookingState,
          owt,
          chargeId,
        }
      });

      return response;
    }
  };
}

export function resetGuestCarLanguageTimeAndPlace() {
  return {
    type: RESET_GUEST_CAR_LANGUAGE_TIME_AND_PLACE,
  };
}

export function clearBookedResult() {
  return {
    type: CLEAR_BOOKED_RESULT,
  };
}

export function clearBookingError() {
  return {
    type: CLEAR_BOOKING_ERROR,
  };
}

export function restoreBookingObject(booking) {
  return {
    type: RESTORE_BOOKING_OBJECT,
    booking,
  };
}

export function setBookingRequestVia(via) {
  return {
    type: SET_BOOKING_REQUEST_VIA,
    via,
  };
}

export function clearLoginRequestVia() {
  return {
    type: CLEAR_LOGIN_REQUEST_VIA,
  };
}

export function setIsCustomizeTrip(isCustomizeTrip) {
  return {
    type: SET_IS_CUSTOMIZE_TRIP,
    isCustomizeTrip,
  };
}

export function setSkipLoginBooking(isSkipLogin) {
  return {
    type: SET_SKIP_LOGIN_BOOKING,
    isSkipLogin,
  };
}

export function clearSkipLoginRedirect() {
  return {
    type: CLEAR_SKIP_LOGIN_REDIRECT,
  };
}
