import isEmpty from 'lodash.isempty';
import moment from 'moment-timezone';
import idx from 'idx';

import {
  LOGIN_WITH_MAGIC_LINK_SUCCESS,
  LOAD_PRESERVED_STATE_SUCCESS
} from './auth';

import { getPlaceDisplayName } from '../../utils/placeHelper';

export const CHANGE_TRIP_NAME = 'planner/name/CHANGE_TRIP_NAME';
export const CHANGE_TRIP_DATE = 'planner/date/CHANGE_TRIP_DATE';
export const CHANGE_START_TIME_OF_DAY = 'planner/date/CHANGE_START_TIME_OF_DAY';

export const ADD_DAYS_TO_PLANNER = 'planner/date/ADD_DAYS_TO_PLANNER';
export const ADD_PICKUP_PLACE_TO_STATE = 'planner/place/ADD_PICKUP_PLACE_TO_STATE';
export const ADD_DESTINATION_TO_STATE = 'planner/place/ADD_DESTINATION_TO_STATE';
export const ADD_DROPOFF_TO_STATE = 'planner/place/ADD_DROPOFF_TO_STATE';
export const UPDATE_REDDOT_QUEUE = 'planner/place/UPDATE_REDDOT_QUEUE';
export const HIDE_CURRENT_STATE_OF_QUEUE = 'planner/place/HIDE_CURRENT_STATE_OF_QUEUE';
export const INSERT_DESTINATION_TO_STATE = 'planner/place/INSERT_DESTINATION_TO_STATE';
export const CHANGE_DESTINATION_DURATION = 'planner/place/CHANGE_DESTINATION_DURATION';

export const DELETE_PICKUP_PLACE = 'planner/place/DELETE_PICKUP_PLACE';
export const DELETE_DROPOFF_PLACE = 'planner/place/DELETE_DROPOFF_PLACE';
export const DELETE_DESTINATIONS_PLACE = 'planner/place/DELETE_DESTINATIONS_PLACE';
export const MOVE_PLACE_UP = 'planner/place/MOVE_PLACE_UP';
export const MOVE_PLACE_DOWN = 'planner/place/MOVE_PLACE_DOWN';

export const FIRE_ROUTE_ENQUIRY = 'planner/enquiry/FIRE_ROUTE_ENQUIRY';
export const FIRE_ROUTE_ENQUIRY_FAIL = 'planner/enquiry/FIRE_ROUTE_ENQUIRY_FAIL';
export const FIRE_ROUTE_ENQUIRY_SUCCESS = 'planner/enquiry/FIRE_ROUTE_ENQUIRY_SUCCESS';

export const SAVE_ENQUIRY = 'planner/enquiry/SAVE_ENQUIRY';
export const SAVE_ENQUIRY_SUCCESS = 'planner/enquiry/SAVE_ENQUIRY_SUCCESS';
export const SAVE_ENQUIRY_FAILED = 'planner/enquiry/SAVE_ENQUIRY_FAILED';

export const LOAD_SAVED_ENQUIRY = 'planner/enquiry/LOAD_SAVED_ENQUIRY';
export const LOAD_SAVED_ENQUIRY_SUCCESS = 'planner/enquiry/LOAD_SAVED_ENQUIRY_SUCCESS';
export const LOAD_SAVED_ENQUIRY_FAILED = 'planner/enquiry/LOAD_SAVED_ENQUIRY_FAILED';

export const FIRE_ROUTE_OPTIMIZE = 'planner/enquiry/FIRE_ROUTE_OPTIMIZE';
export const FIRE_ROUTE_OPTIMIZE_SUCCESS = 'planner/enquiry/FIRE_ROUTE_OPTIMIZE_SUCCESS';
export const FIRE_ROUTE_OPTIMIZE_FAIL = 'planner/enquiry/FIRE_ROUTE_OPTIMIZE_FAIL';
export const FIRE_RESET_ROUTE_OPTIMIZE = 'planner/enquiry/FIRE_RESET_ROUTE_OPTIMIZE';
export const RESET_ROUTE_OPTIMIZE_SNACKBAR = 'planner/enquiry/RESET_ROUTE_OPTIMIZE_SNACKBAR';

export const GET_ENQUIRY_PRICING = 'planner/enquiry/GET_ENQUIRY_PRICING';
export const GET_ENQUIRY_PRICING_SUCCESS = 'planner/enquiry/GET_ENQUIRY_PRICING_SUCCESS';
export const GET_ENQUIRY_PRICING_FAILED = 'planner/enquiry/GET_ENQUIRY_PRICING_FAILED';

const RESET_PLANNER_PLAN = 'planner/enquiry/RESET_PLANNER_PLAN';

export const initialDayPlan = () => ({
  start: '',
  pickup: null,
  destinations: [null],
  dropoff: null,
  startTime: '09:00',
});

export const initialState = () => ({
  startDate: new Date(),
  endDate: new Date(),
  numberOfDays: 1,
  plan: [initialDayPlan()],
  redDotsQueue: [],
  newlyAddedPlace: null,
  enquiry: [],
  reference: '',
  firingEnquiry: false,
  firedEnquiry: false,
  saved: false,
  saving: false,
  savedEnquiry: null,
  loadedSavedEnquiry: false,
  loadingSavedEnquiry: false,
  optimizing: false,
  optimized: false,
  name: '',
  history: {},
});

export const getPhotoReference = place => {
  const photo = idx(place, _ => _.photos[0]);
  const {
    photo_reference: photoReference = '',
    url
  } = photo || {};

  return url || photoReference;
};

export const getPlaceInRoute = ({ pickup, destinations = [], dropoff }) => {
  const pickupPlaceId = isEmpty(pickup) ? null : pickup.place_id;
  const droppffPlaceId = isEmpty(dropoff) ? null : dropoff.place_id;

  return [
    pickupPlaceId,
    ...destinations.map(v => isEmpty(v) ? null : v.place_id),
    droppffPlaceId,
  ].filter(v => v !== null);
};

export const getPlaceDuration = ({ pickup, destinations = [], dropoff }) => {
  const pickupPlaceId = isEmpty(pickup) ? null : pickup.place_id;
  const droppffPlaceId = isEmpty(dropoff) ? null : dropoff.place_id;

  return [
    pickupPlaceId ? 0 : null,
    ...destinations.map(v => isEmpty(v) ? null : (v.duration || 60)),
    droppffPlaceId ? 0 : null,
  ].filter(v => v !== null);
};

export const getNamesInRoute = ({ pickup, destinations = [], dropoff }) => ([
  getPlaceDisplayName(pickup),
  ...destinations.map(v => getPlaceDisplayName(v)),
  getPlaceDisplayName(dropoff),
]);

export const getPhotosInRoute = ({ pickup, destinations = [], dropoff }) => ([
  getPhotoReference(pickup),
  ...destinations.map(v => getPhotoReference(v)),
  getPhotoReference(dropoff),
]);

export const getGeometryInRoute = ({ pickup, destinations = [], dropoff }) => ([
  isEmpty(pickup) ? {} : pickup.geometry,
  ...destinations.map(v => isEmpty(v) ? {} : v.geometry),
  isEmpty(dropoff) ? {} : dropoff.geometry,
]);

export const getMultipleDays = ({ plan, numberOfDays, pickupTimeArr }) => plan.map((p, day) => {
  if (day >= numberOfDays) return null;

  const { pickup, destinations, dropoff } = p;

  return {
    pick_up_time: pickupTimeArr[day],
    places_in_route: getPlaceInRoute({ pickup, destinations, dropoff }),
    places_duration: getPlaceDuration({ pickup, destinations, dropoff }),
    names_in_route: getNamesInRoute({ pickup, destinations, dropoff }),
    photos_in_route: getPhotosInRoute({ pickup, destinations, dropoff }),
    geometry_in_route: getGeometryInRoute({ pickup, destinations, dropoff }),
  };
});

export const getPhotoArrayByUrl = url => {
  if (!url) return [];
  return /\b(http|https)/.test(url)
    ? [{ url }]
    : [{ photo_reference: url }];
};

export const getNewPlan = (day, route) => {
  /**
   * placeDuration can only have length greater or equal to 2, which can be as following
   *
   * 1. [ 0, 60, 0 ] : when pickup, dropoff, destinations all exist
   * 2. [ 60, 0 ] : when pickup doesn't exist
   * 3. [ 0, 60 ] : when dropoff doesn't exist
   * 4. [ 60, 60 ] : when pickup & dropoff both don't exist
   *
   */
  if (!route) {
    return {
      pickup: null,
      dropoff: null,
      destinations: [null],
      // eslint-disable-next-line camelcase
      startTime: day?.pick_up_time // Example: "2018-08-08 10:30 +08:00".slice(11, 16)
    };
  }

  const { places: currentDayRoute } = route;

  /* eslint-disable camelcase */
  const placesDuration = day?.places_duration || [];
  const placesInRoute = day?.places_in_route || [];
  const namesInRoute = day?.names_in_route || [];
  const photosInRoute = day?.photos_in_route || [];
  const geometryInRoute = day?.geometry_in_route || [];
  const startTime = day?.pick_up_time;
  /* eslint-enable camelcase */

  const firstIndex = 0;
  const lastIndex = namesInRoute.length - 1;

  /*
   * for popup use, to keep them immutable...
   * The ship is because places_duration & places_in_route will not contains the unexsited place like null pickup/dropoff
   *
   * Ex:
   *   {
   *     namesInRoute: ['', 'foo', 'bar' ],
   *     placesDuration: [45, 90],
   *   }
   *
   * Route array as well
   */
  const _placesDuration = [...placesDuration];
  const _placesInRoute = [...placesInRoute];
  const _route = [...currentDayRoute];
  const places = namesInRoute.map((name, index) => {
    const id = name ? _placesInRoute.shift() : '';
    const duration = name ? _placesDuration.shift() : '';
    const routeDuration = name ? _route.shift() : {};

    if (!id) return null;

    return {
      id,
      place_id: id,
      name: namesInRoute[index],
      photos: getPhotoArrayByUrl(photosInRoute[index]),
      geometry: geometryInRoute && geometryInRoute[index],
      duration,
      routeDuration,
    };
  });

  return {
    pickup: places[firstIndex] && places[firstIndex].name ? places[firstIndex] : null,
    dropoff: places[lastIndex] && places[lastIndex].name ? places[lastIndex] : null,
    destinations: places.slice(1, lastIndex),
    startTime: startTime ? startTime.slice(11, 16) : '09:00'// Example: "2018-08-08 10:30 +08:00".slice(11, 16)
  };
};

export default function reducer(state = initialState(), action = {}) {
  let newDay = {};
  let newPlan = [];
  let oldDestinationsArr = [];
  let newEnquiry = [];
  let originalDay = {};

  switch (action.type) {
    /**
     * Auth.js Actions
     */
    case LOAD_PRESERVED_STATE_SUCCESS:
    case LOGIN_WITH_MAGIC_LINK_SUCCESS:
      const { preservedState } = action.result;
      return preservedState && preservedState.planner ? { ...preservedState.planner } : { ...state };
      /* End of Auth.js Actions */

    case CHANGE_TRIP_NAME:
      return {
        ...state,
        name: action.name,
        saved: false,
      };
    case LOAD_SAVED_ENQUIRY:
      return {
        ...state,
        loadedSavedEnquiry: false,
        loadingSavedEnquiry: true,
      };
    case LOAD_SAVED_ENQUIRY_SUCCESS:
      const TAIWAN_TIME_ZONE = 'Asia/Taipei';
      /**
       * API return the plan in the following structure
       *
       * Where `places` contains of traffic/routing info
       * `enquiry` contains of multiple_days array for places info and some meta data of the itinearary
       *
       * {
       *   enquiry: {
       *     mutiple_days: Array,
       *     name: String,
       *     reference: String,
       *   },
       *   places: [{
       *     day: 1,
       *     places: {
       *       "ride": {
       *          "duration": 0,
       *          "distance": 0
       *        },
       *        "place": {
       *          "place_id": "ChIJi73bYWusQjQRgqQGXK260bw",
       *          "duration": 0,
       *          "arrival_time": "2018-11-05T23:00:00.000Z",
       *          "departure_time": "2018-11-05T23:00:00.000Z"
       *        }
       *     }
       *   }]
       * }
       */
      const enquiry = action?.result?.enquiry;
      const routeInPlan = action?.result?.places;
      const { name, multiple_days: multipleDays } = enquiry || {};
      const numberOfDays = multipleDays.length;
      const getRouteInPlan = dayIndex => routeInPlan.find(({ day: _day }) => _day === dayIndex + 1);

      if (!numberOfDays) {
        return { ...state };
      }

      newPlan = multipleDays.map((day, _index) => getNewPlan(day, getRouteInPlan(_index)));

      return {
        ...state,
        startDate:
          multipleDays[0].pick_up_time
            ? moment(multipleDays[0].pick_up_time)
              .tz(TAIWAN_TIME_ZONE)
              .format('YYYY-MM-DD')
            : state.startDate,
        endDate:
          multipleDays[multipleDays.length - 1].pick_up_time
            ? moment(multipleDays[multipleDays.length - 1].pick_up_time)
              .tz(TAIWAN_TIME_ZONE)
              .format('YYYY-MM-DD')
            : state.endDate,
        loadedSavedEnquiry: true,
        loadingSavedEnquiry: false,
        plan: newPlan,
        savedEnquiry: enquiry,
        enquiry: routeInPlan,
        name,
        numberOfDays,
      };
    case LOAD_SAVED_ENQUIRY_FAILED:
      return {
        ...state,
        loadedSavedEnquiry: false,
        loadingSavedEnquiry: false,
      };
    case SAVE_ENQUIRY:
      return {
        ...state,
        saved: false,
        saving: true,
      };
    case SAVE_ENQUIRY_SUCCESS:
      const { result: { enquiry: _enquiry } } = action;
      const { reference_id: reference } = _enquiry || {};
      return {
        ...state,
        saved: true,
        saving: false,
        savedEnquiry: _enquiry,
        reference,
      };
    case SAVE_ENQUIRY_FAILED:
      return {
        ...state,
        saved: false,
        saving: false,
      };
    case UPDATE_REDDOT_QUEUE:
      return {
        ...state,
        redDotsQueue: action.queue,
      };
    case CHANGE_TRIP_DATE:
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
        numberOfDays: action.numberOfDays || state.numberOfDays,
        saved: false,
      };
    case RESET_PLANNER_PLAN:
      return {
        ...state,
        plan: [initialDayPlan()],
      };
    case ADD_DAYS_TO_PLANNER:
      const newDayPlan = [...state.plan];
      [...Array(action.days).keys()].forEach(() => {
        newDayPlan.push(initialDayPlan());
      });

      return {
        ...state,
        plan: newDayPlan,
        saved: false,
      };
    case ADD_PICKUP_PLACE_TO_STATE:
      newDay = { ...state.plan[action.day], pickup: action.place };
      newPlan = Object.assign([...state.plan], { [action.day]: newDay });

      return {
        ...state,
        plan: newPlan,
        newlyAddedPlace: action.place,
        saved: false,
      };
    case ADD_DROPOFF_TO_STATE:
      newDay = { ...state.plan[action.day], dropoff: action.place };
      newPlan = Object.assign([...state.plan], { [action.day]: newDay });

      return {
        ...state,
        plan: newPlan,
        newlyAddedPlace: action.place,
        saved: false,
      };
    case ADD_DESTINATION_TO_STATE:
      oldDestinationsArr = [];

      [...state.plan[action.day].destinations].forEach(el => {
        if (!isEmpty(el)) {
          oldDestinationsArr.push(el);
        }
      });

      newDay = {
        ...state.plan[action.day],
        destinations: [
          ...oldDestinationsArr,
          action.place,
        ],
      };
      newPlan = Object.assign([...state.plan], { [action.day]: newDay });

      return {
        ...state,
        plan: newPlan,
        newlyAddedPlace: action.place,
        saved: false,
      };
    case INSERT_DESTINATION_TO_STATE:
      oldDestinationsArr = [];

      [...state.plan[action.day].destinations].forEach(el => {
        if (!isEmpty(el)) {
          oldDestinationsArr.push(el);
        }
      });

      newDay = {
        ...state.plan[action.day],
        destinations: [
          ...oldDestinationsArr.slice(0, action.index),
          action.place,
          ...oldDestinationsArr.slice(action.index)
        ],
      };

      newPlan = Object.assign([...state.plan], { [action.day]: newDay });

      return {
        ...state,
        plan: newPlan,
        newlyAddedPlace: action.place,
        saved: false,
      };
    case CHANGE_DESTINATION_DURATION:
      oldDestinationsArr = state.plan[action.day].destinations;

      newDay = {
        ...state.plan[action.day],
        destinations: [
          ...oldDestinationsArr.slice(0, action.index),
          {
            ...oldDestinationsArr[action.index],
            duration: action.duration,
          },
          ...oldDestinationsArr.slice(action.index + 1)
        ],
      };

      newPlan = Object.assign([...state.plan], { [action.day]: newDay });

      return {
        ...state,
        plan: newPlan,
        saved: false,
      };
    case CHANGE_START_TIME_OF_DAY:
      newDay = {
        ...state.plan[action.day],
        startTime: action.startTime,
      };

      newPlan = Object.assign([...state.plan], { [action.day]: newDay });
      return {
        ...state,
        plan: newPlan,
        saved: false,
      };
    case HIDE_CURRENT_STATE_OF_QUEUE:
      return {
        ...state,
        redDotsQueue: [
          {
            // Modified the first Element to be hidden
            ...state.redDotsQueue[0],
            hide: true,
          },
          // keep the rest elements state
          ...state.redDotsQueue.slice(1),
        ],
      };
    case FIRE_ROUTE_ENQUIRY:
      return {
        ...state,
        firedEnquiry: false,
        firingEnquiry: true,
      };
    case RESET_ROUTE_OPTIMIZE_SNACKBAR:
      return {
        ...state,
        optimized: false,
      };
    case FIRE_RESET_ROUTE_OPTIMIZE:
      return {
        ...state,
        plan: idx(state, _ => _.history.plan) || state.plan,
        enquiry: idx(state, _ => _.history.enquiry) || state.enquiry,
        history: {},
        optimized: false,
      };
    case FIRE_ROUTE_OPTIMIZE:
      return {
        ...state,
        optimizing: true,
        optimized: false,
        history: {
          plan: state.plan,
          enquiry: state.enquiry,
        },
      };
    case FIRE_ROUTE_OPTIMIZE_SUCCESS:
      const { places } = action.result;
      originalDay = state.plan[action.day];
      const { destinations: _destinations } = originalDay;
      const newDestinationsArr = places.slice(1, places.length - 1) // time first and last place, leave only destinations
        .map(p => {
          const { place: { place_id: placeId, duration } } = p;
          return {
            ..._destinations.find(d => d.place_id === placeId && d.duration === duration),
            routeDuration: p,
          };
        });

      newDay = {
        ...originalDay,
        dropoff: {
          ...originalDay.dropoff,
          routeDuration: places[places.length - 1], // Overwrite the route duration, containing new arrivalTime
        },
        destinations: newDestinationsArr,
      };

      newPlan = Object.assign([...state.plan], { [action.day]: newDay });
      newEnquiry = Object.assign([...state.enquiry], { [action.day]: action.result });

      return {
        ...state,
        plan: newPlan,
        enquiry: newEnquiry,
        optimizing: false,
        optimized: true,
      };
    case FIRE_ROUTE_ENQUIRY_SUCCESS:
      originalDay = state.plan[action.day];
      /**
       * placeDuration can only have length greater or equal to 2, which can be as following
       *
       * 1. [ 0, 60, 0 ] : when pickup, dropoff, destinations all exist
       * 2. [ 60, 0 ] : when pickup doesn't exist
       * 3. [ 0, 60 ] : when dropoff doesn't exist
       * 4. [ 60, 60 ] : when pickup & dropoff both don't exist
       *
       */
      const { places_duration: durations } = action.query;
      const { places: route } = action.result;
      const firstIndex = 0;
      const lastIndex = durations.length - 1;

      const newPickupObj = durations[firstIndex] === 0
        // Meaning, pickup exist, since pickup will onlly have duration > 0
        ? {
          ...originalDay.pickup,
          routeDuration: route[firstIndex]
        }
        : { ...originalDay.pickup };

      const newDropoffObj = durations[lastIndex] === 0
        // Meaning, dropoff exist, since pickup will onlly have duration > 0
        ? {
          ...originalDay.dropoff,
          routeDuration: route[lastIndex]
        }
        : { ...originalDay.dropoff };

      let routeWithoutPickupDropoff = route;
      if (!durations[firstIndex] && !durations[lastIndex]) {
        // case: [0, x, x, 0]
        // trim first and last
        routeWithoutPickupDropoff = route.slice(1, lastIndex);
      } else if (!durations[firstIndex] && durations[lastIndex]) {
        // case: [0, x, x]
        // trim first
        routeWithoutPickupDropoff = route.slice(1);
      } else if (durations[firstIndex] && !durations[lastIndex]) {
        // case: [x, x, 0]
        // trim last
        routeWithoutPickupDropoff = route.slice(0, lastIndex);
      }

      const newDestinations = routeWithoutPickupDropoff.map((_route, index) => ({
        ...originalDay.destinations[index],
        routeDuration: _route,
      }));

      newDay = {
        ...originalDay,
        pickup: newPickupObj,
        dropoff: newDropoffObj,
        destinations: newDestinations,
      };

      newPlan = Object.assign([...state.plan], { [action.day]: newDay });
      newEnquiry = Object.assign([...state.enquiry], { [action.day]: action.result });

      return {
        ...state,
        enquiry: newEnquiry,
        plan: newPlan,
        newlyAddedPlace: null,
        firingEnquiry: false,
        firedEnquiry: true,
      };
    case FIRE_ROUTE_ENQUIRY_FAIL:
      return {
        ...state,
        firedEnquiry: false,
        firingEnquiry: false,
      };
    case DELETE_PICKUP_PLACE:
      originalDay = state.plan[action.day];
      newDay = {
        ...originalDay,
        pickup: null,
      };
      newPlan = Object.assign([...state.plan], { [action.day]: newDay });
      return {
        ...state,
        plan: newPlan,
        newlyAddedPlace: null,
      };
    case DELETE_DROPOFF_PLACE:
      originalDay = state.plan[action.day];
      newDay = {
        ...originalDay,
        dropoff: null,
      };
      newPlan = Object.assign([...state.plan], { [action.day]: newDay });
      return {
        ...state,
        plan: newPlan,
        newlyAddedPlace: null,
        saved: false,
      };
    case DELETE_DESTINATIONS_PLACE:
      originalDay = state.plan[action.day];
      newDay = {
        ...originalDay,
        destinations: [
          ...originalDay.destinations.slice(0, action.index),
          ...originalDay.destinations.slice(action.index + 1)],
      };
      newPlan = Object.assign([...state.plan], { [action.day]: newDay });
      return {
        ...state,
        plan: newPlan,
        newlyAddedPlace: null,
        saved: false,
      };
    case MOVE_PLACE_UP:
      originalDay = state.plan[action.day];

      // SWAP UP
      newDay = {
        ...originalDay,
        destinations: [
          ...originalDay.destinations.slice(0, action.index - 1),
          originalDay.destinations[action.index],
          originalDay.destinations[action.index - 1],
          ...originalDay.destinations.slice(action.index + 1)
        ]
      };
      newPlan = Object.assign([...state.plan], { [action.day]: newDay });

      return {
        ...state,
        plan: newPlan,
        saved: false,
      };
    case MOVE_PLACE_DOWN:
      originalDay = state.plan[action.day];

      // SWAP UP
      newDay = {
        ...originalDay,
        destinations: [
          ...originalDay.destinations.slice(0, action.index),
          originalDay.destinations[action.index + 1],
          originalDay.destinations[action.index],
          ...originalDay.destinations.slice(action.index + 2)
        ]
      };
      newPlan = Object.assign([...state.plan], { [action.day]: newDay });

      return {
        ...state,
        plan: newPlan,
        saved: false,
      };
    default:
      return state;
  }
}

/**
 * Sync Actions
 */
export function changeTripDate({ startDate, endDate, numberOfDays }) {
  return {
    type: CHANGE_TRIP_DATE,
    startDate,
    endDate,
    numberOfDays
  };
}

export function addDaysToPlannerState(days) {
  return {
    type: ADD_DAYS_TO_PLANNER,
    days,
  };
}

export function addPickupPlaceToState({ day, place }) {
  return {
    type: ADD_PICKUP_PLACE_TO_STATE,
    day,
    place,
  };
}

export function addDestinationToState({ day, place }) {
  return {
    type: ADD_DESTINATION_TO_STATE,
    day,
    place,
  };
}

export function addDropoffPlaceToState({ day, place }) {
  return {
    type: ADD_DROPOFF_TO_STATE,
    day,
    place,
  };
}

export function insertDestinationToState({ day, place, index }) {
  return {
    type: INSERT_DESTINATION_TO_STATE,
    day,
    place,
    index,
  };
}

export function changeDestinationDuration({ day, index, duration }) {
  return {
    type: CHANGE_DESTINATION_DURATION,
    day,
    duration,
    index,
  };
}

export function changeStartTimeOfDay({ day, startTime }) {
  return {
    type: CHANGE_START_TIME_OF_DAY,
    day,
    startTime,
  };
}

export function updateRedDotQueue(queue) {
  return {
    type: UPDATE_REDDOT_QUEUE,
    queue,
  };
}

export function isSavedEnquiryLoaded(globalState) {
  return globalState.planner.loadedSavedEnquiry && globalState.planner.loadedSavedEnquiry;
}

export function deletePickupPlace({ day }) {
  return {
    type: DELETE_PICKUP_PLACE,
    day,
  };
}

export function deleteDropoffPlace({ day }) {
  return {
    type: DELETE_DROPOFF_PLACE,
    day,
  };
}

export function deleteDestinationsPlace({ day, index }) {
  return {
    type: DELETE_DESTINATIONS_PLACE,
    day,
    index
  };
}

export function hideCurrentStepOfQueue() {
  return {
    type: HIDE_CURRENT_STATE_OF_QUEUE,
  };
}

export function movePlaceUp({ day, index }) {
  return {
    type: MOVE_PLACE_UP,
    day,
    index
  };
}

export function movePlaceDown({ day, index }) {
  return {
    type: MOVE_PLACE_DOWN,
    day,
    index
  };
}

export function changeTripName({ name }) {
  return {
    type: CHANGE_TRIP_NAME,
    name,
  };
}

export function resetOptimizedSnackbar() {
  return {
    type: RESET_ROUTE_OPTIMIZE_SNACKBAR,
  };
}

export function undoOptimizing() {
  return {
    type: FIRE_RESET_ROUTE_OPTIMIZE,
  };
}

/**
 * Async Actions
 */
export function loadSavedEnquiry({ reference }) {
  return {
    types: [
      LOAD_SAVED_ENQUIRY,
      LOAD_SAVED_ENQUIRY_SUCCESS,
      LOAD_SAVED_ENQUIRY_FAILED
    ],
    promise: ({ client }) => client.post('/enquiry/loadSavedEnquiry', {
      data: {
        reference,
      }
    }),
  };
}

export function fireRouteEnquiry({
  day,
  plan,
  placeToAdd,
  pickUpTime
}) {
  if (isEmpty(plan[day])) return;

  const { pickup, destinations, dropoff } = plan[day];
  const pickupPlaceId = isEmpty(pickup) ? null : pickup.place_id;
  const droppffPlaceId = isEmpty(dropoff) ? null : dropoff.place_id;

  const placeInRoute = [
    pickupPlaceId,
    ...destinations.map(v => isEmpty(v) ? null : v.place_id),
    droppffPlaceId,
  ].filter(v => v !== null);

  const placeDuration = [
    pickupPlaceId ? 0 : null,
    ...destinations.map(v => v !== null ? v.duration : null),
    droppffPlaceId ? 0 : null,
  ].filter(v => v !== null);

  const enquiry = {
    places_in_route: placeInRoute,
    places_duration: placeDuration,
    place_to_add: placeToAdd || null,
    pick_up_time: pickUpTime,
  };

  return {
    types: [
      FIRE_ROUTE_ENQUIRY,
      FIRE_ROUTE_ENQUIRY_SUCCESS,
      FIRE_ROUTE_ENQUIRY_FAIL
    ],
    promise: ({ client }) => client.post('/enquiry/build', {
      data: {
        enquiry,
      }
    }),
    day,
    query: enquiry,
  };
}

export function optimizeRoute({
  day,
  plan
}) {
  if (isEmpty(plan)) return;

  const { pickup, destinations, dropoff } = plan;
  const enquiry = {
    places_in_route: getPlaceInRoute({ pickup, destinations, dropoff }),
    places_duration: getPlaceDuration({ pickup, destinations, dropoff }),
  };

  return {
    types: [
      FIRE_ROUTE_OPTIMIZE,
      FIRE_ROUTE_OPTIMIZE_SUCCESS,
      FIRE_ROUTE_OPTIMIZE_FAIL
    ],
    promise: ({ client }) => client.post('/enquiry/optimize', {
      data: {
        enquiry,
      }
    }),
    day,
    query: enquiry,
  };
}

export function getEnquiryPricing({
  plan, pickupTimeArr = [], numberOfDays = '1', country = 'tw'
}) {
  const multipleDays = getMultipleDays({ plan, numberOfDays, pickupTimeArr });

  return {
    types: [GET_ENQUIRY_PRICING, GET_ENQUIRY_PRICING_SUCCESS, GET_ENQUIRY_PRICING_FAILED],
    promise: ({ client }) => client.post('/enquiry/load', {
      data: {
        enquiry: {
          multiple_days: multipleDays.filter(v => v !== null),
          country,
        }
      }
    })
  };
}

export function savePlan({
  reference, plan, pickupTimeArr, numberOfDays, name = ''
}) {
  const multipleDays = getMultipleDays({ plan, numberOfDays, pickupTimeArr });

  return {
    types: [SAVE_ENQUIRY, SAVE_ENQUIRY_SUCCESS, SAVE_ENQUIRY_FAILED],
    promise: ({ client }) => client.post('/enquiry/saveEnquiry', {
      data: {
        name,
        multipleDays: multipleDays.filter(v => v !== null),
        reference,
      }
    })
  };
}

export function resetPlannerPlan() {
  return {
    type: RESET_PLANNER_PLAN,
  };
}
