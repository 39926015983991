/* eslint-disable max-len */
const environment = {
  development: {
    isProduction: false,
    assetsPath: /^https?/.test(process.env.HOST)
      ? `${process.env.HOST || 'localhost'}:${+process.env.PORT + 1 || 3001}/dist/`
      : `http://${process.env.HOST || 'localhost'}:${+process.env.PORT + 1 || 3001}/dist/`
  },
  production: {
    isProduction: true,
    assetsPath: process.env.OWNRIDES_CDN ? `${process.env.OWNRIDES_CDN}/dist/` : '/dist/',
  }
}[process.env.NODE_ENV || 'development'];

const config = {
  host: process.env.HOST || 'localhost',
  port: process.env.PORT || 3000,
  apiHost: process.env.APIHOST || 'localhost',
  apiPort: process.env.APIPORT,
  ga: process.env.GA_KEY || '',
  googleOptimizeId: process.env.GA_OPTIMIZE_ID || '',
  stripe_p: process.env.STRIPE_PUBLIC_KEY || '',
  stripe_s: process.env.STRIPE_SECRET_KEY || '',
  herokuApiUrl: process.env.HEROKU_API_URL || 'http://bytheway-rails.herokuapp.com',
  cdnUrl: process.env.CDN_URL || 'https://assets.ownrides.com',
  rollbar: {
    accessTokenFnd: process.env.ROLLBAR_ACCESS_TOKEN_FND,
    env: process.env.ROLLBAR_ENV
  },
  gmap: {
    googlePlacePhotoApiKey: process.env.GOOGLE_PLACE_PHOTO_API_KEY,
  },
  destinationType: {
    ACCOMMODATION: 'accommodation',
    ATTRACTION: 'attraction'
  },
  app: {
    title: 'OWNRIDES',
    description: 'Book private tours with driver guides in Taiwan and Thailand.',
    headline: 'OWN YOUR TOUR',
    url: 'https://www.ownrides.com',
    preservedSession: 'preserved-session-state',
    loginSession: 'login-session',
    supportMessagingApp: ['WhatsApp', 'WeChat', 'Line', 'Viber', 'KakaoTalk'],
    head: {
      meta: [
        {
          name: 'description',
          content:
            'Book private tours with driver guides in Taiwan and Thailand.'
        },
        { charset: 'utf-8' },
        { property: 'fb:app_id', content: '1832315833691859' },
        { property: 'og:site_name', content: 'OWNRIDES' },
        { property: 'og:title', content: 'OWNRIDES - Book Private Tours with Private Drivers in Taiwan and Thailand' },
        {
          property: 'og:description',
          content:
            'Book private tours with driver guides in Taiwan and Thailand.'
        },
        {
          property: 'og:image',
          content: 'https://assets.ownrides.com/misc/banners/ownrides-site-og-1.jpg'
        },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: 'https://www.ownrides.com/' },
        { property: 'og:image:width', content: '1280' },
        { property: 'og:image:height', content: '360' }
      ]
    }
  },
  policies: [
    {
      name: 'Payment',
      rules: [
        'A small deposit is required when you make a booking.',
        'The remaining balance is payable in cash (Local Currency) to the driver at the end of your trip.',
        'If there is any dispute on payment that cannot be resolved directly with the driver, please contact Customer Support.'
      ]
    },
    {
      name: 'Cancellation Policy',
      rules: [
        'Your deposit will be fully refunded if you cancel your confirmed trip 7 days or more before the trip departure date.',
        'If you cancel your confirmed trip between 4 and 6 days before the trip departure date, half of your deposit will be refunded.',
        'If you cancel your confirmed trip 3 days or less before the trip departure date, none of your deposit will be refunded.',
        'In the event of any natural disaster such as typhoon, earthquake, flood, landslide that affect your confirmed trip and your safety, we might cancel your trip and refund your deposit.'
      ]
    }
  ],
  serviceScope: [
    {
      name: 'Inclusions',
      type: 'inclusion',
      rules: [
        'Private driver’s service fee',
        'Car’s fuel cost, highway toll, parking fee',
        'Transportation for entire tour',
        'Pick up and drop off in city'
      ]
    },
    {
      name: 'Exclusions',
      type: 'exclusion',
      rules: ['Meal', 'Admission fee', 'Accommodation', 'Gratuity / tip (optional)']
    }
  ],
  additionalInfo: [
    {
      em: 'Start and end time of trip',
      content: ' can be adjusted according to your preference and duration set for this tour'
    },
    {
      em: 'Flexibility of trip',
      content:
        ' is required as weather, traffic condition and other unexpected situations might affect the tour, and coverage of all locations in this tour cannot be guaranteed'
    },
    {
      em: 'Overtime fee',
      content: ' of 250 TWD per half hour (30 minutes) is required if trip exceeds duration set for this tour',
      th: ' of 150 THB per half hour (30 minutes) is required if trip exceeds duration set for this tour',
      tw: ' of 250 TWD per half hour (30 minutes) is required if trip exceeds duration set for this tour'
    },
    {
      em: 'Gratuity / tip',
      content: 'is optional but we recommend 10% if the driver’s service is good'
    },
    {
      em: 'Modification to tour',
      content: ' should be discussed with the driver directly'
    },
    {
      em: 'Overloading',
      content: 'is strictly prohibited by law'
    },
    {
      em: '',
      content: 'Exceeding the rated number of passengers specified for the respective vehicles(overloading), driver has the right to no providing the service.'
    }
  ],
  faq: {
    bookingAndPayment: [
      {
        question: 'How do I make a booking?',
        answers: [
          'To make a booking, select a tour (or customize one), date, car type (number of passengers), other preferences, and provide us your contact information to complete the booking.'
        ]
      },
      {
        question: 'I made a booking, what happen next?',
        answers: [
          "After you made a booking, we will find a suitable driver for you within 24 hours and send you the driver's details and contact information. If needed, you can contact the driver and discuss your trip with the driver. On the requested date, the driver will pick you up at the specified time and location."
        ]
      },
      {
        question: 'How can I cancel a booking?',
        answers: [
          'You can cancel a booking by informing our Customer Support. Cancellation is free within the first 24 hours of making a booking. For more information on our cancellation policy, please see our policy page.'
        ]
      },
      {
        question: 'If the driver does not turn up, what should I do?',
        answers: [
          'You should try to contact the driver directly first. If the driver is unreachable, please inform our Customer Support immediately and we will find another driver for you at no additional cost.'
        ]
      },
      {
        question: 'What if the driver and/or vehicle is different from the one we arranged for you?',
        answers: ['Do not board the vehicle. Instead, report this issue to our Customer Support as soon as possible.']
      },
      {
        question: 'When do I make payment?',
        answers: [
          'A small deposit is required when you make a booking. The remaining balance is payable in cash (Local Currency) to the driver at the end of your trip.'
        ]
      },
      {
        question: 'What should I do if there is any dispute on payment with the driver?',
        answers: [
          'If there is any dispute on payment that cannot be resolved directly with the driver, please contact our Customer Support immediately.'
        ]
      },
      {
        question: 'How much gratuity / tip should I give the driver?',
        answers: ['Gratuity / tip is optional but we recommend 10% if the driver’s service is good.']
      }
    ],
    customize: [
      {
        question: "Why can't I find the place I want to go?",
        answers: [
          "Our customize tour feature uses Google Maps to find places. If you can't find a place, it is probably because the place is not recognized by Google Maps or its name is different. Try to find the place with its exact address."
        ]
      },
      {
        question: 'How does the customize tour feature calculate the price?',
        answers: [
          "Our customize tour feature uses advanced technology to find the most optimal route with the shortest distance and lowest price for your tour. The price includes the driver's service fee, the car's fuel cost, highway toll and parking fee, specific to your tour only."
        ]
      }
    ]
  }
};
/* eslint-enable */

Object.assign(config, environment);

export default config;
