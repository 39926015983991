import { css } from 'styled-components';

export const mediaMUI = {
  forPhoneOnly: '(max-width: 599px)',
  forTabletPortraitUp: '(min-width: 600px)',
  forTabletPortraitOnly: '(min-width: 600px) and (max-width: 899px)',
  forTabletLandscapeUp: '(min-width: 900px)',
  forTabletLandscapeOnly: '(min-width: 900px) and (max-width: 1199px)',
  forDesktopUp: '(min-width: 1200px)',
  forDesktopOnly: '(min-width: 1200px) and (max-width: 1799px)',
  forBigDesktopUp: '(min-width: 1800px)'
};

export const media = {
  forPhoneOnly: (...args) => css`
    @media (max-width: 599px) {
      ${css(...args)}
    }
  `,

  forTabletPortraitUp: (...args) => css`
    @media (min-width: 600px) {
      ${css(...args)}
    }
  `,

  forTabletPortraitOnly: (...args) => css`
    @media (min-width: 600px) and (max-width: 899px) {
      ${css(...args)}
    }
  `,

  forTabletLandscapeUp: (...args) => css`
    @media (min-width: 900px) {
      ${css(...args)}
    }
  `,

  forTabletLandscapeOnly: (...args) => css`
    @media (min-width: 900px) and (max-width: 1199px) {
      ${css(...args)}
    }
  `,

  forDesktopUp: (...args) => css`
    @media (min-width: 1200px) {
      ${css(...args)}
    }
  `,

  forDesktopOnly: (...args) => css`
    @media (min-width: 1200px) and (max-width: 1799px) {
      ${css(...args)}
    }
  `,

  forBigDesktopUp: (...args) => css`
    @media (min-width: 1800px) {
      ${css(...args)}
    }
  `,

  forRetinaDisplay: (...args) => css`
    @media
      only screen and (-webkit-min-device-pixel-ratio: 2),
      only screen and (   min--moz-device-pixel-ratio: 2),
      only screen and (     -o-min-device-pixel-ratio: 2/1),
      only screen and (        min-device-pixel-ratio: 2),
      only screen and (                min-resolution: 192dpi),
      only screen and (                min-resolution: 2dppx) {
      ${css(...args)}
    }
  `
};

const theme = {
  greyLight300: '#061C3F',
  greyLight200: '#595959',
  greyLight150: '#828183',
  greyLight75: '#8C9BAA',
  greyLight60: '#F4F6F9',
  greyLight50: '#fafafa',

  primaryGreen: '#00B33B',
  secondaryGreen: '#04844B',
  primaryYellow: '#fcde79',
  secondaryYellow: '#F0B021',

  vibrantRed: '#DC354D',
  logoOrange: '#F0B021',
  logoWhite: '#EFF0F1',

  fontFamilyLato:
    '"Lato", -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif',
  fontFamilyRaleway:
    '"Raleway", -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif'
};

export const muiTheme = {
  palette: {
    primary: {
      light: '#76eaa0',
      main: theme.primaryGreen,
      dark: '#008645',
      contrastText: '#fff'
    },
    secondary: {
      light: '#ffffaa',
      main: theme.primaryYellow,
      dark: '#c7ac4a',
      contrastText: theme.greyLight300
    },
    default: {
      main: '#ffffff',
      contrastText: theme.greyLight300
    }
  },
  typography: {
    h1: {
      fontSize: '3rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: 700,
    },
  }
};

export default theme;
