import isEmpty from 'lodash.isempty';

const LOAD = 'destinations/LOAD';
const LOAD_SUCCESS = 'destinations/LOAD_SUCCESS';
const LOAD_FAIL = 'destinations/LOAD_FAIL';

const initialState = () => ({
  loaded: false,
  destinations: [],
});

export default function reducer(state = initialState(), action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        destinations: {
          ...state.destinations,
          [action.country]: action.result,
        },
        error: null
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        destinations: null,
        error: typeof action.error === 'string' ? action.error : 'Error'
      };
    default:
      return state;
  }
}

export function isLoaded(globalState, country = '') {
  if (!globalState.destinations || !globalState.destinations.loaded) {
    return false;
  }

  if (country === '') {
    if (isEmpty(globalState.destinations)) {
      return false;
    }
  }

  if (country && isEmpty(globalState.destinations[country])) {
    return false;
  }

  return true;
}

export function load({ country = 'tw', type = '' }) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    country,
    promise: ({ client }) => client.get('/destinations/load', {
      params: {
        country,
        type
      }
    })
  };
}
