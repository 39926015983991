import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/Loading';

const EmailLoginRedirectLoadable = Loadable({
  loader: () => import('./EmailLoginRedirect')
    .then(module => module.default)
    .catch(() => {
      if (typeof window !== 'undefined') window.location.reload();
    }),
  loading: () => <div key="EmailLoginRedirectLoadable"><Loading /></div>
});

export default EmailLoginRedirectLoadable;
