import isEmpty from 'lodash.isempty';

import { sendGtagEvent } from '../../utils/gtag';

const LOAD_AUTOCOMPLETE = 'search/places/LOAD_AUTOCOMPLETE';
const LOAD_AUTOCOMPLETE_SUCCESS = 'search/places/LOAD_AUTOCOMPLETE_SUCCESS';
const LOAD_AUTOCOMPLETE_FAIL = 'search/places/LOAD_AUTOCOMPLETE_FAIL';
const LOAD_DETAILS = 'search/places/LOAD_DETAILS';
const LOAD_DETAILS_SUCCESS = 'search/places/LOAD_DETAILS_SUCCESS';
const LOAD_DETAILS_FAIL = 'search/places/LOAD_DETAILS_FAIL';
const LOAD_SEARCH_PLACE = 'search/places/LOAD_SEARCH_PLACE';
const LOAD_SEARCH_PLACE_SUCCESS = 'search/places/LOAD_SEARCH_PLACE_SUCCESS';
const LOAD_SEARCH_PLACE_FAIL = 'search/places/LOAD_SEARCH_PLACE_FAIL';
const CHANGE_SEARCH_WORD = 'search/places/CHANGE_SEARCH_WORD';
const CLEAR_SEARCH_RESULT = 'search/places/CLEAR';
const SET_SELECTED_PLACE = 'search/places/SET_SELECTED_PLCAE';
const SET_SELECTED_PLACE_ID = 'search/places/SET_SELECTED_PLACE_ID';
const SET_PRESET_DURATION = 'search/places/SET_PRESET_DURATION';
const SET_PRESET_DISPLAY_NAME = 'search/places/SET_PRESET_DISPLAY_NAME';
const SET_PRESET_PHOTOS = 'search/places/SET_PRESET_PHOTOS';
const TOGGLE_ADD_TO_ROUTE = 'search/places/TOGGLE_ADD_TO_ROUTE';

const initialState = () => ({
  loading: false,
  loaded: false,
  searching: false,
  searched: false,
  suggestion: [],
  searchKey: '',
  selected: {},
  error: null,
  detailsLoading: false,
  detailsLoaded: false,
  details: [],
  cacheDetailList: [],
  detailsError: null,
  selectedPlaceId: '',
  presetDuration: 60,
  presetDisplayName: '',
  presetPhotos: [],
  presetAllowingAdd: true,
});

export default function reducer(state = initialState(), action = {}) {
  switch (action.type) {
    case LOAD_AUTOCOMPLETE:
      return {
        ...state,
        loading: true,
      };
    case LOAD_AUTOCOMPLETE_SUCCESS:
      if (action.result && action.result.length === 0) {
        sendGtagEvent('Not Foound', 'Place Autocomplete', action.searchKey);
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        suggestion: action.result,
        error: null
      };
    case LOAD_SEARCH_PLACE:
      return {
        ...state,
        searching: true,
        searched: false,
        selected: null,
        details: null,
      };
    case LOAD_SEARCH_PLACE_FAIL:
      return {
        ...state,
        searching: false,
        searched: false,
      };
    case LOAD_SEARCH_PLACE_SUCCESS:
      let guessResult = [];

      if (action.result && action.result.length) {
        // guessResult = action.result.filter(place =>
        //   (place && place.formatted_address.indexOf('Taiwan') >= 0) ||
        //   (place && place.formatted_address.indexOf('Thailand') >= 0));

        guessResult = action.result;
      }

      return {
        ...state,
        searching: false,
        searched: true,
        loading: false,
        loaded: true,
        detailsLoading: false,
        detailsLoaded: true,
        details: guessResult,
        selected: guessResult,
        detailsError: null,
        error: null
      };
    case LOAD_AUTOCOMPLETE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        suggestion: null,
        error: typeof action.error === 'string' ? action.error : 'Error'
      };
    case LOAD_DETAILS:
      return {
        ...state,
        detailsLoading: true,
      };
    case LOAD_DETAILS_SUCCESS:
      let list = state.cacheDetailList;
      const toPush = action.result;
      if (isEmpty(list)) {
        list.push(toPush);
      } else {
        let found = false;

        list.forEach(place => {
          if (place.place_id === action.result.place_id) {
            found = true;
          }
        });

        list = found ? list : list.concat(toPush);
      }

      return {
        ...state,
        detailsLoading: false,
        detailsLoaded: true,
        details: [action.result],
        cacheDetailList: list,
        detailsError: null
      };
    case LOAD_DETAILS_FAIL:
      return {
        ...state,
        detailsLoading: false,
        detailsLoaded: false,
        details: null,
        detailsError: typeof action.error === 'string' ? action.error : 'Error'
      };
    case CHANGE_SEARCH_WORD:
      if (state.searchKey === action.searchKey) return state;

      return {
        ...state,
        searchKey: action.searchKey,
      };
    case SET_SELECTED_PLACE:
      if (state.selected === action.selected) return state;

      return {
        ...state,
        selected: action.selected,
      };
    case SET_SELECTED_PLACE_ID:
      if (state.selectedPlaceId === action.id) return state;

      return {
        ...state,
        selectedPlaceId: action.id,
      };
    case SET_PRESET_DURATION:
      if (state.presetDuration === action.duration) return state;

      return {
        ...state,
        presetDuration: action.duration,
      };
    case SET_PRESET_PHOTOS:
      if (state.presetPhotos === action.presetPhotos) return state;

      return {
        ...state,
        presetPhotos: action.presetPhotos,
      };
    case SET_PRESET_DISPLAY_NAME:
      if (state.presetDisplayName === action.presetDisplayName) return state;

      return {
        ...state,
        presetDisplayName: action.presetDisplayName,
      };
    case TOGGLE_ADD_TO_ROUTE:
      if (state.presetAllowingAdd === action.presetAllowingAdd) return state;

      return {
        ...state,
        presetAllowingAdd: action.presetAllowingAdd,
      };
    case CLEAR_SEARCH_RESULT:
      return {
        ...state,
        loading: false,
        loaded: false,
        suggestion: [],
        searchKey: '',
        error: null,
        selected: null,
        details: null,
        presetDuration: 60,
        presetDisplayName: '',
      };
    default:
      return state;
  }
}

export function changeSearchWord(searchKey) {
  return {
    type: CHANGE_SEARCH_WORD,
    searchKey,
  };
}

export function searchAutocompletePlace(searchKey, country) {
  return {
    types: [LOAD_AUTOCOMPLETE, LOAD_AUTOCOMPLETE_SUCCESS, LOAD_AUTOCOMPLETE_FAIL],
    promise: ({ client }) => client.get('/places/autocomplete', {
      params: {
        searchKey,
        country,
      }
    }),
    searchKey,
  };
}

export function searchPlaceByKeyword(searchKey, country) {
  return {
    types: [LOAD_SEARCH_PLACE, LOAD_SEARCH_PLACE_SUCCESS, LOAD_SEARCH_PLACE_FAIL],
    promise: ({ client }) => client.get('/places/search', {
      params: {
        searchKey,
        country,
      }
    }),
  };
}

export function getPlaceDetails(id) {
  return {
    types: [LOAD_DETAILS, LOAD_DETAILS_SUCCESS, LOAD_DETAILS_FAIL],
    promise: ({ client }) => client.get('/places/details', {
      params: {
        id,
      }
    }),
    id,
  };
}

export function clearSearchResult() {
  return {
    type: CLEAR_SEARCH_RESULT,
  };
}

export function selectPlaceId(id) {
  return {
    type: SET_SELECTED_PLACE_ID,
    id,
  };
}

export function selectPlace(selected) {
  return {
    type: SET_SELECTED_PLACE,
    selected,
  };
}

/**
 * User has 2 ways of adding attractions
 *
 * 1. search in the dialog (default is 60 mins)
 * 2. Click on the recommended destination list (has our recommende duration)
 *
 * Case 2 is where we want to use our own preset duratoin
 *
 * @param {number} duration
 */
export function setPresetDuration(duration) {
  return {
    type: SET_PRESET_DURATION,
    duration,
  };
}

/**
 * User has 2 ways of adding attractions
 *
 * 1. search in the dialog
 * 2. Click on the recommended destination list
 *
 * Case 2 is where we want to use our own preset display name
 *
 * @param {string} name
 */
export function setPresetDiaplayName(name) {
  return {
    type: SET_PRESET_DISPLAY_NAME,
    presetDisplayName: name,
  };
}

export function setPresetPhotos(photos) {
  return {
    type: SET_PRESET_PHOTOS,
    presetPhotos: photos,
  };
}

/**
 * When user click the attraction name, preset the allowing adding to false.
 * Only allowing when
 *
 * 1. user click 'Add place' button
 * 2. user click on Gmap
 *
 * @param {bool} presetAllowingAdd
 */
export function toggleAddToRoute(presetAllowingAdd = true) {
  return {
    type: TOGGLE_ADD_TO_ROUTE,
    presetAllowingAdd,
  };
}
