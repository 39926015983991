import { useEffect, useState } from 'react';
import { getPriceQuote } from 'components/PriceNumber/PriceNumber';

const useBookingPrice = ({
  carTypes,
  languages,
  bookingLanguage,
  bookingCarType,
  profitMargin = 0.15,
  surchargeAmount = 0,
  discountAmount = 0,
}) => {
  const [currentSelectedCarType, setCurrentSelectedCarType] = useState({});
  const [currentSelectedLanguage, setCurrentSelectedLanguage] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);
  const [payableNowPrice, setPayableNowPrice] = useState(0);
  const [carPriceFrom, setCarPriceFrom] = useState(0);
  const [languagePriceFrom, setLanguagePriceFrom] = useState(0);

  useEffect(() => {
    const _car = carTypes && carTypes.find(carType => carType.id === bookingCarType);
    const _language = languages && languages.find(language => Object.prototype.hasOwnProperty.call(language, bookingLanguage));

    setCurrentSelectedCarType(_car);
    setCurrentSelectedLanguage(_language);
  }, [
    carTypes,
    languages,
    bookingLanguage,
    bookingCarType,
  ]);

  useEffect(() => {
    const { price: carPrice = 0 } = currentSelectedCarType || {};

    const _carPriceFrom = getPriceQuote({ price: carPrice, multiplier: (1 + parseFloat(profitMargin)) });
    const currentLanguagePrice = currentSelectedLanguage && currentSelectedLanguage[bookingLanguage];
    const _languagePriceFrom = getPriceQuote({ price: currentLanguagePrice, multiplier: 1 });

    setLanguagePriceFrom(_languagePriceFrom);
    setCarPriceFrom(_carPriceFrom);

    setTotalPrice(
      parseInt(_carPriceFrom, 10)
      + parseInt(_languagePriceFrom, 10)
      + parseInt(surchargeAmount, 10)
      + parseInt(discountAmount, 10)
    );
    setPayableNowPrice(parseInt(carPrice * parseFloat(profitMargin), 10));
  }, [
    currentSelectedCarType,
    currentSelectedLanguage,
    profitMargin,
    surchargeAmount,
    discountAmount,
  ]);

  return {
    totalPrice,
    payableNowPrice,
    currentSelectedCarType,
    carPriceFrom,
    languagePriceFrom,
  };
};

export default useBookingPrice;
