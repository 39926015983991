import React, {
  useState, memo, useCallback, useMemo, useRef, useEffect
} from 'react';
import Joyride from 'react-joyride';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import DurationDropDown from 'components/DurationDropDown/Loadable';
import SeparationLine from 'components/SeparationLine/SeparationLine';
import Button from 'components/MuiComponents/Button';
import Checkbox from '@material-ui/core/Checkbox';

import { KeyboardArrowUpIcon, KeyboardArrowDownIcon } from 'components/StyledIcons/StyledIcons';
import BackwardIcon from '@material-ui/icons/ArrowBack';
import FordwardIcon from '@material-ui/icons/ArrowForward';
import TaxiIcon from '@material-ui/icons/LocalTaxi';
import TimerIcon from '@material-ui/icons/Timer';
import ClearIcon from '@material-ui/icons/Clear';
import InfoOutlineIcon from '@material-ui/icons/InfoOutlined';
import AddLocationIcon from '@material-ui/icons/AddCircleOutline';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MoreHorzIcon from '@material-ui/icons/MoreHoriz';
import UpwardIcon from '@material-ui/icons/ArrowUpward';
import DownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import EditIcon from '@material-ui/icons/Edit';
import MenuItem from '@material-ui/core/MenuItem';
import IconButtonMenu from 'components/MuiComponents/IconButtonMenu';
import isEmpty from 'lodash.isempty';
import { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import StyledButtonRounded from 'components/MuiComponents/RoundedButton';
import immutablySwapItems from 'helpers/immutablySwapItems';
import {
  TUTORIAL_TYPE,
  getJoyrideStep,
  getPlaceDisplayName,
} from 'utils/placeHelper';
import compose from 'utils/compose';
import { sendGtagEvent } from 'utils/gtag';
import replaceS3WithCDN from 'utils/replaceS3WithCDN';

const HOUR_LIMIT = 12;

const destinationType = {
  STARTING: 'STARTING',
  DESTINATION: 'DESTINATION',
  FINISHING: 'FINISHING'
};

const buttonStyles = {
  root: {
    backgroundColor: '#ffffff',
    width: '100%',
    boxShadow: 'none',
    border: 'solid 1px #595959',
    padding: '0.5rem 2rem'
  }
};

const getHours = duration => Math.floor(duration / 60);
const getMinutes = duration => (duration % 60).toFixed(0);
const getPhotosUrls = (destinations = []) => {
  const photosUrl = [];

  destinations.forEach(({ photos }) => {
    if (!photos || !photos.length) return;

    photos.forEach((photo, index) => {
      if (index >= 2) return; // only grab at most two photos

      if (photos[index].thumbnail_url) {
        return photosUrl.push(replaceS3WithCDN(photos[index].thumbnail_url));
      }
    });
  });

  return photosUrl;
};

const SummaryCard = memo(({
  /* eslint-disable react/prop-types */
  startingPlace,
  finishingPlace,
  destinations,
  styles,
  t,
  photoUrls,
  /* eslint-enable react/prop-types */
}) => {
  if (isEmpty(startingPlace) && isEmpty(finishingPlace)) {
    return (
      <div className={styles.clickToEditButton}>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          overwritestyles={css`
            background: #ffffff;
          `}
        >
          {t('DAY_PLANNER_CARD.CLICK_TO_EDIT')}
        </Button>
      </div>
    );
  }

  const destinationsNames = useMemo(() => (destinations
    .map(d => getPlaceDisplayName(d))
    .join(' → ')
  ), [destinations]);

  return (
    <div className={styles.summary}>
      <div className={styles.photos}>
        {
          photoUrls.map(photo => (
            <div
              key={photo}
              className={styles.photo}
              style={{
                background: `url("${photo || ''}")  center center / cover`
              }}
            />
          ))
        }
      </div>
      <div className={styles.destinationPreviewText}>
        <span className={styles.locationOnIcon}>
          <LocationOnIcon />
        </span>
        <h5 className={styles.summaryText}>
          {getPlaceDisplayName(startingPlace)}
          {destinationsNames ? ` → ${destinationsNames}` : ''}
          {' → '}
          {getPlaceDisplayName(finishingPlace)}
        </h5>
      </div>
    </div>
  );
});

const SameAsPickupCheckbox = memo(({
  /* eslint-disable react/prop-types */
  startingPlace,
  finishingPlace,
  styles,
  onCheckboxClick,
  t
  /* eslint-enable react/prop-types */
}) => (
  !isEmpty(startingPlace) && isEmpty(finishingPlace) ? (
    <div>
      <SeparationLine />
      <Checkbox
        label={t('DAY_PLANNER_CARD.SAME_AS_PICKUP')}
        className={styles.checkbox}
        labelPosition="right"
        labelStyle={{ color: '#828183' }}
        onClick={onCheckboxClick}
      />
    </div>
  ) : null
));

const Planner = memo(({
  /* eslint-disable react/prop-types */
  styles,
  t,
  multipleDays,
  duration,
  startingPlace,
  finishingPlace,
  destinations,
  photoUrls,
  startingPlaceHandler,
  finishingPlaceHandler,
  destinationsPlaceHandler,
  openSearch,
  renderPlanItem,
  day
  /* eslint-enable react/prop-types */
}) => {
  const cx = classnames.bind(styles); // eslint-disable-line

  const destinationsLength = destinations.length;

  if (isEmpty(startingPlace) || isEmpty(finishingPlace)) return null;

  const { className: addButtonClass } = useMemo(() => getJoyrideStep({
    type: TUTORIAL_TYPE.ADDBUTTON,
    day,
  }), [day]);

  const joyrideDestinationClass = useMemo(() => (
    destinations.map(({ place_id: destinationPlaceId }) => {
      const { className: destinationPlaceClass } = getJoyrideStep({
        id: destinationPlaceId,
        type: TUTORIAL_TYPE.LOCATION,
        day,
      });

      return destinationPlaceClass;
    })
  ), [day, destinations]);

  return (
    <ul className={styles.dayPlannerContainer}>
      {/* Trip Summary */}
      <li className={styles.tripListItem}>
        <div className={styles.tripListItemContent}>
          <InfoOutlineIcon className={styles.infoIcon} />
          <div
            data-cy="cy-trip-summary"
            className={styles.content}
          >
            {t('DAY_PLANNER_CARD.TRIP_SUMMARY')}
            <p className={styles.tripSummary}>
              {`${destinationsLength} ${t('DAY_PLANNER_CARD.DESTINATION')}`}
            </p>
            {multipleDays ? null : (
              <p
                className={cx('tripSummary', {
                  overtimeWarning: parseInt(duration, 10) >= HOUR_LIMIT
                })}
              >
                {duration ? `${duration} ${t('HOUR_plural')}` : null}
              </p>
            )}
            {!multipleDays && duration > HOUR_LIMIT ? (
              <p className={cx('tripSummary', 'overtimeWarning')}>
                {`(We normally recommend trip under ${HOUR_LIMIT} hours for optimal experience)`}
              </p>
            ) : null}
            <div className={styles.photos}>
              {photoUrls.map(photo => (
                <div
                  key={photo}
                  className={styles.photo}
                  style={{
                    background: `url("${photo || ''}")  center center / cover`
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </li>

      <li // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions
        className={styles.tripListItem}
        onClick={startingPlaceHandler}
        onKeyDown={startingPlaceHandler}
        style={{ cursor: 'pointer' }}
      >
        {renderPlanItem({
          destination: startingPlace,
          type: destinationType.STARTING
        })}
      </li>

      {destinations.map((destination, index) => (
        <li
          className={styles.tripListItem}
          key={joyrideDestinationClass[index]}
        >
          {renderPlanItem({
            destination,
            type: destinationType.DESTINATION,
            index,
            destinationMenuClass: joyrideDestinationClass[index] || '',
            length: destinations.length,
          })}
        </li>
      ))}

      {
        <li className={styles.tripListItem}>
          <div className={`${styles.addMorePlaceButtonContainer} ${addButtonClass}`}>
            <StyledButtonRounded
              data-cy="cy-add-attraction-button"
              variant="contained"
              overwritestyles={buttonStyles}
              onClick={() => {
                openSearch();
                destinationsPlaceHandler();
              }}
              disabled={!startingPlace || !finishingPlace}
            >
              <AddLocationIcon className="mr1" />
              <span className={styles.buttonText}>{t('DAY_PLANNER_CARD.ADD_PLACE')}</span>
            </StyledButtonRounded>
            {destinations.length === 0 ? (
              <span className={styles.floatingIcon}>
                <FordwardIcon />
              </span>
            ) : null}
          </div>
        </li>
      }

      {/* Send Back */}
      <li // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions
        className={styles.tripListItem}
        onClick={finishingPlaceHandler}
        onKeyDown={finishingPlaceHandler}
        style={{ cursor: 'pointer' }}
      >
        {renderPlanItem({
          destination: finishingPlace,
          type: destinationType.FINISHING,
          length: destinations.length,
        })}
      </li>
    </ul>
  );
});

const PlanItem = memo(({
  /* eslint-disable react/prop-types */
  styles,
  destination,
  type,
  index = -1,
  length = 0,
  day,
  destinationMenuClass,
  moveDestinationUpHandler,
  moveDestinationDownHandler,
  makeEnquiryRequest,
  dayPlan,
  openDetails,
  toggleAddToRoute,
  destinationsPlaceHandler,
  handleDestinationDurationChange,
  handleDeleteRequest,
  startingPlaceId,
  finishingPlaceId,
  startingPlaceDisplayName,
  finishingPlaceDisplayName,
  t
  /* eslint-enable react/prop-types */
}) => {
  if (!destination) return null;
  let prefix = '';
  let getDisplayIcon = () => {};
  const menuRef = useRef();

  switch (type) {
    case destinationType.STARTING:
      prefix = t('DAY_PLANNER_CARD.PICK_UP_AT');
      getDisplayIcon = () => <FordwardIcon className={styles.startIcon} />;
      break;
    case destinationType.FINISHING:
      prefix = t('DAY_PLANNER_CARD.DROPOFF_AT');
      getDisplayIcon = () => <BackwardIcon className={styles.endIcon} />;
      break;
    case destinationType.DESTINATION:
      getDisplayIcon = () => {
        if (index < 0) return null;

        return <span className={styles.stepIcon}>{index + 1}</span>;
      };
      break;
    default:
      prefix = '';
  }

  const {
    place_id: destinationPlaceId,
    ride: destinationRide,
    duration: defaultDuration,
  } = destination;

  const { className: durationClass } = useMemo(() => getJoyrideStep({
    id: destinationPlaceId,
    type: TUTORIAL_TYPE.DURATION,
    day,
  }), [day, destinationPlaceId]);

  const rideHours = getHours(destinationRide && destinationRide.duration);
  const rideMinutes = getMinutes(destinationRide && destinationRide.duration);
  const displayName = getPlaceDisplayName(destination) || '';
  const hourStr = rideHours ? `${rideHours} ${t('HOUR_ABBR')}` : '';
  const minStr = rideMinutes ? `${rideMinutes} ${t('MINUTES_ABBR')}` : '';
  const drivingTimeStr = `${hourStr} ${minStr}`;
  const currentDuration = destinationRide?.place_duration || defaultDuration; // eslint-disable-line camelcase
  const { destinations } = dayPlan;

  const onPlaceDetailOpen = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      sendGtagEvent(
        'Click on attracion name to view detail',
        'Booking Customize Trip',
        displayName
      );
      destinationsPlaceHandler();
      toggleAddToRoute(false);
      openDetails(destinationPlaceId);
    },
    [displayName, destinationPlaceId],
  );

  const onDurationChange = e => {
    e.stopPropagation();
    handleDestinationDurationChange(
      destinationPlaceId,
      e.target.value
    );
    sendGtagEvent(
      'Change Duration',
      'Booking Customize Trip'
    );
  };

  const onDeleteButtonClick = useCallback(
    e => {
      e.stopPropagation();
      handleDeleteRequest(index);
      sendGtagEvent('Delete Attraction', 'Booking Customize Trip');

      const cb = menuRef?.current?.handleClose;
      if (typeof cb === 'function') cb(e);
    },
    [menuRef, index],
  );

  const onMoveupButtonClick = useCallback(
    e => {
      e.stopPropagation();

      const cb = menuRef?.current?.handleClose;
      if (typeof cb === 'function') cb(e);

      moveDestinationUpHandler(index);
      sendGtagEvent(
        'Move Attraction',
        'Booking Customize Trip',
        'Up'
      );
      const newDestinations = immutablySwapItems(destinations, index, index - 1);

      const placesIds = newDestinations.map(d => d.place_id);
      const placesDuration = newDestinations.map(d => (d.ride && d.ride.place_duration) || d.duration);
      const names = newDestinations.map(d => getPlaceDisplayName(d));

      // Query Enquiry Now
      makeEnquiryRequest({
        places_in_route: [startingPlaceId, ...placesIds, finishingPlaceId],
        places_duration: [0, ...placesDuration, 0],
        names_in_route: [startingPlaceDisplayName, ...names, finishingPlaceDisplayName]
      });
    },
    [
      index,
      destinations,
      menuRef,
      startingPlaceId,
      finishingPlaceId,
      startingPlaceDisplayName,
      finishingPlaceDisplayName,
    ],
  );

  const onMovedownButtonClick = useCallback(
    e => {
      e.stopPropagation();

      const cb = menuRef?.current?.handleClose;
      if (typeof cb === 'function') cb(e);

      moveDestinationDownHandler(index);
      sendGtagEvent('Move Attraction', 'Booking Customize Trip', 'Down');

      const newDestinations = immutablySwapItems(destinations, index, index + 1);
      const placesIds = newDestinations.map(d => d.place_id);
      const placesDuration = newDestinations.map(d => (d.ride && d.ride.place_duration) || d.duration);
      const names = newDestinations.map(d => getPlaceDisplayName(d));

      makeEnquiryRequest({
        places_in_route: [startingPlaceId, ...placesIds, finishingPlaceId],
        places_duration: [0, ...placesDuration, 0],
        names_in_route: [startingPlaceDisplayName, ...names, finishingPlaceDisplayName]
      });
    }, [
      index,
      destinations,
      menuRef,
      startingPlaceId,
      finishingPlaceId,
      startingPlaceDisplayName,
      finishingPlaceDisplayName,
    ]
  );

  return (
    <div className={styles.tripListItemContent} data-cy={`cy-day-item-index-${index}`}>
      {destinationRide && destinationRide.duration ? (
        <span className={styles.drivingTime}>
          <TaxiIcon className={styles.carIcon} />
          <span>
            {t('DAY_PLANNER_CARD.ABOUT_DRIVING_TIME', {
              time: drivingTimeStr
            })}
          </span>
        </span>
      ) : (
        <span className={styles.drivingTime}>&nbsp;</span>
      )}
      <div className={styles.content}>
        {getDisplayIcon()}
        {prefix ? <p className={styles.placePrefix}>{prefix}</p> : null}
        <a
          tabIndex={0}
          role="button"
          onClick={onPlaceDetailOpen}
          onKeyDown={onPlaceDetailOpen}
        >
          <p className={styles.placeName} data-cy="cy-attraction-name">
            {displayName}
            <ArrowDownIcon className={styles.arrowRight} />
          </p>
        </a>
        {destination.duration && type === destinationType.DESTINATION ? (
          <div className={styles.destinationDuration}>
            <TimerIcon className={styles.timerIcon} />
            <span className={`${styles.durationLabel} ${durationClass}`}>
              <span>Stay about</span>
              <DurationDropDown
                key={`durationDropDown_${destinationPlaceId}_${new Date().getTime()}`}
                duration={currentDuration}
                onChangeHandler={onDurationChange}
              />
            </span>
          </div>
        ) : null}
        {type === destinationType.DESTINATION ? (
          <div className={styles.closeButtonContainer}>
            <IconButtonMenu
              innerRef={menuRef}
              icon={<MoreHorzIcon className={`${styles.upDownButton} ${destinationMenuClass}`} />}
            >
              <MenuItem
                data-cy="cy-item-delete-button"
                style={{ color: '#595959' }}
                onClick={onDeleteButtonClick}
                leftIcon={<ClearIcon />}
              >
                {t('DELETE')}
              </MenuItem>
              {index !== 0 && length > 1 ? (
                <MenuItem
                  data-cy="cy-item-moveup-button"
                  style={{ color: '#595959' }}
                  onClick={onMoveupButtonClick}
                  leftIcon={<UpwardIcon />}
                >
                  {t('DAY_PLANNER_CARD.MOVE_UP')}
                </MenuItem>
              ) : null}
              {index !== length - 1 && length > 1 ? (
                <MenuItem
                  data-cy="cy-item-movedown-button"
                  style={{ color: '#595959' }}
                  onClick={onMovedownButtonClick}
                  leftIcon={<DownwardIcon />}
                >
                  {t('DAY_PLANNER_CARD.MOVE_DOWN')}
                </MenuItem>
              ) : null}
            </IconButtonMenu>
          </div>
        ) : null}
      </div>
    </div>
  );
});

const InnerCard = memo(({
  /* eslint-disable react/prop-types */
  startingPlaceHandler,
  finishingPlaceHandler,
  destinationsPlaceHandler,
  addFinishingPlaceHandler,
  makeEnquiryRequest,
  enquiryResult,
  dayPlan,
  openSearch,
  day,
  styles,
  t,
  toggle,
  setToggle,
  shouldMakeEnquiry,
  changeDestinationPlaceDuration,
  moveDestinationUpHandler,
  moveDestinationDownHandler,
  openDetails,
  toggleAddToRoute,
  deleteDestination,
  /* eslint-enable react/prop-types */
}) => {
  const cx = classnames.bind(styles); // eslint-disable-line
  const { multiple_days: multipleDays, duration } = enquiryResult;
  const {
    startingPlace = {},
    finishingPlace = {},
    destinations,
  } = dayPlan;
  const { place_id: startingPlaceId } = startingPlace;
  const { place_id: finishingPlaceId } = finishingPlace;
  const { className: startingPlaceClass } = getJoyrideStep({
    id: startingPlaceId,
    type: TUTORIAL_TYPE.FROMTO,
    day,
  });
  const { className: finishingPlaceClass } = getJoyrideStep({
    id: finishingPlaceId,
    type: TUTORIAL_TYPE.FROMTO,
    day,
  });

  const startingPlaceDisplayName = useMemo(() => getPlaceDisplayName(startingPlace), [startingPlace]);
  const finishingPlaceDisplayName = useMemo(() => getPlaceDisplayName(finishingPlace), [finishingPlace]);

  const handleDeleteRequest = useCallback((index = -1) => {
    if (index < 0) return;

    deleteDestination(index);

    if (shouldMakeEnquiry) {
      const places = [
        ...destinations.slice(0, index),
        ...destinations.slice(index + 1)
      ];
      const placesIds = places.map(d => d.place_id);
      const placesDuration = places.map(d => (d.ride && d.ride.place_duration) || d.duration);
      const names = places.map(d => getPlaceDisplayName(d));

      makeEnquiryRequest({
        places_in_route: [startingPlaceId, ...placesIds, finishingPlaceId],
        places_duration: [0, ...placesDuration, 0],
        names_in_route: [startingPlaceDisplayName, ...names, finishingPlaceDisplayName]
      });
    }
  }, [
    destinations,
    startingPlaceId,
    finishingPlaceId,
    startingPlaceDisplayName,
    finishingPlaceDisplayName
  ]);

  const handleDestinationDurationChange = useCallback((placeId, placeDuration) => {
    changeDestinationPlaceDuration(placeId, placeDuration);

    if (shouldMakeEnquiry) {
      const placesId = destinations.map(d => d.place_id);
      const names = destinations.map(d => getPlaceDisplayName(d));
      const placesDuration = destinations.map(({
        place_id: _placeId,
        duration: _duration,
        ride,
      }) => {
        const { place_duration: defaultPlaceDuration } = ride || {};

        if (_placeId === placeId) return placeDuration;

        return _duration || defaultPlaceDuration;
      });

      makeEnquiryRequest({
        places_in_route: [startingPlaceId, ...placesId, finishingPlaceId],
        places_duration: [0, ...placesDuration, 0],
        names_in_route: [startingPlaceDisplayName, ...names, finishingPlaceDisplayName]
      });
    }
  }, [
    destinations,
    startingPlaceId,
    finishingPlaceId,
    startingPlaceDisplayName,
    finishingPlaceDisplayName
  ]);

  const renderPlanItem = restProps => (
    <PlanItem
      {...restProps}
      styles={styles}
      moveDestinationUpHandler={moveDestinationUpHandler}
      moveDestinationDownHandler={moveDestinationDownHandler}
      openDetails={openDetails}
      toggleAddToRoute={toggleAddToRoute}
      makeEnquiryRequest={makeEnquiryRequest}
      dayPlan={dayPlan}
      destinationsPlaceHandler={destinationsPlaceHandler}
      handleDestinationDurationChange={handleDestinationDurationChange}
      handleDeleteRequest={handleDeleteRequest}
      day={day}
      t={t}
      startingPlaceId={startingPlaceId}
      finishingPlaceId={finishingPlaceId}
      startingPlaceDisplayName={startingPlaceDisplayName}
      finishingPlaceDisplayName={finishingPlaceDisplayName}
    />
  );

  const photoUrls = useMemo(() => getPhotosUrls(destinations), [destinations]);

  const onCheckboxClick = useCallback(
    e => {
      e.stopPropagation();
      sendGtagEvent(
        'Same as Starting Place',
        'Booking Customize Trip'
      );
      addFinishingPlaceHandler(startingPlace);
      makeEnquiryRequest({
        places_in_route: [startingPlaceId, startingPlaceId]
      });
    },
    [startingPlace, startingPlaceId]
  );

  const togglePlanner = useCallback(e => {
    e.stopPropagation();
    setToggle(!toggle);
  }, [toggle]);

  const setStartingPlaceHandler = e => {
    e.stopPropagation();
    openSearch();
    startingPlaceHandler();
  };

  const setFinishingPlaceHandler = e => {
    e.stopPropagation();
    openSearch();
    finishingPlaceHandler();
  };

  return (
    <>
      {
        toggle ? null : (
          <div
            className={styles.editOrSummary}
            onClick={togglePlanner}
            onKeyDown={togglePlanner}
          >
            <SummaryCard
              startingPlace={startingPlace}
              finishingPlace={finishingPlace}
              destinations={destinations}
              styles={styles}
              togglePlanner={togglePlanner}
              photoUrls={photoUrls}
              t={t}
            />
          </div>
        )
      }
      <div className={cx('collapsibleWrapper', { unToggled: !toggle })}>
        <div className={styles.collapsible}>
          <div className={styles.cardInner}>
            <div className={styles.cardInnerPath} />
            <div
              className={`
                ${styles.cardInnerFromTo}
                ${startingPlaceClass}
                ${finishingPlaceClass}
              `}
            >
              <div
                onClick={setStartingPlaceHandler}
                onKeyDown={setStartingPlaceHandler}
              >
                <p className="m0">
                  {startingPlaceDisplayName ? (
                    <span>
                      <EditIcon className={styles.editIcon} />
                      {`${t('DAY_PLANNER_CARD.PICK_UP')} - `}
                      {startingPlaceDisplayName}
                    </span>
                  ) : (
                    <span className={styles.innerPlaceHolder}>
                      <AddCircleIcon className={styles.addCircle} />
                      {` ${t('DAY_PLANNER_CARD.PICK_UP_PLACE')}`}
                    </span>
                  )}
                </p>
              </div>
              <div
                onClick={setFinishingPlaceHandler}
                onKeyDown={setFinishingPlaceHandler}
              >
                <p className="m0">
                  {finishingPlaceDisplayName ? (
                    <span>
                      <EditIcon className={styles.editIcon} />
                      {`${t('DAY_PLANNER_CARD.DROPOFF')} - `}
                      {finishingPlaceDisplayName}
                    </span>
                  ) : (
                    <span className={styles.innerPlaceHolder}>
                      <AddCircleIcon className={styles.addCircle} />
                      {` ${t('DAY_PLANNER_CARD.DROPOFF_PLACE')}`}
                    </span>
                  )}
                </p>
              </div>
            </div>
            <SameAsPickupCheckbox
              styles={styles}
              onCheckboxClick={onCheckboxClick}
              startingPlace={startingPlace}
              finishingPlace={finishingPlace}
              t={t}
            />
          </div>

          <Planner
            styles={styles}
            t={t}
            multipleDays={multipleDays}
            duration={duration}
            startingPlace={startingPlace}
            finishingPlace={finishingPlace}
            destinations={destinations}
            photoUrls={photoUrls}
            startingPlaceHandler={startingPlaceHandler}
            finishingPlaceHandler={finishingPlaceHandler}
            destinationsPlaceHandler={destinationsPlaceHandler}
            openSearch={openSearch}
            renderPlanItem={renderPlanItem}
            day={day}
          />
        </div>
      </div>
    </>
  );
});

const DayPlannerItem = props => {
  const styles = require('./DayPlannerItem.scss');
  const cx = classnames.bind(styles); // eslint-disable-line
  const { t } = useTranslation(['common']);
  const {
    index,
    deleteDayTrip,
    hideDeleteTrip,
    startingPlaceHandler,
    finishingPlaceHandler,
    destinationsPlaceHandler,
    dayPlan,
    openSearch,
    addFinishingPlaceHandler,
    makeEnquiryRequest,
    enquiryResult,
    shouldMakeEnquiry,
    changeDestinationPlaceDuration,
    moveDestinationUpHandler,
    moveDestinationDownHandler,
    openDetails,
    toggleAddToRoute,
    deleteDestination,
    defaultToggle,
    joyrideStep,
    clearJoyrideStep,
  } = props;
  const [toggle, setToggle] = useState(defaultToggle);
  const [runJoyride, setRunJoyride] = useState(false);
  const menuRef = useRef();

  const { day: joyrideDay, target: joyrideTarget } = joyrideStep;

  const joyrideCallback = useCallback(({ action }) => {
    if (action === 'close') {
      clearJoyrideStep();
    }
  }, []);

  useEffect(() => {
    if (toggle && joyrideDay >= 0) setRunJoyride(true);

    return () => {
      setRunJoyride(false);
    };
  }, []);

  const joyrideStyle = useMemo(() => ({
    buttonNext: {
      backgroundColor: '#00b33b',
      borderRadius: 4,
      color: '#fff',
    },
    tooltipContent: {
      padding: '20px 10px 0',
    },
    tooltip: {
      width: '320px',
    },
    tooltipContainer: {
      textAlign: 'left',
    },
    floaterStyles: {
      options: {
        transition: 'none',
      }
    }
  }), []);

  const togglerButtonStyle = useMemo(() => ({
    root: {
      border: '1px solid #00b33b',
      color: '#00b33b',
      padding: '0.375rem 0.75rem',
      fontWeight: '300',
      fontSize: '0.75rem',
    }
  }), []);

  const arrowStyle = useMemo(() => ({
    root: {
      fontSize: '1rem',
      color: '#00b33b',
      marginLeft: '0.25rem',
    }
  }), []);

  const toggleHandler = useCallback(
    e => {
      e.stopPropagation();
      setToggle(!toggle);
    },
    [toggle],
  );

  useEffect(() => {
    if (typeof window !== 'undefined' && joyrideTarget) {
      const scrollToElement = ({ top = 0 }) => {
        if (!top) return;

        setTimeout(() => {
          window.scroll({
            top: window.scrollY + top - 100,
            behavior: 'smooth',
          });
        }, 0);
      };
      const getBoundingRect = el => el ? el.getBoundingClientRect() : {};
      const getFirstHtmlElement = elements => elements?.[0];
      const queryElementsByClassName = classname => document.getElementsByClassName(classname);
      const getClassName = classnameWithDot => classnameWithDot.slice(1);

      compose(
        scrollToElement,
        getBoundingRect,
        getFirstHtmlElement,
        queryElementsByClassName,
        getClassName,
      )(joyrideTarget);
    }
  }, [dayPlan]);

  return (
    <div className={cx('dayListItemContainer', { toggled: toggle })}>
      {
        toggle && joyrideDay === index
          ? (
            <Joyride
              steps={[joyrideStep]}
              styles={joyrideStyle}
              locale={{ close: 'Okay' }}
              callback={joyrideCallback}
              run={runJoyride}
              hideBackButton
              disableBeacon
            />
          ) : null
      }
      <div className={styles.collapseTitle}>
        <span>
          <span>
            {t('DAY_PLANNER_CARD.DAY_NUMBER_TITLE', { day: index + 1 })}
          </span>
          {
            hideDeleteTrip
              ? null
              : (
                <IconButtonMenu
                  innerRef={menuRef}
                  icon={<MoreHorzIcon className={styles.upDownButton} />}
                >
                  <MenuItem
                    style={{ color: 'red' }}
                    onClick={e => {
                      e.stopPropagation();
                      deleteDayTrip();
                      if (menuRef?.current) menuRef.handleClose(e);
                    }}
                  >
                    {t('DAY_PLANNER_CARD.DELETE_THIS_DAY')}
                  </MenuItem>
                </IconButtonMenu>
              )
          }
        </span>
        <StyledButtonRounded
          data-cy="cy-dayplanner-toggler"
          onClick={toggleHandler}
          overwritestyles={togglerButtonStyle}
        >
          {
            toggle
              ? (
                <>
                  <span>Close</span>
                  <KeyboardArrowUpIcon overwritestyles={arrowStyle} />
                </>
              )
              : (
                <>
                  <span>Customize</span>
                  <KeyboardArrowDownIcon overwritestyles={arrowStyle} />
                </>
              )
          }
        </StyledButtonRounded>
      </div>
      <InnerCard
        styles={styles}
        day={index}
        startingPlaceHandler={startingPlaceHandler}
        finishingPlaceHandler={finishingPlaceHandler}
        destinationsPlaceHandler={destinationsPlaceHandler}
        addFinishingPlaceHandler={addFinishingPlaceHandler}
        makeEnquiryRequest={makeEnquiryRequest}
        dayPlan={dayPlan}
        openSearch={openSearch}
        t={t}
        toggle={toggle}
        setToggle={setToggle}
        enquiryResult={enquiryResult}
        shouldMakeEnquiry={shouldMakeEnquiry}
        changeDestinationPlaceDuration={changeDestinationPlaceDuration}
        moveDestinationUpHandler={moveDestinationUpHandler}
        moveDestinationDownHandler={moveDestinationDownHandler}
        openDetails={openDetails}
        toggleAddToRoute={toggleAddToRoute}
        deleteDestination={deleteDestination}
      />
    </div>
  );
};

DayPlannerItem.propTypes = {
  index: PropTypes.number,
  startingPlaceHandler: PropTypes.func,
  finishingPlaceHandler: PropTypes.func,
  destinationsPlaceHandler: PropTypes.func,
  addFinishingPlaceHandler: PropTypes.func,
  moveDestinationUpHandler: PropTypes.func,
  moveDestinationDownHandler: PropTypes.func,
  enquiryResult: PropTypes.objectOf(PropTypes.any),
  makeEnquiryRequest: PropTypes.func,
  changeDestinationPlaceDuration: PropTypes.func,
  deleteDestination: PropTypes.func,
  deleteDayTrip: PropTypes.func,
  dayPlan: PropTypes.objectOf(PropTypes.any),
  hideDeleteTrip: PropTypes.bool,
  shouldMakeEnquiry: PropTypes.bool,
  openDetails: PropTypes.func.isRequired,
  openSearch: PropTypes.func.isRequired,
  defaultToggle: PropTypes.bool,
  toggleAddToRoute: PropTypes.func.isRequired,
  joyrideStep: PropTypes.objectOf(PropTypes.any),
  clearJoyrideStep: PropTypes.func,
};

DayPlannerItem.defaultProps = {
  addFinishingPlaceHandler: () => {},
  startingPlaceHandler: () => {},
  finishingPlaceHandler: () => {},
  destinationsPlaceHandler: () => {},
  moveDestinationUpHandler: () => {},
  moveDestinationDownHandler: () => {},
  makeEnquiryRequest: () => {},
  changeDestinationPlaceDuration: () => {},
  deleteDestination: () => {},
  deleteDayTrip: () => {},
  enquiryResult: {},
  dayPlan: {},
  index: 0,
  hideDeleteTrip: false,
  shouldMakeEnquiry: false,
  defaultToggle: false,
  joyrideStep: {},
  clearJoyrideStep: {},
};

export default DayPlannerItem;
