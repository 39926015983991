import config from '../config';

const s3Regex = /^https?:\/\/s3-[a-z0-9-]*.amazonaws.com\/btw-photos/;
const cdnPrefix = `${config.cdnUrl}`;

const replaceS3WithCDN = url => {
  if (!url) return;

  const result = url.replace(s3Regex, cdnPrefix);

  return result || url;
};

export default replaceS3WithCDN;
