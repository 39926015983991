import moment from 'moment-timezone';
import compose from 'utils/compose';

const TAIWAN_TIME_ZONE = 'Asia/Taipei';
const LAST_MINUTE_HOUR_LIMIT = 22;

// get Country local time
const formatToYYMMDD = time => time.format('YYYY-MM-DD');

// Format the current time to the beggining of the day
const getBegginingFromTime = time => moment(formatToYYMMDD(time));

const getNextDay = time => time.add(1, 'day');
const getMinimumDate = (hour, time) => (hour >= LAST_MINUTE_HOUR_LIMIT ? getNextDay(time) : time);

export const getTimeByTimezone = tz => moment.tz(tz);
export const getHourFromTime = time => +time.format('H');
export const getMinuteFromTime = time => +time.format('mm');

export const getPickupTimeInTimezone = (date, tz) => moment.tz(date, tz);

export const getTodayYYMMDDByTimezone = timezone => compose(
  formatToYYMMDD,
  getTimeByTimezone,
)(timezone);

const getMinBookingDate = (timezone = TAIWAN_TIME_ZONE) => {
  const formattedCountryTime = compose(
    getBegginingFromTime,
    getTimeByTimezone,
  )(timezone);

  const currentHourOfTimezone = compose(
    getHourFromTime,
    getTimeByTimezone,
  )(timezone);

  // User can still book today before noon
  return getMinimumDate(currentHourOfTimezone, formattedCountryTime);
};

export default getMinBookingDate;
