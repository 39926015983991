import isEmpty from 'lodash.isempty';
import immutablySwapItems from 'helpers/immutablySwapItems';
import {
  GET_ENQUIRY_PRICING_SUCCESS, GET_ENQUIRY_PRICING, GET_ENQUIRY_PRICING_FAILED,
  getPlaceInRoute,
  getPlaceDuration,
  getNamesInRoute,
  getGeometryInRoute,
  getPhotosInRoute,
} from './planner';
import {
  LOGIN_WITH_MAGIC_LINK_SUCCESS,
  LOAD_PRESERVED_STATE_SUCCESS
} from './auth';

const CHANGE_CUSTOMIZE_COUNTRY = 'search/setting/CHANGE_CUSTOMIZE_COUNTRY';
const ADD_STARTING_PLACE = 'search/places/ADD_STARTING_PLACE';
const ADD_FINISHING_PLACE = 'search/places/ADD_FINISHING_PLACE';
const ADD_DESTINATION_PLACE = 'search/places/ADD_DESTINATION_PLACE';
const ADD_NEW_DAY = 'search/places/ADD_NEW_DAY';
const DELETE_DAY_TRIP = 'search/places/DELETE_DAY_TRIP';
const DELETE_DESTINATION = 'search/places/DELETE_DESTINATION';
const CHANGE_DESTINATION_DURATION = 'search/place/CHANGE_DESTINATION_DURATION';
const LOAD_ENQUIRY = 'request/places/LOAD_ENQUIRY';
export const LOAD_ENQUIRY_SUCCESS = 'request/places/LOAD_ENQUIRY_SUCCESS';
const LOAD_ENQUIRY_FAIL = 'request/places/LOAD_ENQUIRY_FAIL';
const MOVE_DESTINATION_UP = 'request/places/MOVE_DESTINATION_UP';
const MOVE_DESTINATION_DOWN = 'request/places/MOVE_DESTINATION_DOWN';
const RESTORE_CUSTOMIZE_OBJECT = 'restore/RESTORE_CUSTOMIZE_OBJECT';
const LOAD_INITIALIZE_IMPORTING_WITHOUT_ENQUIRY = 'LOAD_INITIALIZE_IMPORTING_WITHOUT_ENQUIRY';
const LOAD_INITIALIZE_IMPORTING_WITH_ENQUIRY = 'LOAD_INITIALIZE_IMPORTING_WITH_ENQUIRY';
const LOAD_INITIALIZE_IMPORTING_WITH_ENQUIRY_SUCCESS = 'LOAD_INITIALIZE_IMPORTING_WITH_ENQUIRY_SUCCESS';
const LOAD_INITIALIZE_IMPORTING_WITH_ENQUIRY_FAIL = 'LOAD_INITIALIZE_IMPORTING_WITH_ENQUIRY_FAIL';
const LOAD_PERSIST_ENQUIRY = 'LOAD_PERSIST_ENQUIRY';
const LOAD_PERSIST_ENQUIRY_SUCCESS = 'LOAD_PERSIST_ENQUIRY_SUCCESS';
const LOAD_PERSIST_ENQUIRY_FAIL = 'LOAD_PERSIST_ENQUIRY_FAIL';
const LOAD_PERSISTED_ENQUIRY = 'LOAD_PERSISTED_ENQUIRY';
const LOAD_PERSISTED_ENQUIRY_SUCCESS = 'LOAD_PERSISTED_ENQUIRY_SUCCESS';
const LOAD_PERSISTED_ENQUIRY_FAIL = 'LOAD_PERSISTED_ENQUIRY_FAIL';
const CLEAR_ENQUIRY = 'csutomize/booking/CLEAR_ENQUIRY';
const UPDATE_CUSTOMIZE_DAY_PLAN = 'customize/booking/UPDATE_CUSTOMIZE_DAY_PLAN';

const LOAD_CARTYPES_ENQUIRY = 'LOAD_CARTYPES_ENQUIRY';
export const LOAD_CARTYPES_ENQUIRY_SUCCESS = 'LOAD_CARTYPES_ENQUIRY_SUCCESS';
const LOAD_CARTYPES_ENQUIRY_FAIL = 'LOAD_CARTYPES_ENQUIRY_FAIL';

const newPlanObject = () => ({
  startingPlace: {},
  finishingPlace: {},
  destinations: [],
});

const initialState = () => ({
  dayPlan: [newPlanObject()],
  enquiryResult: {},
  loadingEnquiry: false,
  persisting: false,
  persisted: false,
  persistError: null,
  importing: false,
  imported: false,
  importError: null,
  importedFromItineraryId: '',
  loadingPersisted: false,
  loadPersisted: false,
  loadPersistedError: null,
  selectedCountry: 'tw',
  referenceId: ''
});

const formatDestination = ({ destination, ride }) => ({
  description: destination.name,
  id: destination.google_place_id,
  place_id: destination.google_place_id,
  photos: destination.photos,
  geometry: {
    lat: destination.latitude,
    lng: destination.longitude
  },
  duration: destination.duration,
  ride
});

const mergeDayPlanWithEnquiry = (oldDayPlan, enquiry) => oldDayPlan.map((d, index) => {
  const reOrder = [];
  if (!enquiry) return d;

  const { places: enquiryPlaces } = enquiry;
  const { places: newPlaces } = enquiryPlaces[index];
  const newPlacesLength = newPlaces.length;
  let { finishingPlace } = oldDayPlan[index];

  if (newPlacesLength >= 2) {
    // ex: [ 1, 2, 3, 4 ] -> [2, 3]
    newPlaces.slice(1, newPlacesLength - 1).forEach(_place => {
      const { place, ride } = _place || {};

      if (isEmpty(place)) return;

      const found = oldDayPlan[index].destinations.find(p => p.place_id === place.place_id);

      if (found) {
        reOrder.push({
          ...found,
          duration: place.duration,
          distance: place.distance,
          ride
        });
      }
    });

    const lastPlace = newPlaces[newPlacesLength - 1];
    if (finishingPlace.place_id === lastPlace.place.place_id) {
      finishingPlace = {
        ...finishingPlace,
        duration: lastPlace.place.duration,
        distance: lastPlace.place.distance,
        ride: lastPlace.ride
      };
    }

    return {
      ...d,
      finishingPlace,
      destinations: reOrder
    };
  }

  return d;
});

export default function reducer(state = initialState(), action = {}) {
  let dayArr = [];
  switch (action.type) {
    case CHANGE_CUSTOMIZE_COUNTRY:
      return {
        ...state,
        selectedCountry: action.country,
      };
    /**
     * Auth.js Actions
     */
    case LOAD_PRESERVED_STATE_SUCCESS:
    case LOGIN_WITH_MAGIC_LINK_SUCCESS:
      const { preservedState } = action.result;
      return preservedState && preservedState.customize ? { ...preservedState.customize } : { ...state };
    // End of Auth.js Actions
    case CLEAR_ENQUIRY:
      return initialState();
    case LOAD_CARTYPES_ENQUIRY_SUCCESS:
    case LOAD_INITIALIZE_IMPORTING_WITH_ENQUIRY_SUCCESS:
      dayArr = mergeDayPlanWithEnquiry(state.dayPlan, action.result);

      return {
        ...state,
        importing: false,
        imported: false,
        enquiryResult: action.result,
        dayPlan: dayArr,
        loadingEnquiry: false,
        importError: null
      };
    case LOAD_INITIALIZE_IMPORTING_WITHOUT_ENQUIRY:
      if (!action.route) return state;
      dayArr = [];

      action.route.forEach(day => {
        const newDayTemplate = newPlanObject();
        const length = day && day.destinations && day.destinations.length;

        if (!length) return null;

        // Starting Place
        newDayTemplate.startingPlace = formatDestination(day.destinations[0]);
        // Finishing Place
        newDayTemplate.finishingPlace = formatDestination(day.destinations[length - 1]);
        // Destinations
        day.destinations.slice(1, -1).forEach(destination => {
          newDayTemplate.destinations.push(formatDestination(destination));
        });

        dayArr.push(newDayTemplate);
      });

      return {
        ...state,
        dayPlan: dayArr,
        imported: true,
        importedFromItineraryId: action.id
      };
    case DELETE_DAY_TRIP:
      dayArr = [];
      state.dayPlan.forEach((d, index) => {
        if (index !== action.day) dayArr.push(d);
      });
      return {
        ...state,
        dayPlan: dayArr
      };
    case ADD_NEW_DAY:
      return {
        ...state,
        dayPlan: [...state.dayPlan, newPlanObject()]
      };
    case MOVE_DESTINATION_DOWN:
      dayArr = state.dayPlan.map((d, index) => {
        if (index === action.day) {
          return {
            ...d,
            destinations: immutablySwapItems(d.destinations, action.placeIndex, action.placeIndex + 1)
          };
        }

        return d;
      });

      return {
        ...state,
        dayPlan: dayArr
      };
    case MOVE_DESTINATION_UP:
      dayArr = state.dayPlan.map((d, index) => {
        if (index === action.day) {
          return {
            ...d,
            destinations: immutablySwapItems(d.destinations, action.placeIndex, action.placeIndex - 1)
          };
        }

        return d;
      });

      return {
        ...state,
        dayPlan: dayArr
      };
    case UPDATE_CUSTOMIZE_DAY_PLAN:
      return {
        ...state,
        dayPlan: action.dayPlan,
      };
    case ADD_STARTING_PLACE:
      dayArr = state.dayPlan.map((d, index) => {
        if (index === action.day) {
          return {
            ...d,
            startingPlace: action.place
          };
        }

        return d;
      });

      return {
        ...state,
        dayPlan: dayArr
      };
    case ADD_FINISHING_PLACE:
      dayArr = state.dayPlan.map((d, index) => {
        if (index === action.day) {
          return {
            ...d,
            finishingPlace: action.place
          };
        }

        return d;
      });

      return {
        ...state,
        dayPlan: dayArr
      };
    case ADD_DESTINATION_PLACE:
      dayArr = state.dayPlan.map((d, index) => {
        if (index === action.day) {
          const found = state.dayPlan[index].destinations
            && state.dayPlan[index].destinations.find(destination => destination.place_id === action.place.place_id);

          return {
            ...d,
            destinations: found
              ? [...state.dayPlan[index].destinations]
              : [...state.dayPlan[index].destinations, action.place]
          };
        }

        return d;
      });

      return {
        ...state,
        dayPlan: dayArr
      };
    case CHANGE_DESTINATION_DURATION:
      const newDestinations = [];
      state.dayPlan[action.day].destinations.forEach(d => {
        if (d.place_id === action.placeId) {
          newDestinations.push({
            ...d,
            duration: action.duration
          });
        } else {
          newDestinations.push(d);
        }
      });

      dayArr = state.dayPlan.map((d, index) => {
        if (index === action.day) {
          return {
            ...d,
            destinations: newDestinations
          };
        }

        return d;
      });

      return {
        ...state,
        dayPlan: dayArr
      };
    case DELETE_DESTINATION:
      dayArr = state.dayPlan.map((d, index) => {
        if (index === action.day) {
          return {
            ...d,
            destinations: [
              ...state.dayPlan[index].destinations.slice(0, action.destinationIndex),
              ...state.dayPlan[index].destinations.slice(action.destinationIndex + 1)
            ]
          };
        }

        return d;
      });

      return {
        ...state,
        dayPlan: dayArr
      };
    case LOAD_PERSISTED_ENQUIRY:
      return {
        ...state,
        loadingPersisted: true,
        loadPersisted: false,
        loadPersistedError: null
      };
    case LOAD_PERSISTED_ENQUIRY_SUCCESS:
      const formatDestinationInDayPlanFormat = ({ place, ride }, queryEnquiryDayList) => {
        const { place_id: placeId } = place;

        const {
          places_in_route: _placesInRoute = [],
          names_in_route: _namesInRoute = [],
          geometries_in_route: _geoInRoute = []
        } = queryEnquiryDayList;
        const nameIndex = _placesInRoute && _placesInRoute.findIndex(e => placeId === e);
        const name = _namesInRoute && _namesInRoute[nameIndex];
        const geometry = _geoInRoute && _geoInRoute[nameIndex];

        return {
          description: name,
          id: placeId,
          place_id: placeId,
          photos: [],
          geometry,
          duration: ride.place_duration || place.duration || 60,
          ride
        };
      };

      dayArr = [];

      try {
        // Hack the enquiry result into DayPlan Format
        const { enquiry, places } = action.result;
        const { multiple_days: multipleDays = [] } = enquiry;
        places.forEach((day, dayIndex) => {
          const newDayTemplate = newPlanObject();
          const length = day && day.places && day.places.length;

          if (!length) return null;

          // Starting Place
          newDayTemplate.startingPlace = formatDestinationInDayPlanFormat(day.places[0], multipleDays[dayIndex]);
          // Finishing Place
          newDayTemplate.finishingPlace = formatDestinationInDayPlanFormat(
            day.places[length - 1],
            multipleDays[dayIndex]
          );
          // Destinations
          day.places.slice(1, -1).forEach(destination => {
            newDayTemplate.destinations.push(formatDestinationInDayPlanFormat(destination, multipleDays[dayIndex]));
          });

          dayArr.push(newDayTemplate);
        });
      } catch (e) {
        console.error('Init Reference Error', e);
      }

      return {
        ...state,
        dayPlan: dayArr,
        loadingPersisted: false,
        loadPersisted: true,
        loadPersistedError: null,
        enquiryResult: action.result
      };
    case LOAD_PERSISTED_ENQUIRY_FAIL:
      return {
        ...state,
        loadingPersisted: false,
        loadPersisted: false,
        loadPersistedError: action.error
      };
    case LOAD_PERSIST_ENQUIRY:
      return {
        ...state,
        persisting: true,
        persisted: false,
        persistError: null
      };
    case LOAD_PERSIST_ENQUIRY_SUCCESS:
      const { enquiry: { reference_id: referenceId } } = action.result;

      return {
        ...state,
        persisting: false,
        persisted: true,
        persistError: null,
        enquiryResult: action.result,
        referenceId
      };
    case LOAD_PERSIST_ENQUIRY_FAIL:
      return {
        ...state,
        persisting: false,
        persisted: false,
        persistError: action.error
      };
    case LOAD_CARTYPES_ENQUIRY_FAIL:
    case LOAD_INITIALIZE_IMPORTING_WITH_ENQUIRY_FAIL:
      return {
        ...state,
        importing: false,
        imported: false,
        importError: action.error
      };
    case LOAD_ENQUIRY:
      return {
        ...state,
        loadingEnquiry: true,
        persisted: false
      };
    case LOAD_ENQUIRY_SUCCESS:
      dayArr = mergeDayPlanWithEnquiry(state.dayPlan, action.result);

      return {
        ...state,
        dayPlan: dayArr,
        loadingEnquiry: false,
        enquiryResult: action.result
      };
    case GET_ENQUIRY_PRICING:
      return {
        ...state,
        loadingEnquiry: true,
      };
    case GET_ENQUIRY_PRICING_FAILED:
      return {
        ...state,
        loadingEnquiry: false,
      };
    case GET_ENQUIRY_PRICING_SUCCESS:
      return {
        ...state,
        loadingEnquiry: false,
        enquiryResult: action.result,
      };
    case RESTORE_CUSTOMIZE_OBJECT:
      return {
        ...action.customize
      };
    default:
      return state;
  }
}

export function changeCustomizeCountry(country) {
  return {
    type: CHANGE_CUSTOMIZE_COUNTRY,
    country,
  };
}

export function moveDestinationUp(index, day) {
  return {
    type: MOVE_DESTINATION_UP,
    placeIndex: index,
    day
  };
}

export function moveDestinationDown(index, day) {
  return {
    type: MOVE_DESTINATION_DOWN,
    placeIndex: index,
    day
  };
}

export function addStartingPlace(place, day) {
  return {
    type: ADD_STARTING_PLACE,
    place,
    day
  };
}

export function addFinishingPlace(place, day) {
  return {
    type: ADD_FINISHING_PLACE,
    place,
    day
  };
}

export function addDestinationPlace(place, day) {
  return {
    type: ADD_DESTINATION_PLACE,
    place,
    day
  };
}

export function changeDestinationPlaceDuration(placeId, duration, day) {
  return {
    type: CHANGE_DESTINATION_DURATION,
    placeId,
    duration,
    day
  };
}

export function deleteDestination(destinationIndex, day) {
  return {
    type: DELETE_DESTINATION,
    destinationIndex,
    day
  };
}

export function makeEnquiryRequest({
  enquiry,
  day,
  dayPlan,
  countryCode
}) {
  let shouldFireEnquiry = true; // safety check

  const multipleDaysEnquiry = {
    multiple_days: dayPlan ? dayPlan.map((plan, index) => {
      const { startingPlace: pickup = {}, finishingPlace: dropoff = {}, destinations = [] } = plan || {};

      // fisrt check if incoming enquiry
      if (day === index) {
        const { places_in_route: _placesInRoute, places_duration: _placesDuration } = enquiry || {};

        if (isEmpty(_placesInRoute) || isEmpty(_placesDuration)) {
          shouldFireEnquiry = false;

          return {};
        }

        return enquiry;
      }

      // if no incoming enquiry, then check if we can form a new structure with pickup and dropoff
      if (isEmpty(pickup) || isEmpty(dropoff)) {
        shouldFireEnquiry = false;

        return {};
      }

      return {
        places_in_route: getPlaceInRoute({ pickup, destinations, dropoff }),
        places_duration: getPlaceDuration({ pickup, destinations, dropoff })
      };
    }) : [],
    country: countryCode,
  };

  if (!shouldFireEnquiry) {
    return {
      type: LOAD_ENQUIRY_FAIL
    };
  }

  return {
    types: [LOAD_ENQUIRY, LOAD_ENQUIRY_SUCCESS, LOAD_ENQUIRY_FAIL],
    promise: ({ client }) => client.post('/enquiry/load', {
      data: {
        enquiry: multipleDaysEnquiry
      }
    }),
    day
  };
}

export function addNewDay() {
  return {
    type: ADD_NEW_DAY
  };
}

export function deleteDayTrip(day) {
  return {
    type: DELETE_DAY_TRIP,
    day
  };
}

export function clearEnquiry() {
  return {
    type: CLEAR_ENQUIRY
  };
}

export function restoreCustomizeObject(customize) {
  return {
    type: RESTORE_CUSTOMIZE_OBJECT,
    customize
  };
}

export function loadPersistedEnquiry(referenceId) {
  return {
    types: [LOAD_PERSISTED_ENQUIRY, LOAD_PERSISTED_ENQUIRY_SUCCESS, LOAD_PERSISTED_ENQUIRY_FAIL],
    promise: ({ client }) => client.post('/enquiry/persist', {
      data: {
        referenceId
      }
    })
  };
}

export function persistEnquiry(enquiryResult, dayPlan, referenceId) {
  const multipleDaysEnquiry = {
    multiple_days: dayPlan
      ? dayPlan.map(({ startingPlace: pickup = {}, finishingPlace: dropoff = {}, destinations = [] }) => ({
        places_in_route: getPlaceInRoute({ pickup, destinations, dropoff }),
        places_duration: getPlaceDuration({ pickup, destinations, dropoff }),
        names_in_route: getNamesInRoute({ pickup, destinations, dropoff }),
        geometry_in_route: getGeometryInRoute({ pickup, destinations, dropoff }),
        photos_in_route: getPhotosInRoute({ pickup, destinations, dropoff }),
      }))
      : []
  };

  return {
    types: [LOAD_PERSIST_ENQUIRY, LOAD_PERSIST_ENQUIRY_SUCCESS, LOAD_PERSIST_ENQUIRY_FAIL],
    promise: ({ client }) => client.post('/enquiry/load', {
      data: {
        enquiry: multipleDaysEnquiry,
        persist: true,
        referenceId
      }
    })
  };
}

export function retrieveCarTypes(route, id) {
  const multipleDaysEnquiry = {
    multiple_days: route
      ? route.map(({ destinations }) => {
        const places = destinations.map(({ destination }) => destination.google_place_id || destination.place_id);
        const placesDuration = destinations.map(({ destination, ride }) => (ride && ride.place_duration) || destination.duration || 60);
        return {
          places_in_route: [...places],
          places_duration: [...placesDuration]
        };
      })
      : []
  };

  return {
    types: [
      LOAD_CARTYPES_ENQUIRY,
      LOAD_CARTYPES_ENQUIRY_SUCCESS,
      LOAD_CARTYPES_ENQUIRY_FAIL
    ],
    promise: ({ client }) => client.post('/enquiry/load', {
      data: {
        enquiry: multipleDaysEnquiry
      }
    }),
    route,
    id
  };
}

export function initializeImportingWithEnquiry(route, id) {
  const multipleDaysEnquiry = {
    multiple_days: route
      ? route.map(({ destinations }) => {
        const places = destinations.map(({ destination }) => destination.google_place_id || destination.place_id);
        const placesDuration = destinations.map(({ destination, ride }) => (ride && ride.place_duration) || destination.duration || 60);
        return {
          places_in_route: [...places],
          places_duration: [...placesDuration]
        };
      })
      : []
  };

  return {
    types: [
      LOAD_INITIALIZE_IMPORTING_WITH_ENQUIRY,
      LOAD_INITIALIZE_IMPORTING_WITH_ENQUIRY_SUCCESS,
      LOAD_INITIALIZE_IMPORTING_WITH_ENQUIRY_FAIL
    ],
    promise: ({ client }) => client.post('/enquiry/load', {
      data: {
        enquiry: multipleDaysEnquiry
      }
    }),
    route,
    id
  };
}

export function updateCustomizeDayPlan(dayPlan) {
  return {
    type: UPDATE_CUSTOMIZE_DAY_PLAN,
    dayPlan,
  };
}

export function initializeImportingWithoutEnquiry(route, id) {
  return {
    type: LOAD_INITIALIZE_IMPORTING_WITHOUT_ENQUIRY,
    route,
    id
  };
}
