import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';

import About from 'containers/About/Loadable';
import Assign from 'containers/Assign/Loadable';
import App from 'containers/App/Loadable';
import Booking from 'containers/Booking/Loadable';
import City from 'containers/City/Loadable';
import Customize from 'containers/Customize/Loadable';
import Country from 'containers/Country/Loadable';
import EmailLoginRedirect from 'containers/EmailLoginRedirect/Loadable';
import FbLoginRedirect from 'containers/FbLoginRedirect/Loadable';
import Home from 'containers/NewHome/Loadable';
import Help from 'containers/Help/Loadable';
import Itinerary from 'containers/NewItinerary/Loadable';
import Login from 'containers/Login/Loadable';
import LoginSuccess from 'containers/LoginSuccess/Loadable';
import NotFound from 'containers/NotFound/Loadable';
import NewPricing from 'containers/NewPricing/Loadable';
import NonLoginBookingSuccess from 'containers/NonLoginBookingSuccess/Loadable';
import PlacesList from 'containers/PlacesList/Loadable';
import PlaceItem from 'containers/PlaceItem/Loadable';
import Planner from 'containers/Planner/Loadable';
import Policy from 'containers/Policy/Loadable';
import Privacy from 'containers/Privacy/Loadable';
import Pricing from 'containers/Pricing/PricingFunction';
import Profile from 'containers/Profile/Loadable';
import Reviews from 'containers/Reviews/Loadable';
import ReviewDriver from 'containers/ReviewDriver/Loadable';
import Search from 'containers/Search/Loadable';
import Survey from 'containers/Survey/Loadable';
import Terms from 'containers/Terms/Loadable';
import Trips from 'containers/Trips/Loadable';
import Trip from 'containers/Trip/Loadable';

import isEmpty from 'lodash.isempty';
import idx from 'idx';

const isAuthenticated = connectedRouterRedirect({
  redirectPath: '/login',
  authenticatedSelector: state => {
    const user = idx(state, _ => _.auth.user);

    return !isEmpty(user);
  },
  wrapperDisplayName: 'UserIsAuthenticated'
});

// const isNotAuthenticated = connectedReduxRedirect({
//   redirectPath: '/',
//   authenticatedSelector: state => state.auth.user === null,
//   redirectAction: routerActions.replace,
//   wrapperDisplayName: 'UserIsAuthenticated',
//   allowRedirectBack: false
// });

const routes = [
  {
    component: App,
    routes: [
      { path: '/', exact: true, component: Home },
      { path: '/a/:token', component: Assign },
      { path: '/about', component: About },
      { path: '/booking/success/:referenceid', component: NonLoginBookingSuccess, exact: true },
      { path: '/booking/:itineraryid', component: Booking },
      { path: '/drivers/reviews', component: Reviews },
      { path: '/callback/facebook', component: FbLoginRedirect },
      { path: '/callback/email', component: EmailLoginRedirect },

      { path: '/country/:name', component: Country },

      { path: '/help', component: Help },
      { path: '/itinerary/customize', component: Customize, exact: true },
      {
        path: '/itinerary/customize/pricing',
        component: NewPricing,
        exact: true
      },
      {
        path: '/itinerary/customize/pricing/beta/?reference=:reference',
        component: NewPricing,
        exact: true
      },
      {
        path: '/itinerary/customize/planner',
        component: Planner,
        exact: true,
        isCustomizePlanningRoute: true
      },
      {
        path: '/itinerary/customize/planner/beta',
        component: Planner,
        isCustomizePlanningRoute: true,
        exact: true
      },
      {
        path: '/itinerary/customize/planner/?reference=:reference',
        component: Pricing,
        isCustomizePlanningRoute: true,
        exact: true
      },
      {
        path: '/itinerary/customize/planner/beta/?reference=:reference',
        component: Planner,
        isCustomizePlanningRoute: true,
        exact: true
      },
      { path: '/itinerary/:id', component: Itinerary, exact: true },
      { path: '/itinerary/:id/pricing', component: Pricing },
      { path: '/login', component: Login },
      { path: '/login-success', component: isAuthenticated(LoginSuccess) },
      { path: '/place/:id', component: PlaceItem },
      { path: '/policy', component: Policy },
      { path: '/privacy', component: Privacy },
      { path: '/profile/:id', component: Profile, exact: true },
      {
        path: '/profile/:slug/:id/review',
        component: isAuthenticated(ReviewDriver),
        exact: true
      },
      { path: '/search/:name', component: City, exact: true },
      { path: '/search', component: Search, exact: true },
      {
        path: '/taiwan/places',
        component: PlacesList,
        countryCode: 'tw',
        countryName: 'Taiwan'
      },
      { path: '/terms', component: Terms },
      {
        path: '/thailand/places',
        component: PlacesList,
        countryCode: 'th',
        countryName: 'Thailand'
      },
      { path: '/trips', component: isAuthenticated(Trips) },
      { path: '/trip/:id', component: isAuthenticated(Trip), exact: true },
      { path: '/trip/:reference/review', component: Survey, exact: true },

      { path: '/404', component: NotFound },

      { component: NotFound }
    ]
  }
];

export default routes;
