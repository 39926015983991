import idx from 'idx';
import isEmpty from 'lodash.isempty';

/**
 *
 * Desire Output Foramt
 *
 * {
 *   description: "Taichung City",
 *   id: "ChIJ7yJ5-d8XaTQRf0SmfuQ-Uoc",
 *   place_id: "ChIJ7yJ5-d8XaTQRf0SmfuQ-Uoc",
 *   photos: [ ... ],
 *   geometry: {
 *     lat: "24.1477358",
 *     lng: "120.6736482"
 *   },
 *   ride: {
 *     duration: 74,
 *     distance: 66688,
 *   }
 *   duration: 0 // Default Staying Duration
 * }
 */

/**
 * Transform OWNRIDES recommended attracion format to Day Planner Format
 *
 * @param { object } place
 *
 * Example OWNRIDES Destination format:
 * {
 *   id: 1
 *   name: "Yehliu Geopark"
 *   slug: null
 *   description: "Witness the famous Queen's Head and other interesting stone formation made by nature at the Yehliu Geopark."
 *   short_description: null
 *   duration: 90
 *   google_place_id: "ChIJ-U6QKXRMXTQRXACzEafhTRg"
 *   latitude: "25.205333"
 *   longitude: "121.691167"
 *   url: null
 *   tel: null
 *   address: null
 *   city: {
 *     id: 5,
 *     name: 'kenting',
 *     time_zone: 'Asia/Taipei'
 *   },
 *   photos: [
 *     {
 *       image_url: '',
 *       thumbnail_url: ''
 *     }
 *   ]
 *   destination_type: "attraction"
 *   created_at: "2017-02-07T07:11:45.167Z"
 *   updated_at: "2017-08-23T09:55:56.830Z"
 * }
 */
const convertOwnridesPlaceToStandard = place => {
  const {
    google_place_id: googlePlaceId,
    latitude,
    longitude,
  } = place;

  const photos = idx(place, _ => _.photos);

  return {
    ...place,
    id: googlePlaceId,
    place_id: googlePlaceId,
    geometry: {
      lat: latitude,
      lng: longitude,
    },
    photos: photos && photos.length
      ? photos.map(p => ({ url: p.thumbnail_url || '' }))
      : []
  };
};

/**
 *
 * @param { object } place
 *
 * {
 *    address_components: [
 *      {
 *        long_name: 'Taiwan',
 *        short_name: 'TW',
 *        types: [
 *          'country',
 *          'political'
 *        ]
 *      },
 *      {
 *        long_name: 'Ruifang District',
 *        short_name: 'Ruifang District',
 *        types: [
 *          'administrative_area_level_3',
 *          'political'
 *        ]
 *      },
 *      {
 *        long_name: 'New Taipei City',
 *        short_name: 'New Taipei City',
 *        types: [
 *          'administrative_area_level_1',
 *          'political'
 *        ]
 *      },
 *      {
 *        long_name: '224',
 *        short_name: '224',
 *        types: [
 *          'postal_code'
 *        ]
 *      }
 *    ],
 *    adr_address: '<span class="locality">Ruifang District</span>, <span class="region">New Taipei City</span>, <span class="country-name">Taiwan</span> <span class="postal-code">224</span>',
 *    formatted_address: 'Ruifang District, New Taipei City, Taiwan 224',
 *    geometry: {
 *      location: {
 *        lat: 25.11038499999999,
 *        lng: 121.845794
 *      },
 *      viewport: {
 *        northeast: {
 *          lat: 25.1117339802915,
 *          lng: 121.8471429802915
 *        },
 *        southwest: {
 *          lat: 25.10903601970849,
 *          lng: 121.8444450197085
 *        }
 *      }
 *    },
 *    icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/bus-71.png',
 *    id: '2506d65a19cdb9647b69db5bd240909d3055697b',
 *    name: 'Jiufen Old Street',
 *    photos: [
 *      {
 *        height: 1184,
 *        html_attributions: [
 *          '<a href="https://maps.google.com/maps/contrib/115318666841429557438/photos">Natemisego Natte&#39;</a>'
 *        ],
 *        photo_reference: 'CmRaAAAAJ-SDVqexGPPPVVdYErWw_EQiMUhejGEtCJJdNzgimA_rSqe9tI-Mb91uYn4wVT59LBsQ07R71JIFTdi2w63MtPG1qQhmCJLVAT6U6LlOSkxStGnbBP6B5hRl3Fq9zDW3EhBhUoPSUZEM34isizjOGbb6GhRNzxf056kxw9FtS56qgnwRdl4NYA',
 *        width: 1776
 *      },
 *    ],
 *    place_id: 'ChIJBZfwMRhFXTQRyFPHvlybIhU'
 *  }
 */
const convertGooglePlaceToStandard = place => {
  const {
    place_id: googlePlaceId,
    geometry,
  } = place;

  const latitude = idx(geometry, _ => _.location.lat);
  const longitude = idx(geometry, _ => _.location.lng);

  return {
    ...place,
    id: googlePlaceId,
    place_id: googlePlaceId,
    geometry: {
      lat: latitude,
      lng: longitude,
    },
    duration: 60,
  };
};

const getPlaceDisplayName = place => {
  if (isEmpty(place)) return '';

  const {
    name,
    formatted_address: formattedAddress,
    description,
  } = place;

  const mainText = idx(place, _ => _.structured_formatting.main_text);

  return (
    name
    || mainText
    || formattedAddress
    || description
  );
};

const TUTORIAL_TYPE = {
  FROMTO: '',
  LOCATION: 'location',
  DURATION: 'duration',
  ADDBUTTON: 'add',
};

const getJoyrideStep = ({
  day = 0, id = '', type, message
}) => {
  const typeStr = type ? `-${type}` : '';
  const idStr = id ? `-${id}` : '';

  return {
    className: `tutorial-day${day}${idStr}${typeStr}`,
    message,
    day
  };
};

const getTimeHours = (duration = 0) => Math.floor(parseInt(duration, 10) / 60);
const getTimeMinutes = (duration = 0) => (parseInt(duration, 10) % 60).toFixed(0);
const getTimeTextByMinutes = mins => {
  const hours = getTimeHours(mins);
  const minutes = getTimeMinutes(mins);

  return `${hours ? `${hours} h` : ''} ${minutes ? `${minutes} min` : ''}`;
};

export {
  convertOwnridesPlaceToStandard,
  convertGooglePlaceToStandard,
  getPlaceDisplayName,
  getJoyrideStep,
  getTimeHours,
  getTimeMinutes,
  getTimeTextByMinutes,
  TUTORIAL_TYPE,
};
