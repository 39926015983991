import React from 'react';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import Clear from '@material-ui/icons/Clear';
import Timer from '@material-ui/icons/Timer';
import PeopleOutline from '@material-ui/icons/PeopleOutline';
import MapRounded from '@material-ui/icons/MapRounded';
import AccessTime from '@material-ui/icons/AccessTime';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import LocalTaxi from '@material-ui/icons/LocalTaxi';
import Search from '@material-ui/icons/Search';
import Place from '@material-ui/icons/Place';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import NavigateNext from '@material-ui/icons/NavigateNext';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import HelpOutline from '@material-ui/icons/HelpOutline';
import InfoRounded from '@material-ui/icons/InfoRounded';
import Favourite from '@material-ui/icons/FavoriteBorder';
import Share from '@material-ui/icons/Share';
import PhotoOutline from '@material-ui/icons/PhotoOutlined';
import MailOutline from '@material-ui/icons/MailOutline';
import StarsRounded from '@material-ui/icons/StarsRounded';
import OpenInNew from '@material-ui/icons/OpenInNew';
import Check from '@material-ui/icons/Check';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = ({ overwritestyles = {} }) => makeStyles({
  ...overwritestyles
});


export const CheckIcon = props => {
  const classes = useStyles(props)();

  return (
    <Check {...props} classes={{ root: classes.root }} />
  );
};

export const OpenInNewIcon = props => {
  const classes = useStyles(props)();

  return (
    <OpenInNew {...props} classes={{ root: classes.root }} />
  );
};


export const StarsRoundedIcon = props => {
  const classes = useStyles(props)();

  return (
    <StarsRounded {...props} classes={{ root: classes.root }} />
  );
};

export const MailOutlineIcon = props => {
  const classes = useStyles(props)();

  return (
    <MailOutline {...props} classes={{ root: classes.root }} />
  );
};


export const PhotoOutlineIcon = props => {
  const classes = useStyles(props)();

  return (
    <PhotoOutline {...props} classes={{ root: classes.root }} />
  );
};

export const FavouriteIcon = props => {
  const classes = useStyles(props)();

  return (
    <Favourite {...props} classes={{ root: classes.root }} />
  );
};

export const ShareIcon = props => {
  const classes = useStyles(props)();

  return (
    <Share {...props} classes={{ root: classes.root }} />
  );
};

export const InfoRoundedIcon = props => {
  const classes = useStyles(props)();

  return (
    <InfoRounded {...props} classes={{ root: classes.root }} />
  );
};

export const HelpOutlineIcon = props => {
  const classes = useStyles(props)();

  return (
    <HelpOutline {...props} classes={{ root: classes.root }} />
  );
};

export const KeyboardArrowDownIcon = props => {
  const classes = useStyles(props)();

  return (
    <KeyboardArrowDown {...props} classes={{ root: classes.root }} />
  );
};

export const KeyboardArrowUpIcon = props => {
  const classes = useStyles(props)();

  return (
    <KeyboardArrowUp {...props} classes={{ root: classes.root }} />
  );
};

export const KeyboardArrowRightIcon = props => {
  const classes = useStyles(props)();

  return (
    <KeyboardArrowRight {...props} classes={{ root: classes.root }} />
  );
};

export const RemoveIcon = props => {
  const classes = useStyles(props)();

  return (
    <Remove {...props} classes={{ root: classes.root }} />
  );
};

export const AddIcon = props => {
  const classes = useStyles(props)();

  return (
    <Add {...props} classes={{ root: classes.root }} />
  );
};

export const SearchIcon = props => {
  const classes = useStyles(props)();

  return (
    <Search {...props} classes={{ root: classes.root }} />
  );
};

export const PlaceIcon = props => {
  const classes = useStyles(props)();

  return (
    <Place {...props} classes={{ root: classes.root }} />
  );
};

export const ClearIcon = props => {
  const classes = useStyles(props)();

  return (
    <Clear {...props} classes={{ root: classes.root }} />
  );
};

export const TimerIcon = props => {
  const classes = useStyles(props)();

  return (
    <Timer {...props} classes={{ root: classes.root }} />
  );
};

export const PeopleOutlineIcon = props => {
  const classes = useStyles(props)();

  return (
    <PeopleOutline {...props} classes={{ root: classes.root }} />
  );
};

export const MapRoundedIcon = props => {
  const classes = useStyles(props)();

  return (
    <MapRounded {...props} classes={{ root: classes.root }} />
  );
};

export const AccessTimeIcon = props => {
  const classes = useStyles(props)();

  return (
    <AccessTime {...props} classes={{ root: classes.root }} />
  );
};

export const ArrowBackIcon = props => {
  const classes = useStyles(props)();

  return (
    <ArrowBack {...props} classes={{ root: classes.root }} />
  );
};

export const ArrowForwardIcon = props => {
  const classes = useStyles(props)();

  return (
    <ArrowForward {...props} classes={{ root: classes.root }} />
  );
};

export const LocalTaxiIcon = props => {
  const classes = useStyles(props)();

  return (
    <LocalTaxi {...props} classes={{ root: classes.root }} />
  );
};

export const NavigateBeforeIcon = props => {
  const classes = useStyles(props)();

  return (
    <NavigateBefore {...props} classes={{ root: classes.root }} />
  );
};

export const NavigateNextIcon = props => {
  const classes = useStyles(props)();

  return (
    <NavigateNext {...props} classes={{ root: classes.root }} />
  );
};

export default {
  AddIcon,
  ClearIcon,
  TimerIcon,
  PeopleOutlineIcon,
  MapRoundedIcon,
  AccessTimeIcon,
  ArrowBackIcon,
  ArrowForwardIcon,
  LocalTaxiIcon,
  SearchIcon,
  PlaceIcon,
  NavigateBeforeIcon,
  NavigateNextIcon,
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon,
  RemoveIcon,
  HelpOutlineIcon,
  InfoRoundedIcon,
  FavouriteIcon,
  ShareIcon,
  PhotoOutlineIcon,
  MailOutlineIcon,
  StarsRoundedIcon,
  KeyboardArrowRightIcon,
  OpenInNewIcon,
  CheckIcon
};
