import config from '../../config';

export const LOAD = 'recommendedDestinations/LOAD';
export const LOAD_SUCCESS = 'recommendedDestinations/LOAD_SUCCESS';
const LOAD_FAIL = 'recommendedDestinations/LOAD_FAIL';

const LOAD_ACCOMMODATION = 'recommendedDestinations/LOAD_ACCOMMODATION';
const LOAD_ACCOMMODATION_SUCCESS = 'recommendedDestinations/LOAD_ACCOMMODATION_SUCCESS';
const LOAD_ACCOMMODATION_FAIL = 'recommendedDestinations/LOAD_ACCOMMODATION_FAIL';

const SET_CITY_FILTER = 'recommendedDestinations/SET_CITY_FILTER';
const SET_CATEGORY_FILTER = 'recommendedDestinations/SET_CATEGORY_FILTER';

export const initialState = () => ({
  accommodationLoaded: false,
  accommodationLoading: false,
  accommodations: [],
  destinationLoading: false,
  destinationLoaded: false,
  destinations: {},
  cityFilter: '',
  categoryFilter: '',
});

export function getDestinationObjectKey({
  country = 'tw', city = '', type = '', category = ''
} = {}) {
  return `${country}${city}${type}${category}`;
}

/**
* Example Structure
* {
*   destinations: {
*     'tw' : {
*         'id1': {
*           ...
*         },
*         'id2': {
*           ...
*         },
*         ...
*     }
*     'to_go': {
*        ids: [ 'id3', 'id4', ... ]
*        fetched: true,
*     },
*     'museum': {
*       ids: [],
*       fetched: false,
*     }
*     'taipei': {
*       ids: [ 'id4', 'id5', ... ]
*       fetched: true,
*     },
*     'taipei_to_go': {
*       ids: [ 'id4', ... ]
*       fetched: true,
*     }
*   },
* }
*/
export default function reducer(state = initialState(), action = {}) {
  switch (action.type) {
    case SET_CITY_FILTER:
      return {
        ...state,
        cityFilter: action.city,
      };
    case SET_CATEGORY_FILTER:
      return {
        ...state,
        categoryFilter: action.category,
      };
    case LOAD_ACCOMMODATION:
      return {
        ...state,
        accommodationLoading: true
      };
    case LOAD_ACCOMMODATION_SUCCESS:
      return {
        ...state,
        accommodationLoading: false,
        accommodationLoaded: true,
        accommodations: {
          ...state.destinations,
          [action.country]: action.result,
        },
      };
    case LOAD_ACCOMMODATION_FAIL:
      return {
        ...state,
        accommodationLoading: false,
        accommodationLoaded: false,
      };
    case LOAD:
      return {
        ...state,
        destinationLoading: true
      };
    case LOAD_SUCCESS:
      const _ids = [];
      const filtersKey = getDestinationObjectKey({
        country: action.country,
        city: action.city,
        type: action.destinationType,
        category: action.category,
      });

      const destinationsWithIDAsKey = {};

      action.result.forEach(destination => {
        const { id } = destination;
        _ids.push(id);
        destinationsWithIDAsKey[id] = destination;
      });

      const idsMapping = {
        [filtersKey]: {
          ids: _ids,
          fetched: true,
        }
      };

      return {
        ...state,
        destinationLoading: false,
        destinationLoaded: true,
        destinations: {
          ...state.destinations,
          [action.country]: { // Overwrite the destinations pool
            ...state.destinations[action.country],
            ...destinationsWithIDAsKey,
          },
          ...idsMapping,
        },
        error: null
      };
    case LOAD_FAIL:
      return {
        ...state,
        destinationLoading: false,
        destinationLoaded: false,
        error: typeof action.error === 'string' ? action.error : 'Error'
      };
    default:
      return state;
  }
}

export function isLoaded(globalState) {
  return globalState.destinations && globalState.destinations.loaded;
}

export function isAccommodationLoaded(globalState) {
  return globalState.destinations && globalState.destinations.accommodationLoaded;
}

export function setCityFilter(city) {
  return {
    type: SET_CITY_FILTER,
    city,
  };
}

export function setCategoryFilter(category) {
  return {
    type: SET_CATEGORY_FILTER,
    category,
  };
}

export function loadAccommodation({
  country = 'tw',
  type = config.destinationType.ACCOMMODATION,
}) {
  return {
    types: [LOAD_ACCOMMODATION, LOAD_ACCOMMODATION_SUCCESS, LOAD_ACCOMMODATION_FAIL],
    promise: ({ client }) => client.get('/destinations/load', {
      params: {
        country,
        type,
      }
    }),
    country,
    type,
  };
}

export function load({
  country = 'tw',
  city = '',
  type = '',
  category = '',
}) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ client }) => client.get('/destinations/load', {
      params: {
        country,
        city,
        type,
        category,
      }
    }),
    country,
    city,
    destinationType: type,
    category,
  };
}
