import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/Loading';

const ReviewDriverLoadable = Loadable({
  loader: () => import('./ReviewDriver')
    .then(module => module.default)
    .catch(e => {
      console.log(e);
      // if (typeof window !== 'undefined') window.location.reload();
    }),
  loading: () => <div key="ReviewDriverLoadable"><Loading /></div>
});

export default ReviewDriverLoadable;
