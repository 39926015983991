import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import MuiPopper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import styled from 'styled-components';

const Root = styled.div`
  z-index: 4;
`;

const Popper = styled(MuiPopper)`
  && {
    z-index: 4;
  }
`;

class IconButtonMenu extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]).isRequired,
    icon: PropTypes.node.isRequired,
    innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    ariaOwn: PropTypes.string,
  };

  static defaultProps = {
    innerRef: () => {},
    ariaOwn: `Menu_${(new Date()).getTime()}`
  };

  constructor(props) {
    super(props);

    this.handleToggle = this.handleToggle.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    const { innerRef } = this.props;
    if (typeof innerRef === 'function') {
      innerRef(this);
    } else if (Object.prototype.hasOwnProperty.call(innerRef, 'current')) {
      innerRef.current = this;
    }
  }

  handleToggle(e) {
    e.stopPropagation();
    this.setState(state => ({ open: !state.open }));
  }

  handleClose(e) {
    if (this.anchorEl && this.anchorEl.contains(e.target)) {
      return;
    }

    this.setState({ open: false });
  }

  render() {
    const { children, icon, ariaOwn } = this.props;
    const { open } = this.state;

    return (
      <Root>
        <IconButton
          buttonRef={node => { this.anchorEl = node; }}
          aria-owns={open ? ariaOwn : null}
          aria-haspopup="true"
          onClick={this.handleToggle}
          data-cy="cy-icon-menu"
        >
          { icon }
        </IconButton>
        <Popper
          open={open}
          anchorEl={this.anchorEl}
          transition
          placement="bottom-end"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList>
                    { children }
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Root>
    );
  }
}

export default IconButtonMenu;
