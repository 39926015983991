import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/Loading';

const PricingPoliciesLoadable = Loadable({
  loader: () => import('./Policies')
    .then(module => module.default)
    .catch(e => { console.error(e); }),
  loading: () => <div key="PricingPoliciesLoadable"><Loading /></div>
});

export default PricingPoliciesLoadable;
