import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/Loading';

const PriceSummaryLoadable = Loadable({
  loader: () => import('./PriceSummary')
    .then(module => module.default)
    .catch(e => { console.error(e); }),
  loading: () => <div key="PriceSummaryLoadableLoading"><Loading /></div>
});

export default PriceSummaryLoadable;
