import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  onErrorHandler,
  ErrorBoundaryFallbackComponent,
  withErrorBoundary
} from 'components/ErrorBoundry/ErrorBoundry';

export const getPriceQuote = ({
  price = 0,
  multiplier = 0,
  toFixed = 0,
  margin = 0.15
}) => {
  const multiply = multiplier || 4 * (1 + parseFloat(margin));

  return price
    ? (parseFloat(price) * multiply).toFixed(toFixed)
    : 0;
};

const PriceNumber = memo(props => (
  <span>
    { getPriceQuote({ ...props }) }
  </span>
));

PriceNumber.propTypes = {
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  multiplier: PropTypes.number,
  margin: PropTypes.number,
  toFixed: PropTypes.number,
};

PriceNumber.defaultProps = {
  multiplier: 1,
  toFixed: 0,
  margin: 0.15,
};

export default withErrorBoundary(
  PriceNumber,
  ErrorBoundaryFallbackComponent,
  onErrorHandler,
);
