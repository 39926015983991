import {
  LOGIN_WITH_MAGIC_LINK_SUCCESS,
  LOAD_PRESERVED_STATE_SUCCESS,
} from './auth';

const initialState = {
  redirect: '/'
};

export default function info(state = initialState, action = {}) {
  switch (action.type) {
    /**
     * Auth.js Actions
     */
    case LOAD_PRESERVED_STATE_SUCCESS:
    case LOGIN_WITH_MAGIC_LINK_SUCCESS:
      const { preservedState } = action.result;
      return preservedState && preservedState.redirect ? { ...preservedState.redirect } : { ...state };
    /* End of Auth.js Actions */

    default:
      return state;
  }
}
