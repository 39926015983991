import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from './utils/i18next-universal-backend';

const options = {
  fallbackLng: 'en',
  whitelist: ['en', 'zh'],
  react: {
    useSuspense: false,
  },
  // have a common namespace used around the full app
  ns: ['common', 'new-version', 'meta', 'auth', 'assign'],
  defaultNS: 'common',
  backend: {
    loadPath: 'https://locales.ownrides.com/locales/frontend/{{lng}}/{{ns}}.json',
  }
};

// for browser use xhr backend to load translations and browser lng detector
if (typeof window !== 'undefined') {
  i18n
    .use(Backend)
    .use(initReactI18next)
    .use(LanguageDetector);
}

// initialize if not already initialized
if (!i18n.isInitialized) {
  i18n.init(options);
}

export default i18n;
